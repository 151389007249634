import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, ListItem, ListItemIcon, Stack, useTheme, useMediaQuery } from '@mui/material';
import PricesCourses1 from './sells/prices1';
import SingleImage from '../components/images/imagen1';
import WhatsAppButtonComponent from '../components/whatsapp/WhatsappButton';
import UserInfo from '../components/UserInfo';

const MyAccount = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const marginTop = isMobile ? theme.spacing(10) : theme.spacing(10);
  const marginBottom = isMobile ? theme.spacing(1) : theme.spacing(2);
  const marginLeft = isMobile ? theme.spacing(1) : theme.spacing(2);
  const marginRight = isMobile ? theme.spacing(1) : theme.spacing(2);



  return (
    <div>
      <Box sx={{
        marginTop: marginTop,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
        marginRight: marginRight
      }}>
         
          <Box textAlign="center"> {/* Agregamos el textAlign="center" para centrar */}            
            <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/adminport.png'></SingleImage>
          </Box>
        
      </Box>
    </div>
  );
};

export default MyAccount;
