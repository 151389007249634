import React from 'react';
import { Box, Typography, Container } from "@mui/material";
import FooterLanding from './FooterLandin';
const ThankYouPage = () => {
  return (
    <Container maxWidth="md" sx={{ textAlign: 'center', marginTop: '110px' }}>
      <Typography variant="h1" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
        ¡Gracias por tu compra!
      </Typography>
      <Typography variant="h5" sx={{ marginBottom: '20px' }}>
        Agradecemos que hayas realizado una compra con nosotros. En los próximos minutos, te daremos acceso una vez que sea verificado.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
        <img src="https://academy-publics.s3.amazonaws.com/4+-+Team+congratulates+colleague.png" alt="Thank You" style={{ maxWidth: '100%', height: 'auto' }} />
      </Box>
      <Box mb={5}>
      <Typography variant="h2">
        Si tienes alguna pregunta, no dudes en contactarnos.
      </Typography>
      </Box>
      <FooterLanding></FooterLanding>
    </Container>
  );
};

export default ThankYouPage;
