import React from 'react';
import { Box, Typography, Button, Grid,ListItem ,ListItemIcon,Stack} from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery'
import { tokens } from "../../theme";
import { useTheme } from '@emotion/react';
import SlideUpText from '../../components/TextAnimation1';
import CheckIcon from '@mui/icons-material/Check';
import CardCustom from '../../components/CardT1/CardT5';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeLandingCard from '../../components/CardT1/CardT6';
import HomeLandingCard2 from '../../components/CardT1/CardT7';
import SingleImage from '../../components/images/imagen1';
import HomeLandingCard3 from '../../components/CardT1/CardT8';
import WhatsAppButtonComponent from '../../components/whatsapp/WhatsappButton';
import FacebookButton from '../../components/Facebook/FabookDir';
import InstagramButton from '../../components/Instagram/Instagram';
import FooterLanding from './FooterLandin';
import DownloadButton from '../../components/BotonDescargar/BotonDescargar';


const Us = () => {
    const theme = useTheme();
    const isMobile2 = useMediaQuery('(max-width: 720px)');
    const colors = tokens(theme.palette.mode);



  
  return (
    <div>
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #141b2d 50%, #232c41)',
        paddingTop: '110px',
        paddingBottom: '5px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
       
        
      }}
    >
      <Grid container spacing={1} >
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 }, marginRight: isMobile2 ? '5px' : '250px', marginLeft: isMobile2 ? '5px' : '250px' }}>
         
          <SlideUpText text="Hola, somos Proex Academy!" variant="h1" color="white" marginBottom="20px" />
          <SlideUpText text="La academia dedicada a la enseñanza de diseño estructural para infraestructura y edificaciones.  Nuestro principal objetivo es brindar a los estudiantes la oportunidad de adquirir o complementar conocimientos en diseño que, por diversas circunstancias, no pueden adquirir adecuadamente en la universidad. Proex Academy es un centro de aprendizaje orientado hacia estudiantes de ingeniería civil, profesionales y especialistas que se dedican al desarrollo de proyectos de construcción que desean elevar su nivel de conocimiento en la ingenieria estructural." variant="h3" color={colors.blueAccent[400]} marginBottom="20px" />

        </Grid>
        
       
      </Grid>
    </Box>
    


    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #232c41)',
      }}
    >
      <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/proexacademyimagus_min.png'></SingleImage>
    </Box>
    
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #232c41 50%, #3e4396)',
        paddingTop: '80px',
        paddingBottom: '5px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 },marginRight: isMobile2 ? '5px' : '250px', marginLeft: isMobile2 ? '5px' : '250px' }}>
         
          <SlideUpText text="Quien nos respalda?" variant="h1" color="white" marginBottom="20px" />
          <SlideUpText text="En Proex Academy nos respaldamos en la amplia experiencia de Proex Ingeniería, una reconocida empresa especializada en el desarrollo de estudios y diseños estructurales. Nuestro equipo está liderado por los ingenieros Howart Riaño y Oscar Triviño, ambos ingenieros civiles y magíster en estructuras de la Universidad de los Andes, Colombia. Ellos aportan todo su conocimiento y experiencia para que más ingenieros puedan adentrarse de manera sólida y con herramientas adecuadas en el apasionante campo del análisis y diseño estrcutual." variant="h3" color='white' marginBottom="20px" />
        
          <Grid>
          <Button variant="outlined" color="secondary" component={Link} to="https://proexingenieria.com/"
          sx={{ fontSize: '1rem',border: 'none',fontWeight: isMobile2 ? 'normal' : '600' }}
          >
            Website Proex Ingenieria Sas
          </Button>
          </Grid>
          <Grid>
          <DownloadButton
              color="secondary"
              size="large"
              fileUrl="https://academy-public-resources.s3.amazonaws.com/publics/BrochureProex2023_HR_V1.0.pdf"
              buttonText="Ver Informacion Proex Ingenieria"
          />
          </Grid>

        </Grid>
        
       
      </Grid>
    </Box>
   

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 2%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } }}>
            
          <Typography variant="h2" sx={{ marginBottom: '20px' }} >
          ¡Únete a nuestra comunidad de estudiantes motivados y construye un futuro brillante en la construcción con Proex Academy! 
          </Typography>
                </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }}>
        <Box 
        sx={{
            display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        }}
         
        >
          <Link to="/cursos">
               <Button style={{ fontSize: isMobile2 ? '0.8rem' : '1rem' ,fontWeight: isMobile2 ? 'normal' : '600'}} sx={{ fontSize: '1rem' ,backgroundColor: "#EA810F", color: "#141b2d"}} variant='contained' >
                Ver Cursos</Button>             
             </Link>
               
            <Link to="/register">
               <Button style={{ fontSize: isMobile2 ? '0.8rem' : '1rem' ,fontWeight: isMobile2 ? 'normal' : '600'}} sx={{ fontSize: '1rem' }} variant='contained' color='secondary'>
                Resgistrarme Gratis</Button>             
             </Link>
            
             </Box>
                       

        </Grid>      
      </Grid>
    </Box>


    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #141b2d)',
        paddingTop: '5px',
        paddingBottom: '5px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } }}>
        <Box display="flex" flexDirection="column" alignItems="center">
      <Box width={200} mb={2}>
        <InstagramButton />
      </Box>
      <Box width={200}>
        <FacebookButton />
      </Box>
    </Box>
        
        
        </Grid>
           
      </Grid>
    </Box>

    <FooterLanding></FooterLanding>




    </div>
   
    
    
  );
};

export default Us;
