import React from 'react';
import { Card, CardContent, CardActions, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const CardFreeEbooks = ({ title, description, imageUrl, to }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(`/freebooks/${to}`);
  };

  const CardContainer = styled(Card)(({ theme }) => ({
    maxWidth: 400,
    margin: '0 auto',
    background: 'linear-gradient(to right, #288f00, #39d300)', // Naranja oscuro
    color: '#fff',
    borderRadius: 5, // Cambia este valor para ajustar el grosor del borde
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)',
    marginBottom: 20,
    border: '2px solid white', // Agrega esta propiedad para establecer el color del borde
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 12,
    letterSpacing: 1,
  }));

  const Image = styled('img')({
    width: '100%',
    objectFit: 'contain',
    borderRadius: '10px 10px 0 0',
    maxHeight: '200px', // Ajusta este valor para cambiar la altura de la imagen
  });

  const Actions = styled(CardActions)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
  }));

  const ButtonStyled = styled(Button)(({ theme }) => ({
    color: '#fff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 1,
  }));

  return (
    <CardContainer>
      <Image src={imageUrl} alt="Card" />
      <CardContent>
        <Title variant="h2" component="div">
          {title}
        </Title>
        <Typography variant="body1" component="div">
          {description}
        </Typography>
      </CardContent>
      <Actions>
        <ButtonStyled onClick={handleButtonClick} variant="contained">
          Leer
        </ButtonStyled>
      </Actions>
    </CardContainer>
  );
};

export default CardFreeEbooks;
