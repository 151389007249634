import React, { useState,useEffect } from 'react';
import { Button, TextField, Box } from '@mui/material';

const UserInfo = ({ username, job, email }) => {
  const [editingUsername, setEditingUsername] = useState(false);
  const [editingJob, setEditingJob] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [newJob, setNewJob] = useState('');

  const [editingPassword, setEditingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');


  const handleUsernameChange = (email2) => {
    if (newUsername !== '') {
      console.log('New username:', newUsername);
      setEditingUsername(false);
  
      const infoname = {
        'typeoperation': 'updateusername',
        'email-academy': email,
        'username': newUsername
      };

      
  
      fetch('https://8ori4qvsnc.execute-api.us-east-1.amazonaws.com/dev/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(infoname)
      })
        .then(response => response.json())
        .then(data => {
          console.log('Data:', data);
          // Realizar acciones adicionales según la respuesta del servidor
        })
        .catch(error => {
          console.error('Error:', error);
          // Manejar el error de la solicitud
        });

        localStorage.setItem('username', newUsername); 
        alert("Actualización satisfactoria, ahora puedes recargar la pagina")
    }
  };
  

  const handleJobChange = () => {
    if (newJob !== '') {
      console.log('New job:', newJob);
      setEditingJob(false);

      const infojob = {
        'typeoperation': 'updatejob',
        'email-academy': email,
        'job': newJob
      };

      
  
      fetch('https://8ori4qvsnc.execute-api.us-east-1.amazonaws.com/dev/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(infojob)
      })
        .then(response => response.json())
        .then(data => {
          console.log('Data:', data);
          // Realizar acciones adicionales según la respuesta del servidor
        })
        .catch(error => {
          console.error('Error:', error);
          // Manejar el error de la solicitud
        });

        localStorage.setItem('userjob', newJob); 
        alert("Actualización satisfactoria, ahora puedes recargar la pagina")
    }
  };

  

 


 const handlePasswordChange = () => {
        if (currentPassword !== '' && newPassword !== '' && newPassword === confirmNewPassword) {
       
          setEditingPassword(false);
      
          const infopassword = {
            "typeoperation": "updatepassword",
            "email-academy": email,
            "password": currentPassword,
            "NewPassword": confirmNewPassword
          }
      
          fetch('https://8ori4qvsnc.execute-api.us-east-1.amazonaws.com/dev/register', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(infopassword)
          })
            .then(response => response.json())
            .then(data => {
          
              const validationresponse = data.validation;
      
              if (validationresponse === true) {
                localStorage.setItem('token', confirmNewPassword);
                alert("Cambio de clave satisfactoria");
              } else {
                alert("Cambio no realizado, clave o verificación de clave no válida");
              }
            })
            .catch(error => {
              console.error('Error:', error);
              // Manejar el error de la solicitud
            });
        }
      };
      




    
  
  const handlePasswordEdit = () => {
    setEditingPassword(true);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };
 
   
  return (
    <div>
      <Box mb={3} display="flex" alignItems="center">
        <strong style={{ fontSize: '1.5rem' }}>Nombre:</strong>
        {editingUsername ? (
          <Box display="flex" alignItems="center" marginLeft="1rem">
            <TextField
              label="Nuevo Nombre"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
            />
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={handleUsernameChange}
              style={{ marginLeft: '1rem' }}
            >
              Guardar
            </Button>
          </Box>
       
        ) : (
          <Box display="flex" alignItems="center" marginLeft="1rem">
            <span style={{ fontSize: '1.2rem' }}>{username}</span>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={() => setEditingUsername(true)}
              style={{ marginLeft: '1rem' }}
            >
              Cambiar
            </Button>
          </Box>
        )}
      </Box>
      <Box mb={3} display="flex" alignItems="center">
        <strong style={{ fontSize: '1.5rem' }}>Profesión:</strong>
        {editingJob ? (
          <Box display="flex" alignItems="center" marginLeft="1rem">
            <TextField
              label="Nueva Profesión"
              value={newJob}
              onChange={(e) => setNewJob(e.target.value)}
            />
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={handleJobChange}
              style={{ marginLeft: '1rem' }}
            >
              Guardar
            </Button>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" marginLeft="1rem">
            <span style={{ fontSize: '1.2rem' }}>{job}</span>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={() => setEditingJob(true)}
              style={{ marginLeft: '1rem' }}
            >
              Cambiar
            </Button>
          </Box>
        )}
      </Box>
      <Box mb={3} display="flex" alignItems="center">
        <strong style={{ fontSize: '1.5rem' }}>Clave:</strong>
        {editingPassword ? (
          <Box display="flex" alignItems="center" marginLeft="1rem">
            <TextField
              type="password"
              label="Clave actual"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <TextField
              type="password"
              label="Nueva clave"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              type="password"
              label="Confirmar nueva clave"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={handlePasswordChange}
              style={{ marginLeft: '1rem' }}
            >
              Cambiar
            </Button>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" marginLeft="1rem">
            <span style={{ fontSize: '1.2rem' }}>**********</span>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={handlePasswordEdit}
              style={{ marginLeft: '1rem' }}
            >
              Cambiar
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default UserInfo;