import React from 'react';
import { Box, Typography, Button, Grid,ListItem ,ListItemIcon,Stack,Avatar} from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery'
import { tokens } from "../../theme";
import { useTheme } from '@emotion/react';
import SlideUpText from '../../components/TextAnimation1';
import CheckIcon from '@mui/icons-material/Check';
import CardCustom from '../../components/CardT1/CardT5';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeLandingCard from '../../components/CardT1/CardT6';
import HomeLandingCard2 from '../../components/CardT1/CardT7';
import SingleImage from '../../components/images/imagen1';
import HomeLandingCard3 from '../../components/CardT1/CardT8';
import WhatsAppButtonComponent from '../../components/whatsapp/WhatsappButton';
import FacebookButton from '../../components/Facebook/FabookDir';
import InstagramButton from '../../components/Instagram/Instagram';
import FooterLanding from './FooterLandin';
import SingleImage2 from '../../components/images/imagen2';
import DropdownList from '../../components/desplegable_modulos';
import DownloadButton from '../../components/DownLoadButton';
import ItemList from '../../components/Lista1';
import ListItems from '../../components/Lista2';
import CardCustomTeacher from '../../components/CardT1/cardT10';
import PricesCourses1 from './prices1';
import SingleImage3 from '../../components/images/imagen3';
import VimeoVideoPublicidad from '../../components/VimeoVideo2';

const Cursofundamentos = () => {
    const theme = useTheme();
    const isMobile3 = useMediaQuery('(max-width: 960px)');
    const isMobile2 = useMediaQuery('(max-width: 600px)');    
    const colors = tokens(theme.palette.mode);
    const pdfUrl = 'https://academy-public-resources.s3.amazonaws.com/publics/temarios/C03.png';

    const itemListCompentencias = ['Fundamentos Comportamiento del Concreto reforzado',
                                   'Fundamentos Comortamiento del Acero Estructural',
                                   'Comportamiento simplificado de losas y vigas' ,
                                   'Comportamiento simplificado columnas',
                                   'Comportamiento simplificado muros', 
                                   'Comportamiento simplificado riostras',
                                   
                                   
                                  
                                ];
   const itemListCompentencias2 = ['Reconocer los principales sistemas estructurales para edificios',                                  
                                  'Caracterisiticas de porticos, sistemas combinados y muros en Concreto', 
                                  'Caracteristicas sistema de porticos y arriostrados en acero', 
                                  'Criterios para selección de sistemas Estructurales',
                                  'Sistemas de contención',
                                  'Fundamentos de cimentaciones',
                                  ];

    const itemsMetodologiaCurso = [
    
    'Clases Pregrabadas, acceso 24/7',
    '4:26 Horas de Clases',
    'Presentaciones de las clases',
    'Documentos adicionales de apoyo',    
    'Certificado de Asistencia',
    'Solución de dudas por sistema de comentarios '
    ];

    const itemsDirigidoCurso = [
    
      'Estudiantes de Ingenieria civil',
      'Ingenieros civiles diseñadores estructurales',
      'Profesionales afines a la construcción ',      
      ];

      

    const itemsHerramientas = [    
        'No requiere uso de software',
        'Familiaridad con la Ingenieria Civil',
        'Maxima disposición a aprender',      
        ];
        const modules = [
          {
            title: 'Módulo 1 - Introducción',
            duration: '4 min',
            clases: 'Son 2 Clases',
            classesList: [
              {
                name: 'Presentación del curso',
                duration: '00:28 min',
              },
              {
                name: 'Introducción y definiciones',
                duration: '03:32 min',
              }
            ],
          },
          {
            title: 'Módulo 2 - Estudios y diseño en edificaciones',
            duration: '45 min',
            clases: 'Son 3 clases',
            classesList: [
              {
                name: 'Diseño arquitectónico',
                duration: '17:43 min',
              },
              {
                name: 'Estudio geotécnico',
                duration: '21:43 min',
              },
              {
                name: 'Diseño de redes',
                duration: '06:18 min',
              }
            ],
          },
          {
            title: 'Módulo 3 - Materiales de edificaciones',
            duration: '54 min',
            clases: 'Son 7 Clases',
            classesList: [
              {
                name: 'Materiales permitidos',
                duration: '04:04 min',
              },
              {
                name: 'Mampostería',
                duration: '12:10 min',
              },
              {
                name: 'Concreto',
                duration: '09:09 min ',
              },
              {
                name: 'Acero estructural',
                duration: '11:32 min',
              },
        {
                name: 'Otros materiales',
                duration: '06:24 min',
              },
        {
                name: 'Criterios de uso de materiales',
                duration: '03:04 min',
              },
        {
                name: 'Concreto vs Acero',
                duration: '08:08 min',
              }
            ],
    
          },
    
          {
            title: 'Módulo 4 - Elementos estructurales de edificios',
            duration: '1 hora 31 min',
            clases: 'Son 10 Clases',
            classesList: [
              {
                name: 'Sistema de entrepisos',
                duration: '06:32 min',
              },
              {
                name: 'Mampostería: Muros',
                duration: '09:04 min',
              },
              {
                name: 'Pórticos de concreto reforzado',
                duration: '14:33 min ',
              },
              {
                name: 'Sistema combinado en concreto reforzado',
                duration: '05:37 min',
              },
              {
                name: 'Sistema de muros en concreto reforzado',
                duration: '04:37 min',
              },
              {
                name: 'Pórticos de acero estructural',
                duration: '13:08 min',
              },
              {
                name: 'Sistemas arriostrados de acero estructural',
                duration: '07:02 min',
              },
              {
                name: 'Otros sistemas de acero',
                duration: '06:14 min ',
              },
              {
                name: 'Sistemas de contención',
                duration: '10:18 min',
              },
              {
                name: 'Cimentaciones',
                duration: '14:01 min',
              }
            ],
    
          },
          {
            title: 'Módulo 5 - Especificaciones generales y normativa',
            duration: '32 min',
            clases: 'Son 3 Clases',
            classesList: [
              {
                name: 'Comportamiento de concreto',
                duration: '15:10 min',
              },
              {
                name: 'Comportamiento de acero',
                duration: '08:59 min',
              },
              {
                name: 'Códigos de diseño',
                duration: '08:02 min ',
              }
            ],
    
          },
          {
            title: 'Módulo 6 - Comportamiento simplificado de elementos',
            duration: '24 min',
            clases: 'Son 5 Clases',
            classesList: [
              {
                name: 'Losas y vigas',
                duration: '07:31 min',
              },
              {
                name: 'Columnas',
                duration: '04:23 min',
              },
              {
                name: 'Muros',
                duration: '03:01 min ',
              },
              {
                name: 'Riostras',
                duration: '03:49 min',
              },
              {
                name: 'Elementos de contención',
                duration: '05:31 min',
              }
            ],
    
          },
          {
            title: 'Módulo 7 - Proceso general de diseño',
            duration: '14 min',
            clases: 'Son 2 Clases',
            classesList: [
              {
                name: 'Importancia del proceso constructivo',
                duration: '08:35 min',
              },
              {
                name: 'Procedimiento general de diseño',
                duration: '05:43 min',
              }
            ],
    
          },
         
        ];
    
    const cardDataList = [
      {
        title: 'Clases Ejercicios',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Desarrollamos ejemplos paso a paso para que puedas entender y aprender facilmente, Video Clases 24/7',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 1
        to:'c01',
      },
      {
        title: 'Clases conceptuales ',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Clases produndas de fundamentos y conceptos, Video Clases 24/7',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 2
        to:'c02',
      },
      {
        title: 'Clases Manejo Software',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Clases 100% orientadas al trabajo del dia a dia de los ingenier@s, Video Clases 24/7',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 3
        to:'c03',
      },
      {
        title: 'Material Escrito Complementario',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Te brindamos materiales de apoyo complementario en PDF para complementar las clases',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      },
      {
        title: 'Certificado de Asistencia',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Te otorgamos certificado de asistencia al tomar todas las clases',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      },
      {
        title: ' Preguntas y respuestas',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Respondemos tus preguntas acerca de las clases en nuestro sistema de comentarios',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      }


    ];
    

    const cardMotivation = [
      {
        title: 'Inicies tu carrera profesional como diseñador estructural',
        
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 1
        to:'c01',
      },
      {
        title: 'Crear bases fuertes para introducirte al diseño estructural de edificaciones ',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',       
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 2
        to:'c02',
      },
      {
        title: 'Estes  list@ para enfrentarte a retos de diseño estructural',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 3
        to:'c03',
      }
    ];





    const cardDataList3 = [
      {
        title: 'Cursos Estructurales',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Cursos de fundamentos, análisis y diseño estructural aplicado al trabajo practico de los Ingenier@s civiles',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 1
        to:'cursos',
      },
   


    ];





    const imageUrl1 = 'https://academy-public-resources.s3.amazonaws.com/publics/student+main.svg';
    const imageUrl2 = 'https://academy-public-resources.s3.amazonaws.com/publics/bridge1.svg';
    const imageUrl3 = 'https://academy-public-resources.s3.amazonaws.com/publics/building+_1.svg';

  
  return (
    <div>
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #141b2d 50%, #232c41)',
        paddingTop: '105px',
        paddingBottom: '20px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } , marginTop: '20px', marginRight: (isMobile2 ? '20px' : isMobile3 ? '30px' : '200px') , marginLeft: (isMobile2 ? '20px' : isMobile3 ? '30px' : '200px') }}>
         
          <Box sx={{
        borderRadius: '10px',
        backgroundColor:'#141b2b',
        border: '3px solid #4c92dd'
        // Resto de estilos
      }}>
          <SlideUpText text="Fundamentos de diseño estructural para edificaciones " variant="h2" color="#4c92dd" marginBottom="20px" />
          <SlideUpText text="Construye conocimiento, potencia tu éxito en la construcción" variant="h4" color="#white" marginBottom="20px" />
          </Box>
          <SingleImage3 Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/iconos-cursos/iconofundamentos.svg'></SingleImage3>
        </Grid>

      
        
       
      </Grid>
    </Box>
    <Box>

    </Box>

    <Box
       sx={{
        backgroundImage: 'linear-gradient(to bottom, #232c41 50%, #232c41)',
        paddingTop: '0px',
        paddingBottom: '0px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
      >
      <VimeoVideoPublicidad videoNumber="846394469" ></VimeoVideoPublicidad>
      </Box>

    {/* Descripcion del curso*/}
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #232c41)',
        paddingTop: '80px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } ,       
      }}        
        >
        <Box marginLeft={'50px'} marginRight={'50px'}>
        <SlideUpText text="Este es un curso fundamental para que aprendas y fortalezcas todos los conocimientos necesarios para introducirte en el diseño estructural de edificaciones"
           variant="h3" color='white' marginBottom="20px" />
        
        <SlideUpText text="El curso esta divido en 7 capítulos con duración total de 4 horas y 25 minutos"
           variant="h3" color='white' marginBottom="20px" />
        
        <SlideUpText text="Aprenderás los principales materiales y sistemas estructurales usados en la construcción, su comportamiento y una aproximación conceptual hacia el diseño estructural"
           variant="h3" color='white' marginBottom="20px" />
          </Box> 
        
        </Grid>

        <Grid item xs={12} sm={6} sx={{ order: { xs: 2, sm: 2 } }}>
        <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/fundamentos1_min.png' boxHeight='40vh' ></SingleImage>
        </Grid>      
      </Grid>
    </Box>
      {/*Mensaje 2*/}
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 2%, #141b2d)',
        paddingTop: '40px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 2 } }}>
        <SlideUpText text="En Proex Academy creamos este curso  para que" variant="h2" color='white' marginBottom="20px" />
         
          <Box display="flex" alignItems="center">
          
          <HomeLandingCard2 cardDataList={cardMotivation} />
          </Box>



                </Grid>
           
      </Grid>
    </Box>
      {/*Imagen transicion*/}

      <Box      
      sx={{
        paddingTop: '40px',
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #3e4396)',
      }}
    >
      <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/fundamentos2_min.png'></SingleImage>
    
    </Box>

      {/*Competencias profesionales a adquirir*/}
      <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #121b2b 80%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Competencias profesionales a adquirir " variant="h2" color="white" marginBottom="20px" />        
          <SlideUpText text="Aprenderas a " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemListCompentencias} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #121b2b 30%, #121b2b)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Tambien Aprenderas a" variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemListCompentencias2} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    {/*Imagen transicion*/}
    <Box      
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #121b2b)',
      }}
    >      
      <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/fundamentos3_min.png'></SingleImage>
    
    </Box>



    {/*A quien va dirido*/}

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #232c41)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },
        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="A quien va dirigido el curso " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemsDirigidoCurso} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Herramientas Necesarias " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemsHerramientas} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    {/*Temario*/}
    <Box
  sx={{
    backgroundImage: 'linear-gradient(to top, #232c41 30%, #3e4396)',
    paddingTop: '0px',
    paddingBottom: '40px',
    textAlign: { xs: 'center', sm: 'center' },
    width: '100%',
  }}
>
  <Grid container spacing={2} justifyContent="center">
    <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 }, marginLeft: '20px', marginRight: '20px' }}>
      <Typography variant="h2">Temario Curso</Typography>
      <Typography variant="h2"></Typography>
      <DropdownList modules={modules} />
    </Grid>

  </Grid>
</Box>

    {/*Metodologia Curso*/}

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #232c41)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },    
            
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Metodología " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemsMetodologiaCurso} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

      {/*Teachers*/}
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #3e4396)',
        paddingTop: '0px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
          
      }}
    >
      <Box display="flex" justifyContent="center" paddingTop={5} paddingBottom={5} >
      <SlideUpText text="El Profesor del Curso " variant="h2" color="white" marginBottom="20px" />        
      </Box> 

      <Grid container spacing={2} >
                
        
            <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }} style={{ marginTop: '10px', paddingTop: '10px' }}>               

              <Box
                sx={{
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  textAlign: { xs: 'center', sm: 'center', md: 'center' },
                }}
              >
                           
                  <Typography variant="h3">Ing. Oscar Eduardo Triviño Ayala</Typography>
              
                <Box display="flex" alignItems="center" gap="10px" sx={{textAlign: { xs: 'center', sm: 'center', md: 'center' }}}>                  
                <Avatar src="https://academy-public-resources.s3.amazonaws.com/publics/logouniversidad+Javeriana+1_rounded.png" sx={{ width: 100, height: 100 }} />
                  <Typography variant="h4">Ingeniero Civil Pontifica Universidad Javeriana-Colombia</Typography>
                  
                </Box>
                <Box display="flex" alignItems="center" gap="10px"  sx={{textAlign: { xs: 'center', sm: 'center', md: 'center' }}} >
                <Avatar src="https://academy-public-resources.s3.amazonaws.com/publics/logo+uniandes+1_rounded.png" sx={{ width: 100, height: 100 }} />
                <Typography variant="h4">Magister en ingeniería civil con énfasis en estructuras de la Universidad de los Andes-Colombia</Typography>
                </Box>
                <Typography variant="h4">Diseñador estructural con 9 años de experiencia en el diseño de Puentes, Estructuras ambientales, Industriales y edificaciones</Typography>
                <Typography variant="h4">Ingeniero apasionado por la excelencia y el conocimiento</Typography>
              </Box>

         
                </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } }}>
        <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/otimage2min.png' boxHeight='40vh' ></SingleImage>
        </Grid>      
      </Grid>
    </Box>

    


    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #232d41)',
        paddingTop: '5px',
        paddingBottom: '5px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
        <SlideUpText text=" ¿Que te brindamos en Proex Academy? " variant="h2" color='#EA810F' marginBottom="20px" />
        
    </Box>

     {/*Aqui van las card*/} 

     <Box 
            sx={{ height: '50px', overflow: 'auto',margingTop: '50px',
            
            }}/>            
            
            <Box marginTop={3} marginRight={1} marginBottom={4} marginLeft={1} alignSelf='center'
            >
            {/*
            <CardComponent list={list_filters} />  
            */}
            
            <HomeLandingCard cardDataList={cardDataList} />
      </Box> 
      
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
      
      <WhatsAppButtonComponent />
    </div>
    

    <Box display="flex" flexDirection="column" justifyContent="center" paddingTop={5} paddingBottom={5} 
       sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 2%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
       <SlideUpText text="Precios y Planes" variant="h2" color="white" marginBottom="20px" />        
      
      </Box> 



    <Box m={2} marginTop={10}>
        <PricesCourses1 colors={colors} course={4}/>
    </Box>


    <Box display="flex" flexDirection="column" justifyContent="center" paddingTop={5} paddingBottom={5} 
       sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 2%, #141b2d)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <SlideUpText text="¡Únete a nuestra comunidad de estudiantes motivados y " variant="h2" color="white" marginBottom="20px" />        
      <SlideUpText text="construye un futuro brillante en la construcción con Proex Academy! " variant="h2" color="white" marginBottom="20px" />        
      </Box> 

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 2%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >

     <Link to="/register">
               <Button style={{ fontSize: isMobile2 ? '1.2rem' : '1.5rem' ,fontWeight: isMobile2 ? 'normal' : '600'}} sx={{ fontSize: '1rem' }} variant='contained' color='secondary'>
                Registrarme y comprar  </Button>             
    </Link> 
                       

       
     
    </Box>




        
        
     


    <FooterLanding></FooterLanding>




    </div>
   
    
    
  );
};

export default Cursofundamentos;
