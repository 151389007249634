import React from 'react';
import { Box, Typography, Button, Grid,ListItem ,ListItemIcon,Stack} from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery'
import { tokens } from "../../theme";
import { useTheme } from '@emotion/react';
import SlideUpText from '../../components/TextAnimation1';
import CheckIcon from '@mui/icons-material/Check';
import CardCustom from '../../components/CardT1/CardT5';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeLandingCard from '../../components/CardT1/CardT6';
import HomeLandingCard2 from '../../components/CardT1/CardT7';
import SingleImage from '../../components/images/imagen1';
import HomeLandingCard3 from '../../components/CardT1/CardT8';
import WhatsAppButtonComponent from '../../components/whatsapp/WhatsappButton';
import FacebookButton from '../../components/Facebook/FabookDir';
import InstagramButton from '../../components/Instagram/Instagram';
import FooterLanding from './FooterLandin';
import ImageSlider from '../../components/ImageList';


const MainPageSells = () => {
    const theme = useTheme();
    const isMobile2 = useMediaQuery('(max-width: 720px)');
    const colors = tokens(theme.palette.mode);

    const cardDataList = [
      {
        title: 'Clases Ejercicios',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Desarrollamos ejemplos paso a paso para que puedas entender y aprender facilmente, Video Clases 24/7',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 1
        to:'c01',
      },
      {
        title: 'Clases conceptuales',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Clases cortas de fundamentos y conceptos, Video Clases 24/7',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 2
        to:'c02',
      },
      {
        title: 'Clases Manejo Software',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Clases 100% orientadas al trabajo del día a día de l@s ingenier@s, Video Clases 24/7',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 3
        to:'c03',
      },
      {
        title: 'Material Escrito Complementario',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Te brindamos materiales de apoyo complementario en PDF para complementar las clases',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      },
      {
        title: 'Certificado de Asistencia',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Te otorgamos certificado de asistencia al tomar todas las clases',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      },
      {
        title: ' Preguntas y respuestas',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Respondemos tus preguntas acerca de las clases en nuestro sistema de comentarios',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      }


    ];
    

    const cardDataList2 = [
      {
        title: 'Mejorar tus capacidades en diseño Estructural',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 1
        to:'c01',
      },
      {
        title: 'Aprender o Actualizarte en Metodologías de análisis y diseño Estructural',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
       
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 2
        to:'c02',
      },
      {
        title: 'Impulsar en saltos tu carrera profesional',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 3
        to:'c03',
      }
    ];





    const cardDataList3 = [
      {
        title: 'Cursos Estructurales',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Cursos de fundamentos, análisis y diseño estructural aplicado al trabajo practico de l@s Ingenier@s civiles',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 1
        to:'cursos',
      },
   


    ];



    const images = [
      'https://academy-public-resources.s3.amazonaws.com/publics/STUDENT+1_min.png',      
      'https://academy-public-resources.s3.amazonaws.com/publics/studen2+1_min.png',
      'https://academy-public-resources.s3.amazonaws.com/publics/studen_worker1+1_min.png',
      'https://academy-public-resources.s3.amazonaws.com/publics/studen_worker2+1_min.png'
      
    ];



  
  return (
    <div>
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #141b2d 50%, #232c41)',
        paddingTop: '110px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } }}>
         
          <SlideUpText text="Construye conocimiento, potencia tu éxito en la construcción!" variant="h1" color="white" marginBottom="20px" />
          <SlideUpText text="Descubre el poder de la excelencia en la construcción con Proex Academy" variant="h3" color={colors.blueAccent[400]} marginBottom="20px" />
          <Button variant="contained" color="secondary" component={Link} to="/cursos"
          sx={{ fontSize: '1rem',border: 'none',fontWeight: isMobile2 ? 'normal' : '600' }}
          >
            Quiero Información de los Cursos
          </Button>
        </Grid>
        
       
      </Grid>
    </Box>
    


    <Box      
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #232c41)',
      }}
    >
      <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/bridge+and+logo_min.png'></SingleImage>
    
    </Box>

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #232c41)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },
        width: '100%',
      }}
      >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } }}>
          <SlideUpText text="Somos Proex academy" variant="h1" color='white' marginBottom="20px" />
          <SlideUpText text="El Centro de aprendizaje para estudiantes, profesionales y empresas de la construcción" variant="h3" color='white' marginBottom="20px" />
          <SlideUpText text="Nuestra misión es impulsar el desarrollo de la ingeniería de alta calidad en latinoamerica, brindando programas educativos y capacitación especializada que promuevan la excelencia técnica en la construcción" variant="h3" color='white' marginBottom="20px" />
          <ImageSlider imageUrls={images} time={5500}/>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }}>
        <Typography variant="body1" component="div" align="center" sx={{ marginTop: '50px' }}>
                  <Typography variant="body1" component="div" align="center" sx={{ marginTop: '100px' }}>
                  
                </Typography>
                </Typography>
          <Box display="flex" justifyContent="center">
            
              <HomeLandingCard3 cardDataList={cardDataList3} />
          
          </Box>
        </Grid>
      </Grid>
    </Box>



   
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #3e4396)',
        paddingTop: '0px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={2} >
        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }} style={{ marginTop: '250px', paddingTop: '10px' }}>
        <SlideUpText text="Con instructores expertos y una plataforma de aprendizaje en línea" variant="h2" color='white' marginBottom="20px" />
         
                </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } }}>
        
        </Grid>      
      </Grid>
    </Box>


    
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #232c41)',
        paddingTop: '80px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } }}>
        <SlideUpText text="Desde el diseño estructural y hasta las últimas tendencias en construcción sostenible,
          te preparamos para afrontar los desafíos del mundo real" variant="h2" color='white' marginBottom="20px" />
        
                </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }}>
        <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/bridgesheet-min.png' boxHeight='35vh' ></SingleImage>
        </Grid>      
      </Grid>
    </Box>
    
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #141b2d)',
        paddingTop: '5px',
        paddingBottom: '5px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
      }}
    >
        <SlideUpText text=" ¿Que te brindamos en Proex Academy? " variant="h2" color='#EA810F' marginBottom="20px" />
        
    </Box>

     {/*Aqui van las card*/} 

     <Box 
            sx={{ height: '50px', overflow: 'auto',margingTop: '50px' }}/>            
            
            <Box marginTop={3} marginRight={1} marginBottom={4} marginLeft={1} alignSelf='center'>
            {/*
            <CardComponent list={list_filters} />  
            */}
            
            <HomeLandingCard cardDataList={cardDataList} />
      </Box> 
      
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
      
      <WhatsAppButtonComponent />
    </div>




    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 2%, #141b2d)',
        paddingTop: '40px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 2 } }}>
        <SlideUpText text=" Te animamos a tomar un curso en Academy para " variant="h2" color='white' marginBottom="20px" />
         
          <Box display="flex" alignItems="center">
          
          <HomeLandingCard2 cardDataList={cardDataList2} />
          </Box>



                </Grid>
           
      </Grid>
    </Box>
 
  

   
    

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 2%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } }}>
            
          <Typography variant="h2" sx={{ marginBottom: '20px' }} >
          ¡Únete a nuestra comunidad de estudiantes motivados y construye un futuro brillante en la construcción con Proex Academy! 
          </Typography>
                </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }}>
        <Box 
        sx={{
            display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        }}
         
        >
          <Link to="/cursos">
               <Button style={{ fontSize: isMobile2 ? '0.8rem' : '1rem' ,fontWeight: isMobile2 ? 'normal' : '600'}} sx={{ fontSize: '1rem' ,backgroundColor: "#EA810F", color: "#141b2d"}} variant='contained' >
                Ver Cursos</Button>             
             </Link>
               
            <Link to="/register">
               <Button style={{ fontSize: isMobile2 ? '0.8rem' : '1rem' ,fontWeight: isMobile2 ? 'normal' : '600'}} sx={{ fontSize: '1rem' }} variant='contained' color='secondary'>
                Registrarme Gratis</Button>             
             </Link>
            
             </Box>
                       

        </Grid>      
      </Grid>
    </Box>


    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #141b2d)',
        paddingTop: '5px',
        paddingBottom: '5px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } }}>
        <Box display="flex" flexDirection="column" alignItems="center">
      <Box width={200} mb={2}>
        <InstagramButton />
      </Box>
      <Box width={200}>
        <FacebookButton />
      </Box>
    </Box>
        
        
        </Grid>
           
      </Grid>
    </Box>

    <FooterLanding></FooterLanding>




    </div>
   
    
    
  );
};

export default MainPageSells;
