import {Grid,Paper,Stack,TextField,Button,Box, Select,MenuItem,FormControl,InputLabel,CircularProgress, Typography } from "@mui/material";
import Icon from '@mui/material/Icon';
import Header from "../../components/Header";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from "react";
import { useNavigate,Link } from "react-router-dom";
import { useEffect} from "react";
import FooterLanding from "../sells/FooterLandin";
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import PricesCourses1 from "../sells/prices1";
import WhatsAppButtonComponent from "../../components/whatsapp/WhatsappButton";
import ImageSlider from "../../components/ImageList";


function RegisterPage() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    const navigateOut=useNavigate()



    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [education, setEducation] = useState("");
    const [cellphone, setCellphone] = useState("");
    const [password, setPassword] = useState("");


    const [fieldErrors, setFieldErrors] = useState({
      name: false,
      email: false,
      education: false,
      cellphone: false,
      password: false,
    });


    const handleNameChange = (event) => {
      const value = event.target.value;
      setName(value);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        name: value === '',
      }));
    };

    const handleEmailChange = (event) => {
      const value = event.target.value;
      setEmail(value);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        email: value === '' || !validateEmail(value),
      }));
    };
    const validateEmail = (email) => {
      // Utiliza una expresión regular para validar el formato del correo electrónico
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const handleEducationChange = (event) => {
      const value = event.target.value;
      setEducation(value);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        education: value === '',
      }));
    };

    const handleCellphoneChange = (event) => {
      const value = event.target.value;
      setCellphone(value);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        cellphone: value === '',
      }));
    };

    const handlePasswordChange = (event) => {
      const value = event.target.value;
      setPassword(value);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        password: value === '',
      }));
    };

    

    const onRegister = async () => {
      const response = await fetch('https://8ori4qvsnc.execute-api.us-east-1.amazonaws.com/dev/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          typeoperation: "create",
          "email-academy": email,
          name: name,
          cellphone: cellphone,
          education_level: education,
          password: password,          
          statustudent: false,
          statusworker: false,
          statusdirector: false,
          statusfree: true,          
          owner: false,          
          apps: [],
          courses: [],
          books: [],
          enterprise: "",
          plan: [{courses:["none"]}, {ebooks:["none"]}, {apps:["none"]}]
        })
      });
    
      if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
      }
    
      const data = await response.json();      
      console.log(data.stateregister);
      alert(data.body);
    }
    
    
    const [isLoading, setIsLoading] = useState(false);

    const handleRegisterClick = async () => {
      // Verificar campos vacíos

      if (name === '') {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          name: true,
        }));
      }
      if (email === '') {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          email: true,
        }));
      }
      if (education === '') {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          education: true,
        }));
      }
      if (cellphone === '') {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          cellphone: true,
        }));
      }
      if (password === '') {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          password: true,
        }));
      }
      
      if (fieldErrors.email) {
        return; // Detener el flujo de ejecución si hay errores
      }
      // Si hay algún campo vacío, no continuar con el registro
      if (name === '' || email === '' || education === '' || cellphone === '' || password === '') {
        return;
      }
  
      setIsLoading(true);
      try {
        await onRegister();
        setIsLoading(false);
        // Resto de la lógica después de registrar exitosamente
      } catch (error) {
        setIsLoading(false);
        // Resto de la lógica de manejo de error
      }
    };

    const colors = ['#ffcccc', '#4cceac', '#ffcccc'];

    const images = [
      'https://academy-public-resources.s3.amazonaws.com/publics/worker+student+3+1_min.png',
      'https://academy-public-resources.s3.amazonaws.com/publics/universitary+student+3_min.png',
      'https://academy-public-resources.s3.amazonaws.com/publics/worker+student+4+1_min.png',
      'https://academy-public-resources.s3.amazonaws.com/universitary+student2+1_min.png',


    ];
  
    return (      
      <Box>
        <Box              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',
              paddingtop: '1px',
              marginTop: '105px',
              marginBottom: '0px' 
              }}
              > 
                <Stack direction='column'  sx={{ margin: '1rem',alignItems: 'center' ,justifyContent: 'center' }} spacing={1}>
                  <Grid>
                  <Typography variant="h1" > Inscríbete y comienza a estudiar en 2 Pasos</Typography>
                  </Grid>
                  
                  <Stack  direction="row" alignItems="center" justifyContent="center" sx={{ margin: '0.5rem',alignItems: 'center' ,justifyContent: 'center' }} spacing={2}>
                    <LooksOneIcon sx={{ fontSize: '35px' }} style={{ color: '#cf7f0f' }}/>                  
                    <Typography variant="h2" color="#cf7f0f" >Registrate con tus datos  </Typography>
                  </Stack>               
          </Stack>
        </Box>

      <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',
      paddingtop: '1px',
      marginTop: '65px',
      marginBottom: '65px' 
      }}
      >
      
    <Box height={500} />
    <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'center',display: isDesktop ? 'block' : 'none'}}>

        <ImageSlider imageUrls={images} time={6500}/>
              
      </Box>
      <Box m="30px" sx={{ display: 'flex', width:'350px',  height: '80vh', alignItems: 'center', justifyContent: 'center',
      //borderLeft: '2px solid grey',
      //border: '1px solid #CCCCCC', // Establece un borde gris claro de 1 píxel    
      padding: '20px', // Establece un padding de 20 píxeles en todos los lados del componente Box
      }}> 
          <Stack direction='column' 
          spacing={4}>
            <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
              
                <img
                  alt="profile-user"
                  width="180px"
                  height="70px"
                  src={`../../assets/logoproexblanco.png`} 
                  style={{ paddingTop: '10px' }}                 
                />
          
            </Box>
            <Header title="REGISTRO EN ACADEMY" subtitle="sin costo" />
            <TextField variant="filled" label='Nombre'
             error={fieldErrors.name}
             helperText={fieldErrors.name && 'Este campo es requerido'}
            onChange={handleNameChange}
            sx={{
              '& input::placeholder': {
                color: fieldErrors.name ? 'red' : undefined,
              },
            }}
            
            />
            <TextField 
            onChange={handleEmailChange}
            variant="filled" label='Correo'
            error={fieldErrors.email}
            helperText={fieldErrors.email && 'Este campo es requerido, Ingresar Correo Valido'}            
            sx={{
             '& input::placeholder': {
               color: fieldErrors.email ? 'red' : undefined,
             },
           }}
            />      
              
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Nivel Educativo</InputLabel>
                <Select onChange={handleEducationChange}
                labelId="education-label"
                value={education}                
                error={fieldErrors.education}
                >


                  <MenuItem value={"Estudiante-Universitario"}>Estudiante Universitario</MenuItem>
                  <MenuItem value={"Profesional"}>Profesional</MenuItem>
                  <MenuItem value={"Especialista"}>Especialista</MenuItem>
                  <MenuItem value={"Magister"}>Magister</MenuItem>
                  <MenuItem value={"Doctorado"}>Doctorado</MenuItem>
                </Select>
            </FormControl>        
            <TextField 
            variant="filled" label='Celular/WhatsApp' 
            error={fieldErrors.cellphone}
            helperText={fieldErrors.cellphone && 'Este campo es requerido'}              
            sx={{
                '& input::placeholder': {
                  color: fieldErrors.cellphone ? 'red' : undefined,
                },
              }}
            onChange={handleCellphoneChange}/>
            <TextField variant="filled" type="password" 
            label='Contraseña'
            error={fieldErrors.password}
            helperText={fieldErrors.password && 'Este campo es requerido'}
            onChange={handlePasswordChange}/>                       
            <Button variant='contained' color='secondary' onClick={handleRegisterClick}
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={20} />}
            >{isLoading ? 'Registrando Estudiante...' : 'Registrarme'}</Button>                       
          </Stack>
        </Box>
        </Box>



        <Box              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',
              paddingtop: '10px',
              marginTop: '10px',
              marginBottom: '10px' 
              }}
              >
                <Stack direction='column'  sx={{ alignItems: 'center' }} spacing={1}>
                  <Stack direction="row" alignItems="center" justifyContent="center">
                    <LooksTwoIcon sx={{ fontSize: '35px' }} style={{ color: '#cf7f0f' }}/>                  
                    <Typography variant="h2" >Adquiere uno de nuestros cursos o Inscribete a un plan</Typography>
                  </Stack>               
          </Stack>
        </Box>


        <Box m={2} marginTop={10}>
        <PricesCourses1 colors={colors} />
        </Box>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>      
          <WhatsAppButtonComponent />
        </div>

        <Box  >
          <FooterLanding></FooterLanding>
        </Box>
    </Box>
     
    );
    
  }
  export default RegisterPage