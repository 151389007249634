import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const ListItemsX = ({ items, backgroundColor, textColor, iconColor, textSize, iconSize }) => {
  return (
    <List>
      
        <ListItem         
          sx={{
            backgroundColor: backgroundColor,
            marginBottom: '16px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            paddingLeft: '32px',
            border:'10'
          }}
        >
          <ListItemIcon sx={{ color: iconColor, fontSize: iconSize }}>
            <ClearIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography variant={textSize} sx={{ color: textColor }}>{items}</Typography>
          </ListItemText>
          
        </ListItem>
    
    </List>
  );
};

export default ListItemsX;
