import React from 'react';
import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';

const FacebookButton = () => {
  const handleFacebookClick = () => {
    window.open('https://www.facebook.com/academiaestructuralproex', '_blank');
  };

  return (
    <Button
      variant="contained"
      style={{
        backgroundColor: '#1877f2',
        color: '#ffffff',
        fontSize: '0.8rem',
        
        textTransform: 'none',
      }}
      startIcon={<FacebookIcon />}
      onClick={handleFacebookClick}
      size="small"
    >
      Ir a Facebook
    </Button>
  );
};

export default FacebookButton;
