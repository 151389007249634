import React from 'react';
import { Box, Typography, Button, Grid,ListItem ,ListItemIcon,Stack} from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery'
import { tokens } from "../../theme";
import { useTheme } from '@emotion/react';
import SlideUpText from '../../components/TextAnimation1';
import CheckIcon from '@mui/icons-material/Check';
import CardCustom from '../../components/CardT1/CardT5';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeLandingCard from '../../components/CardT1/CardT6';
import HomeLandingCard2 from '../../components/CardT1/CardT7';
import SingleImage from '../../components/images/imagen1';
import HomeLandingCard3 from '../../components/CardT1/CardT8';

import FacebookButton from '../../components/Facebook/FabookDir';
import InstagramButton from '../../components/Instagram/Instagram';
import FooterLanding from './FooterLandin';
import PricesCourses2 from './prices2';
import PricesCourses1 from './prices1';
import WhatsAppButtonComponent from '../../components/whatsapp/WhatsappButton';
import ListItems from '../../components/Lista2';
import ListItemsX from '../../components/Lista3';

const MainPageSellsEnterprise = () => {
    const theme = useTheme();
    const isMobile2 = useMediaQuery('(max-width: 720px)');
    const colors = tokens(theme.palette.mode);

    const itemListMetodologia = ['Clases pregrabadas para tomar por nuestra plataforma Proex Academy',
                                 'Clases en vivo OnLine por Zoom',
                                 'Talleres de seguimiento en vivo por Zoom',
                                 'Talleres de seguimiento en vivo presenciales',
                                 'Seguimiento al proceso de aprendizaje mediante Proex Academy',
                                 'Seguimiento de tareas mediante Proex Academy',
                                ];

    const itemPrograma1= ['Acceso a clases ya pregrabadas en Proex Academy,estos son cursos de capacitación profesional en diseño estructural',    
    'Este enfoque de programa incluye la creación de cursos de diseño estructural para tu empresa de acuerdo a tus necesidades',
    'La tipología de los cursos es pregrabada',
    'Podemos hacer cursos para ti que abarquen areas como:',
    'Análisis estructural ',
    'Diseño Estructural sismo-resistente de Edificios en concreto, acero , mamposteria y madera en diversos tipologias de sistemas estructurales '                          , 
    'Diseño puentes en concreto reforzado, preesforzado y acero estructural',     
    'Diseño Estructural Edificaciones  Industriales', 
    'Diseño Estructural Estructuras Oil & Gas', 
    'Diseño Taques y Estructuras Hidraulicas',        
    'Se Incluye manejo profesional de Excel y Mathcad ',
    'Se incluye uso de Normativas Colombianas y Estadounidenses para el diseño estructural',
    'Se incluye manejo profesional de Software de Análisis y diseño estructural',  
    'Seguimiento de tareas por plataforma Proex Academy',
    'Los cursos se crean o imparten de acuerdo a las necesidades de tu empresa',
    'Los costos dependen del numero de estudiantes y numero de cursos ha crear', 
   ];

   const itemPrograma2= ['Incluye todo el Programa Starter y ademas:',
   'Todos los cursos creados son enfocados en que el estudiante aprenda a usar Python para la optimización de tareas de diseño estructural',    
   'Enfocado en el mejoramiento de habilidades técnicas en la automatización de procesos y aumento de productividad en el trabajo',
   'La tipología de los cursos es pregrabada y virtual en vivo por Zoom',   
   'Se incluye manejo profesional de Excel, Mathcad y Nivel intermedio de Python',
   'Se incluye uso de Normativas Colombianas y Estadounidenses para el diseño estructural',
   'Se incluye la automatizacion de procesos con Software de Análisis y diseño estructural',  
   'Seguimiento de tareas por plataforma Proex Academy',
   'Este enfoque es para equipos ya muy bien formados que desea aumentar su productividad a travez de la programación',
   'Los cursos se crean o imparten de acuerdo a las necesidades de tu empresa',
   'Los costos dependen del numero de estudiantes y numero de cursos ha crear', 
  ];

  const itemPrograma3= ['Incluye todo el Programa Starter y ademas:',  
  'El programa esta enfocado crear cursos para enseñarle a los estudiantes a construir aplicaciones web enfocadas en la Ingenieria y calculo matemático',
  'Creamos los cursos de acuerdo a las necesidades que tu empresa tiene con una aplicacion para calculo',
  'El principal objetivo es crear grupos de trabajo potentes que a su vez crean un gran valor en la empresa y que perdure en el tiempo',
  'Al finalizar el curso tendras una nueva aplicacion para usar dia a dia en tu empresa, creada por tus colaboradores',
  'Se enseña la construccion de Interfaz con react-js (aplicacion web moderna)',
  'Se enseña la contruccion de Base de Datos y Motor de calculo con Python',
  'Aplicacion completamente alojada en Web',
  'El estudiante estara completamente capacitado para hacer aplicaciones productivas para la empresa',
  'Estos cursos no requiere conocimientos previos en programación',
  'Son Clases Pregrabadas y en vivo On-Line ',
  'Los cursos se crean o imparten de acuerdo a las necesidades de tu empresa',
  'Los costos dependen del numero de estudiantes y numero de cursos ha crear', 
 ];
 
  return (
    <div>
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #141b2d 50%, #232c41)',
        paddingTop: '110px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    > 
      <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #141b2b)',
        paddingTop: '0px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
      }}
      >
      <SlideUpText text="Construye conocimiento, potencia la productividad de tu Empresa" variant="h1" color="#white" marginBottom="20px" />
      <SlideUpText text="Desarrolla el potencial de tu equipo de diseño estructural  " variant="h2" color="#white" marginBottom="20px" />      
      <SlideUpText text="creamos cursos de capacitación especializados por ti" variant="h2" color="white" marginBottom="20px" />
      
      <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/construction_enterprise_min.png'></SingleImage>
      </Box>

      <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #3e4396)',
        paddingTop: '0px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
      }}
    >
      <Grid container spacing={2} >
        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } }} style={{ marginTop: '100px', paddingTop: '10px' }}>
        <SlideUpText text="En Proex Academy, estamos comprometidos en contribuir a la construcción de" variant="h3" color="white" marginBottom="20px" />
        <SlideUpText text="conocimiento en ingeniería y potenciar el éxito de las empresas y profesionales en " variant="h3" color="white" marginBottom="20px" />
          <SlideUpText text="el campo de la ingeniería estructural" variant="h3" color="white" marginBottom="20px" />
         
                </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }}>
        <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/builgindtall_min.png' boxHeight='45vh' ></SingleImage>
        </Grid>      
      </Grid>
    </Box> 


    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #232c41)',
        paddingTop: '0px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
      }}
    >
      <Grid container spacing={2} >
        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 2, sm: 1 } }} style={{ marginTop: '50px', paddingTop: '10px' }}>
        <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/gear+2.png' boxHeight='35vh' ></SingleImage>
         
                </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }} style={{ marginTop: '80px', paddingTop: '10px' }}>
        <SlideUpText text="Sabemos que la productividad es fundamental para el éxito de cualquier empresa" variant="h3" color="white" marginBottom="20px" />
        <SlideUpText text="Por eso creamos para ti programas de capacitación a la medida de tu empresa, para mejorar" variant="h3" color="white" marginBottom="20px" />
        <SlideUpText text="la eficiencia y rendimiento de tu equipo de ingenieria" variant="h3" color="white" marginBottom="20px" />
          
        
        </Grid>      
      </Grid>
    </Box> 

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2b 30%, #232c41)',
        paddingTop: '0px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
      }}
    >
      <Grid container spacing={2} >
        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } }} style={{ marginTop: '100px', paddingTop: '10px' }}>
        <SlideUpText text="Los programas que creamos se centran en fortalecer las habilidades técnicas, " variant="h3" color="white" marginBottom="20px" />
        <SlideUpText text="optimizar los procesos de trabajo,reducir tiempos de ejecución " variant="h3" color="white" marginBottom="20px" />
        <SlideUpText text="y maximizar la calidad de los resultados" variant="h3" color="white" marginBottom="20px" />
          <SlideUpText text="en los equipos de ingenieria de tu empresa" variant="h3" color="white" marginBottom="20px" />
         
                </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }}>
        <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/STRUCTURAL_SKILLS_MIN.png' boxHeight='45vh' ></SingleImage>
        </Grid>      
      </Grid>
    </Box>


    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #141b2b)',
        paddingTop: '0px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
      }}
    >
      <Grid container spacing={2} >        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 2, sm: 1 } }} style={{ marginTop: '50px', paddingTop: '10px' }}>
        <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/SOFTWARETOWORK_min.png' boxHeight='45vh' ></SingleImage>         
        </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }} style={{ marginTop: '100px', paddingTop: '10px' }}>        
        <SlideUpText text="Nos gusta enseñar conocimientos conceptuales de diseño estructural y" variant="h3" color="white" marginBottom="20px" />
        <SlideUpText text="conocimientos prácticos para realizar diseños estructurales en la vida real." variant="h3" color="white" marginBottom="20px" />
        <SlideUpText text="Utilizamos los software más demandados en la industria y técnicas de optimización y automatización " variant="h3" color="white" marginBottom="20px" />
        <SlideUpText text="através de la programación con Python y ReactJS!" variant="h3" color="white" marginBottom="20px" />
        </Grid>      
      </Grid>
    </Box> 
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Metodología de estudio" variant="h2" color="white" marginBottom="20px" />        
          <SlideUpText text="en los cursos creados para tu empresa" variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemListMetodologia} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2b 30%, #3e4396 )',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Tenemos tres enfoques para la creación de programas de capacitación Empresarial" variant="h2" color="white" marginBottom="20px" />        
          <SlideUpText text="tu puedes escoger el que mas se ajuste a tu medida" variant="h2" color="white" marginBottom="20px" />        
          <SlideUpText text="y nosotros hacemos los cursos por ti" variant="h2" color="white" marginBottom="20px" />        
          <Box
      sx={{
        backgroundColor: '#202736',
        borderRadius: '8px',
        border: '2px solid #4cceac',
        margin: '16px',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 600px)': {
          margin: '8px',
          padding: '8px',
        },
      }}
      
    >
      <Stack direction='column'>
      <SlideUpText text="Programa Enfoque Starter" variant="h2" color="#4cceac" marginBottom="20px" />    
      <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/proexacademyinside.png' boxHeight='40vh' ></SingleImage>             
      <SlideUpText text="Caracteristicas" variant="h2" color="#ea810f" marginBottom="20px" />    
      <ListItems items={itemPrograma1} backgroundColor="#202736" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
      
      <ListItemsX  items={"No Incluye Cursos de Programación"} backgroundColor="#202736" textColor="white" iconColor="red"  textSize="h4"   iconSize={30}     ></ListItemsX>
      <ListItemsX  items={"No Incluye Cursos Virtuales en Vivo"} backgroundColor="#202736" textColor="white" iconColor="red"  textSize="h4"   iconSize={30}     ></ListItemsX>
      <ListItemsX  items={"No Incluye Talleres de capacitacion presenciales"} backgroundColor="#202736" textColor="white" iconColor="red"  textSize="h4"   iconSize={30}     ></ListItemsX>
      
      
      <Button variant="contained" color="secondary" component={Link} to="https://calendly.com/hirianoa-proex-academy/30min"
          sx={{ fontSize: '1rem',border: 'none',fontWeight: isMobile2 ? 'normal' : '600' }}
          >
            AGENDAR CITA
          </Button>
      </Stack>
    </Box>


    <Box
      sx={{
        backgroundColor: '#202736',
        borderRadius: '8px',
        border: '2px solid #f64854',
        margin: '16px',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 600px)': {
          margin: '8px',
          padding: '8px',
        },
      }}
      
    >
      <Stack direction='column'>
      <SlideUpText text="Programa Enfoque Optimizacion en Diseño Estructural" variant="h2" color="#f64854" marginBottom="20px" />        
      <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/pythonlib.png' boxHeight='40vh' ></SingleImage>             
      <ListItems items={itemPrograma2} backgroundColor="#202736" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
      <ListItemsX  items={"No Incluye Programación Web"} backgroundColor="#202736" textColor="white" iconColor="red"  textSize="h4"   iconSize={30}     ></ListItemsX>
      <ListItemsX  items={"No Incluye Guia en la creacion de aplicación Web"} backgroundColor="#202736" textColor="white" iconColor="red"  textSize="h4"   iconSize={30}     ></ListItemsX>
      
      
      
      <Button variant="contained"  component={Link} to="https://calendly.com/hirianoa-proex-academy/30min"
          sx={{ fontSize: '1rem',border: 'none',backgroundColor: '#f64854',fontWeight: isMobile2 ? 'normal' : '600' }}
          >
            AGENDAR CITA
          </Button>
      </Stack>
    </Box>



    <Box
      sx={{
        backgroundColor: '#202736',
        borderRadius: '8px',
        border: '3px solid #ea810f',
        margin: '16px',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 600px)': {
          margin: '8px',
          padding: '8px',
        },
      }}
      
    >
      <Stack direction='column'>
      <SlideUpText text="Programa Enfoque Mi Aplicación de Ingenieria" variant="h2" color="#ea810f" marginBottom="20px" />        
      <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/softwaredashboard.png' boxHeight='40vh' ></SingleImage>             
      <ListItems items={itemPrograma3} backgroundColor="#202736" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>              
      <Button variant="contained"  component={Link} to="https://calendly.com/hirianoa-proex-academy/30min"
          sx={{ fontSize: '1rem',border: 'none',backgroundColor: '#ea810f',fontWeight: isMobile2 ? 'normal' : '600' }}
          >
            AGENDAR CITA
          </Button>
      </Stack>
    </Box>


         </Grid>
      </Grid>
    </Box>
    


    

    
    </Box>



    <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #232c41 50%, #141b2b)',
        paddingTop: '80px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } }}>
         
          <SlideUpText text="Te invitamos a conocernos y crear tus programas de capacitacion" variant="h1" color="white" marginBottom="20px" />
          <SlideUpText text="para tu equipo  diseño estructural con nosotros" variant="h1" color="white" marginBottom="20px" />
          
          <Button variant="contained" color="secondary" component={Link} to="https://calendly.com/hirianoa-proex-academy/30min"
          sx={{ fontSize: '1rem',border: 'none',fontWeight: isMobile2 ? 'normal' : '600' }}
          >
            AGENDAR CITA
          </Button>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>      
            <WhatsAppButtonComponent />
          </div>

      
          
        </Grid>
        
       
      </Grid>
    </Box>

   
    










  



   

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #141b2d)',
        paddingTop: '5px',
        paddingBottom: '5px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } }}>
        <Box display="flex" flexDirection="column" alignItems="center">
 
    </Box>
        
        
        </Grid>
           
      </Grid>
    </Box>

    

    <FooterLanding></FooterLanding>




    </div>
   
    
    
  );
};

export default MainPageSellsEnterprise;

