import { Typography,Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import TableRowsIcon from '@mui/icons-material/TableRows';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { Menu, MenuItem,Item } from '@mui/material';
import {Link,NavLink} from 'react-router-dom';




const Topbar = ({onLogout}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  
  
  const appBarStyles = {
    position: 'fixed',
    top: 0,
    left:  isMobile ? 0 : 280,
    right: 0,
    zIndex: 9999,

  };

  
  

  ///control menu
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setIsMenuOpen(false);
  };
  
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);    
  };


  const jump=(vinculo)=>{
    <Link to={vinculo}></Link>
    
    }
    const linkStyles = {
      textDecoration: 'none',
      color: 'inherit',
    }; 

    return (
      
      <Box style={appBarStyles} display="flex" justifyContent="space-between" p={2}
      sx={{backgroundColor: '#141b2d'}}>   
        
        {/* BOTON MENU MOBILE */}
        <Box display="flex"
        sx={{display: isMobile ? 'block' : 'none'}}>   
          <IconButton onClick={handleMenuClick}>
            <TableRowsIcon />
          </IconButton>
          <Menu
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        style={{
          marginTop: '40px',
          marginLeft: '5px',
        }}
        anchorEl={null}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        MenuListProps={{
          sx: {
            '& .MuiMenuItem-root': {
              fontSize: '1rem',
              padding: '20px',
            },
          },
        }}
        >

        <MenuItem onClick={handleClose}>
            <NavLink to="/" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1"> Home</Typography>
            </NavLink>
        </MenuItem>
       
        <MenuItem onClick={handleClose}>
            <NavLink to="/courses" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1"> Cursos</Typography>
            </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <NavLink to="/marketplace" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1">Marketplace </Typography>
            </NavLink>   
        </MenuItem>
        {/*
        <MenuItem onClick={handleClose}>
        <NavLink to="/administration" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1">Administración</Typography>
            </NavLink> 
        </MenuItem>
        */}
       
        <MenuItem onClick={handleClose}>
            <NavLink to="/freebooks" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1"> Ebooks libres</Typography>
            </NavLink>
        </MenuItem>
       
        
        {/*
        <MenuItem onClick={handleClose}>
            <NavLink to="/myapps" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1"> Apps</Typography>
            </NavLink>
        </MenuItem>
        

        <MenuItem onClick={handleClose}>
            <NavLink to="/myebooks" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1"> Ebooks</Typography>
            </NavLink>
        </MenuItem>
        */}  
        {/*
        <MenuItem onClick={handleClose}>
        <NavLink to="/freecourses" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1"> Cursos Libres</Typography>
            </NavLink>
        </MenuItem>
      
       
        <MenuItem onClick={handleClose}>
        <NavLink to="/notebook" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1"> Bloc de notas</Typography>
            </NavLink>
        
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <NavLink to="/mytasks" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1"> Tareas</Typography>
        </NavLink>   
            <MenuItem onClick={handleClose}>
        <NavLink to="/marketplaceapps" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1">Marketplace Apps</Typography>
            </NavLink> 
        </MenuItem>

        </MenuItem>

        {/*
        
         
        */}
        <MenuItem onClick={handleClose}>
        <NavLink to="/user" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1">Mi Usuario</Typography>
            </NavLink> 
        </MenuItem>
     
        
        
      </Menu>

        </Box>

        {/* LOGO ACADEMY */}
        <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="120px"
                  height="40px"
                  src={`../../assets/logoproexblanco.png`}
                  
                />
              </Box>

      {/* SEARCH BAR 
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>*/}

      {/* ICONS */}
      
      <Box display="flex">
        {/*
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>*/}
        {/*
        <Link to="/notifications">     
          <IconButton>
            <NotificationsOutlinedIcon />
          </IconButton>
        </Link>
        
        
        */}

        {/*
        <Link to="/administration">      
          <IconButton>
            <SettingsOutlinedIcon />
          </IconButton>
        </Link>
        */}
        
        <Link to="/user">      
         <IconButton>
           <PersonOutlinedIcon />
         </IconButton>
        </Link>

        <IconButton>
          <CloseIcon onClick={onLogout}/>
        </IconButton>

      
      </Box>
    </Box>
  );
};

export default Topbar;
