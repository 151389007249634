import {Paper,Stack,TextField,Button,Box, Select,MenuItem,FormControl,InputLabel,CircularProgress,Grid } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from "react";
import { useNavigate,Link } from "react-router-dom";
import { useEffect} from "react";
import CardFreeEbooks from '../../components/CardT1/CardT4';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';



function MyFreeEbooks() {
    const cardData = [
        {
          title: 'Minibook- Diseño estructural vigas de concreto reforzado por flexión',
          description: 'Acorde con ACI 318-19 & NSR-10',
          imageUrl: 'https://academy-books.s3.amazonaws.com/freebooks/fb-01/refbeam.png',
          to: 's01'
        }
    
      ];

    return (
        <Box sx={{marginTop:10,marginLeft:5,marginRight:5, marginBottom:5}}>
      
        
        <Grid container spacing={2} justifyContent="center">

        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <CardFreeEbooks
              title={card.title}
              description={card.description}
              imageUrl={card.imageUrl}  
              to={card.to}  
            />
          </Grid>
        ))}

        
      </Grid>
     
        
    
        

      </Box>
      )
  }
export default MyFreeEbooks






