import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, Grid, Select, MenuItem, Box } from '@mui/material';
import { useCurrency } from '../../context/CurrencyContext';

const PricesCourses1 = ({ course }) => {
  const [selectedCourse, setSelectedCourse] = useState(1);

  useEffect(() => {
    if (course && [1, 2, 3, 4, 5].includes(course)) {
      setSelectedCourse(course);
    } else {
      setSelectedCourse(1);
    }
  }, [course]);

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  const handleButtonClick = () => {
    const selected = courseOptions.find(option => option.value === selectedCourse);
    if (selected) {
      window.location.href = getPaymentLink(selectedCourse);
    }
  };

  const currency = useCurrency();

  const courseOptions = [
    { value: 1, label: 'Diseño Estructural Puentes Viga acero tipo I y tipo cajon' },
    { value: 2, label: 'Modelacion y diseño estructural Edificios en muros de concreto con Etabs' },
    { value: 3, label: 'Avaluo de Cargas para diseño estructural de edificios' },
    { value: 4, label: 'Fundamentos del diseño estructural de edificaciones' },
    { value: 5, label: 'Desarrollo de aplicaciones con inteligencia artificial' },
  ];

  const getSubtitle = () => {
    switch (selectedCourse) {
      case 1:
        return currency === 'COP' ? '$ 450.000 COP' : '$ 115 USD';
      case 2:
        return currency === 'COP' ? '$ 300.000 COP' : '$ 75 USD';
      case 3:
        return currency === 'COP' ? '$ 200.000 COP' : '$ 50 USD';
      case 4:
        return currency === 'COP' ? '$ 200.000 COP' : '$ 50 USD';
      case 5:
        return currency === 'COP' ? '$ 320.000 COP' : '$ 80 USD';
      default:
        return '';
    }
  };



  const getPaymentLink = (courseId) => {
    const links = {
      COP: {
        1: 'https://payco.link/2240450',
        2: 'https://payco.link/2240457',
        3: 'https://payco.link/2240467',
        4: 'https://payco.link/2240471',
        5: 'https://payco.link/3114719',
      },
      USD: {
        1: 'https://payco.link/3131982',
        2: 'https://payco.link/3131993',
        3: 'https://payco.link/3131998',
        4: 'https://payco.link/3132003',
        5: 'https://payco.link/3114570',
      }
    };
    return currency === 'COP' ? links.COP[courseId] : links.USD[courseId];
  };

  const colors = ['#141b2b', '#141b2b', '#141b2b'];

  return (
    <Box sx={{ mx: 5 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={4}>
          <Card style={{ backgroundColor: colors[0] }}
            sx={{
              borderColor: '#4cceac',
              borderWidth: '2px',
              borderStyle: 'solid',
            }}
          >
            <CardContent>
              <Typography variant="h3" align="center" color="#4cceac">
                Compra un Curso
              </Typography>
              <Typography variant="h4" align="center" color="white">
                Selecciona un curso para ver costo:
              </Typography>

              <Select
                value={selectedCourse}
                onChange={handleCourseChange}
                fullWidth
                variant="outlined"
                sx={{ marginBottom: 2 }}
                style={{ color: '#4cceac' }}
              >
                {courseOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>

              <Typography variant="h1" align="center" color="#ea810f">
                {getSubtitle()}
              </Typography>
              <Box marginTop={2}>
                <Typography variant="h4" align="center" color="white">
                  Acceso por un año al curso y sus recursos
                </Typography>
                <Typography variant="h4" align="center" color="white">
                  Pago electrónico o físico
                </Typography>
              </Box>
              <Box marginTop={5}>
                <Button
                  size="large"
                  variant="contained"
                  style={{ backgroundColor: '#4cceac', color: "#141b2b", fontSize: '16px', fontWeight: 600 }}
                  fullWidth
                  onClick={handleButtonClick}
                >
                  COMPRAR
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ backgroundColor: colors[1] }}
            sx={{
              borderColor: '#f64854',
              borderWidth: '2px',
              borderStyle: 'solid',
            }}
          >
            <CardContent>
              <Typography variant="h3" align="center" color='#f64854'>
                Plan Academy Anual
              </Typography>
              <Typography variant="h1" align="center" color='#f64854'>
                Oferta
              </Typography>
              <Box marginBottom={4} marginTop={2}>
                <Typography variant="h1" align="center" color='#ea810f'>
                  {currency === 'COP' ? '¡ $ 600.000 Cop!' : '¡ $ 150 USD!'}
                </Typography>
              </Box>
              <Box marginTop={2} marginBottom={5}>
                <Typography variant="h4" align="center" color="#white">
                  Acceso por 1 año a todos los cursos de Proex Academy
                </Typography>
                <Typography variant="h4" align="center" color="#white">
                  Incluye cursos de:
                </Typography>
                <Typography variant="h4" align="center" color="#white">
                  - Desarrollo y despliegue de aplicaciones con inteligencia artificial
                </Typography>
                <Typography variant="h4" align="center" color="#white">
                  - Diseño Estructural de puentes en vigas de acero tipo I y tipo cajón
                </Typography>
                <Typography variant="h4" align="center" color="#white">
                  - Modelación y diseño de Edificios en muros de concreto reforzado
                </Typography>
                <Typography variant="h4" align="center" color="#white">
                  - Avaluos de cargas, Conceptos y Aplicaciones
                </Typography>
                <Typography variant="h4" align="center" color="#white">
                  - Fundamentos para diseño estructural de edificaciones
                </Typography>
                <Typography variant="h4" align="center" color="#white">
                  Pago electrónico o físico
                </Typography>
              </Box>
              <Box marginTop={6}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={() => window.location.href = currency === 'COP' ? 'https://payco.link/2400734' : 'https://payco.link/3132357'}
                  style={{ backgroundColor: '#f64854', color: "#141b2b", fontSize: '16px', fontWeight: 600 }}
                >
                  COMPRAR
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={13} sm={4}>
          <Card style={{ backgroundColor: colors[2] }}
            sx={{
              borderColor: '#4cceac',
              borderWidth: '2px',
              borderStyle: 'solid',
            }}
          >
            <CardContent>
              <Typography variant="h3" align="center" color='#4cceac'>
                Plan Academy Lifetime
              </Typography>
              <Box marginTop={5} marginBottom={6}>
                <Typography variant="h1" align="center" color='#ea810f'>
                  {currency === 'COP' ? '$ 2.000.000 Cop' : '$ 500 USD'}
                </Typography>
              </Box>
              <Box marginTop={2}>
                <Typography variant="h4" align="center" color="white">
                  Acceso de por vida a todos los cursos de Proex Academy
                </Typography>
                <Typography variant="h4" align="center" color="white">
                  Acceso de por vida a todos cursos futuros de Proex Academy
                </Typography>
                <Typography variant="h4" align="center" color="white">
                  Pago electrónico o físico con e-payco
                </Typography>
              </Box>
              <Box marginTop={5}>
                <Button
                  size='large'
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ backgroundColor: '#4cceac', color: "#141b2b", fontSize: '16px', fontWeight: 600 }}
                  onClick={() => window.location.href = currency === 'COP' ? 'https://payco.link/2249653' : 'https://payco.link/3132366'}
                >
                  COMPRAR
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PricesCourses1;
