import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

const MuroVoladizoNsr10 = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <div style={{ backgroundColor: 'white', width: '100%', marginTop: '75px', display: 'flex', flexDirection: 'column' }}>
      <AppBar position="static" color="default" style={{ backgroundColor: '#003680' }}>
        <Toolbar>
          <IconButton color="inherit">
            <MenuIcon />
          </IconButton>
          <div style={{ flexGrow: 1 }} />
          <IconButton color="inherit">
            <SearchIcon />
          </IconButton>
          <IconButton color="inherit">
            <NotificationsIcon />
          </IconButton>
          <IconButton color="inherit">
            <AccountCircleIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div style={{ display: 'flex', width: '100%' }}>
        <Box style={{ backgroundColor: '#e2e2e2', flex: 2, height: 'calc(85vh)', overflowY: 'auto', borderRadius: '0px', scrollbarColor: 'blue #e2e2e2', scrollbarWidth: 'thin', margin: '10px' }}>
          {/* Aquí iría el contenido del box rojo */}
          <Box>
            <Typography>GEOMETRIA</Typography>
            <hr style={{ border: '1px solid #003680' }} />
          </Box>
          <TableContainer>
            <Table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <TableBody>
                <TableRow>
                  <TableCell style={{ padding: '8px', color: '#003680' }}>Valor 1</TableCell>
                  <TableCell style={{ padding: '8px', color: '#003680' }}><TextField fullWidth inputProps={{ style: { color: '#003680', padding: 0, margin: 0, border: 'none', width: '100%', background: 'transparent' } }} /></TableCell>
                  <TableCell style={{ padding: '8px', color: '#003680' }}>Valor 3</TableCell>
                </TableRow>
                {/* Agregar más filas aquí */}
                <TableRow>
                  <TableCell style={{ padding: '8px', color: '#003680' }}>Otro valor</TableCell>
                  <TableCell style={{ padding: '8px', color: '#003680' }}><TextField fullWidth inputProps={{ style: { color: '#003680', padding: 0, margin: 0, border: 'none', width: '100%', background: 'transparent' } }} /></TableCell>
                  <TableCell style={{ padding: '8px', color: '#003680' }}>Más valores</TableCell>
                </TableRow>
                {/* Agregar más filas aquí */}
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            <Typography variant='h2'>MATARIALES</Typography>
            <hr style={{ border: '1px solid #003680' }} />
          </Box>
          <TableContainer>
            <Table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <TableBody>
                <TableRow>
                  <TableCell style={{ padding: '8px', color: '#003680' }}>Valor 1</TableCell>
                  <TableCell style={{ padding: '8px', color: '#003680' }}><TextField fullWidth inputProps={{ style: { color: '#003680', padding: 0, margin: 0, border: 'none', width: '100%', background: 'transparent' } }} /></TableCell>
                  <TableCell style={{ padding: '8px', color: '#003680' }}>Valor 3</TableCell>
                </TableRow>
                {/* Agregar más filas aquí */}
                <TableRow>
                  <TableCell style={{ padding: '8px', color: '#003680' }}>Otro valor</TableCell>
                  <TableCell style={{ padding: '8px', color: '#003680' }}><TextField fullWidth inputProps={{ style: { color: '#003680', padding: 0, margin: 0, border: 'none', width: '100%', background: 'transparent' } }} /></TableCell>
                  <TableCell style={{ padding: '8px', color: '#003680' }}>Más valores</TableCell>
                </TableRow>
                {/* Agregar más filas aquí */}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box style={{ backgroundColor: '#cccccc', flex: 7, height: 'calc(85vh)', overflowY: 'auto', borderRadius: '0px', scrollbarColor: 'blue #cccccc', scrollbarWidth: 'thin', margin: '10px' }}>
          {/* Aquí iría el contenido del box azul */}
        </Box>
      </div>
    </div>
  );
};

export default MuroVoladizoNsr10;
