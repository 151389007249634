import { useEffect} from 'react';
import { React, useRef } from 'react';
import { Canvas, extend, useThree, useFrame } from 'react-three-fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Sphere from './Building3D';

const CameraOrbitController=()=>{
  const{camera,gl}=useThree();
  useEffect(()=>{
    const controls=new OrbitControls(camera,gl.domElement)
    return()=>{
      controls.dispose();
    }
  },[camera,gl])
  return null;

}

const AnalysisWindow = () => {
  const coordinates = [
    [0, 0, 0],
    [10, 10, 0],
    [20, 20, 1],
  ];

  return (   
     
      <Canvas>
        <CameraOrbitController/>
        <ambientLight />        
        <mesh>
        <sphereGeometry />        
          <meshStandardMaterial color="hotpink" />
        </mesh>

        <mesh>        
        <boxGeometry args={[2,2,2]}></boxGeometry>
          <meshStandardMaterial color="#00a8f3" />
        </mesh>
      </Canvas>
  );
};

export default AnalysisWindow;
