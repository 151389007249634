import { Stack,Grid,Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { VimeoVideo } from "../../components/VimeoVideo";
import useMediaQuery from '@mui/material/useMediaQuery';
import VideoListReproduction from "../../components/VideosList";
import VideoReproductor from "../../components/VideoReproductor";
import './course1.css'
    

function TemplateCourses() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const boxStyles = {
      marginTop: '70px', // Puedes ajustar el valor según tus necesidades
    };

    

    const videosList = [
      { name: 'Video 1', minutes: 10 },
      { name: 'Video 2', minutes: 15 },
      { name: 'Video 3', minutes: 20 },
      { name: 'Video 3', minutes: 20 },
      { name: 'Video 3', minutes: 20 },
      { name: 'Video 3', minutes: 20 },
      { name: 'Video 3', minutes: 20 },
      { name: 'Video 3', minutes: 20 },
      { name: 'Video 3', minutes: 20 },
      { name: 'Video 3', minutes: 20 },
      { name: 'Video 3', minutes: 20 },
      { name: 'Video 3', minutes: 20 },
      { name: 'Video 3', minutes: 20 },
      { name: 'Video 3', minutes: 20 },
      { name: 'Video 3', minutes: 20 },
      { name: 'Video 3', minutes: 20 },

    ];


    return (
      <Box m="20px" sx={{ ...boxStyles }} >
        {/* HEADER */}
      
  
        {/* GRID & CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >
          <VideoReproductor></VideoReproductor>  
          <VideoListReproduction></VideoListReproduction>
          
               
          
  
     
         
         
        </Box>
      </Box>
    );
  };
  
  
  export default TemplateCourses
