import { useState, useEffect } from "react";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import { BrowserRouter as Router, Switch, Route, Routes, Navigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import PrivateRoutes from "./AppPrivateRoutes";
import PublicRoutes from "./AppPublicRoutes";
import { useNavigate, Link } from "react-router-dom";
import { CurrencyProvider } from "./context/CurrencyContext";

function App() {
  const [theme, colorMode] = useMode();
  const [loggedIn, setLoggedIn] = useState(false);
  const navigateOut = useNavigate();
  const [username, setUsername] = useState("");
  const [plans, setPlans] = useState("");

  useEffect(() => {
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');
   
    if (user && token) {
      setLoggedIn(true);
      const storedUsername = localStorage.getItem('username');
      setUsername(storedUsername);
      const storedPlans = JSON.parse(localStorage.getItem('pruebacursos'));
      setPlans(storedPlans);
    }
  }, []);

  const ControlLocalStorage = localStorage.getItem("datoscursos");
  useEffect(() => {}, [ControlLocalStorage]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (isMobile) {
      scrollToTop();
    }
  }, []);

  const handleLogin = async (email, password) => {
    const response = await fetch('https://8ori4qvsnc.execute-api.us-east-1.amazonaws.com/dev/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    });
    
    const data = await response.json();
    const AuthenticationValue = data.Authentication;
    const NotLoginMessage = data.additional_message;
    
    if (AuthenticationValue === "true") {
      localStorage.setItem('user', email);
      localStorage.setItem('token', 'your_token_value');
      setLoggedIn(true);
      navigateOut('/');
      
      setUsername(data.username);
      setPlans(data.cursos_finales);

      const datosCursosBacks = [data.cursos_finales];
      localStorage.setItem('useremail', data.email);
      localStorage.setItem('plan_user', data.plan_user);
      localStorage.setItem('username', data.username);
      localStorage.setItem('userjob', data.job);
      localStorage.setItem('datoscursos', datosCursosBacks);
      localStorage.setItem('pruebacursos', JSON.stringify(data.cursos_finales));
    } else {
      alert(NotLoginMessage);
      console.log('Credenciales inválidas');
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
    navigateOut('/');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('useremail');
    localStorage.removeItem('plan_user');
    localStorage.removeItem('username');
    localStorage.removeItem('userjob');
    localStorage.removeItem('datoscursos');
    localStorage.removeItem('pruebacursos');
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CurrencyProvider>
          <div className="app">
            <main className="content">
              {loggedIn ? (
                <PrivateRoutes onLogout={handleLogout} usernameoficial={username} plans2={plans} />
              ) : (
                <PublicRoutes onLogin={handleLogin} />
              )}
            </main>
          </div>
        </CurrencyProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
