import React from 'react';
import Button from '@mui/material/Button';
import InstagramIcon from '@mui/icons-material/Instagram';

const InstagramButton = () => {
  const handleInstagramClick = () => {
    window.open('https://www.instagram.com/proexacademy/', '_blank');
  };

  return (
    <Button
      variant="contained"
      style={{
        backgroundColor: '#C13584',
        color: '#ffffff',
        fontSize: '0.75rem',        
        textTransform: 'none',
      }}
      startIcon={<InstagramIcon />}
      onClick={handleInstagramClick}
      size="medium"
    >
      Ir a Instagram
    </Button>
  );
};

export default InstagramButton;
