import React from 'react';
import { Box, Typography, Button, Grid,ListItem ,ListItemIcon,Stack,Avatar} from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery'
import { tokens } from "../../theme";
import { useTheme } from '@emotion/react';
import SlideUpText from '../../components/TextAnimation1';
import CheckIcon from '@mui/icons-material/Check';
import CardCustom from '../../components/CardT1/CardT5';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeLandingCard from '../../components/CardT1/CardT6';
import HomeLandingCard2 from '../../components/CardT1/CardT7';
import SingleImage from '../../components/images/imagen1';
import HomeLandingCard3 from '../../components/CardT1/CardT8';
import WhatsAppButtonComponent from '../../components/whatsapp/WhatsappButton';
import FacebookButton from '../../components/Facebook/FabookDir';
import InstagramButton from '../../components/Instagram/Instagram';
import FooterLanding from './FooterLandin';
import SingleImage2 from '../../components/images/imagen2';
import DropdownList from '../../components/desplegable_modulos';
import DownloadButton from '../../components/DownLoadButton';
import ItemList from '../../components/Lista1';
import ListItems from '../../components/Lista2';
import CardCustomTeacher from '../../components/CardT1/cardT10';
import PricesCourses1 from './prices1';
import SingleImage3 from '../../components/images/imagen3';
import VimeoVideoPublicidad from '../../components/VimeoVideo2';

const Cursoedficiosmuros = () => {
    const theme = useTheme();
    const isMobile3 = useMediaQuery('(max-width: 960px)');
    const isMobile2 = useMediaQuery('(max-width: 600px)');    
    const colors = tokens(theme.palette.mode);
    const pdfUrl = 'https://academy-public-resources.s3.amazonaws.com/publics/temarios/C03.png';

    const itemListCompentencias = ['Modelar un edificio de 15 pisos de altura en Etabs',
                                   'Aplicar cargas sísmicas y verticales al modelo',
                                   'Llevar acabo un análisis de Fuerza horizontal equivalente ' ,
                                   'Ajustes en el modelo por cortante basal' ,
                                   'Realizar verificación de reacciones, derivas y ajuste del «R»',
                               
                                ];
   const itemListCompentencias2 = ['Definir correctamente parámetros de diseño en  ETABS',                                  
                                  'Realizar diseño estructural de muros con  ETABS',                                  
                                  'Realizar diseño estructural de losas de la edificación con Etabs',  
                                  'Realizar diseño estructural vigas de la edificación',  
                                  'Realizar chequeos manuales del diseño estructural de un muro',                                   
                                  ];

    const itemsMetodologiaCurso = [
    
    'Clases Pregrabadas, acceso 24/7',
    '8 Horas de Clases',
    'Presentaciones de las clases',
    'Documentos adicionales de apoyo',    
    'Certificado de Asistencia',
    'Solución de dudas por sistema comentarios admincursos@proexingenieria.com'
    ];

    const itemsDirigidoCurso = [
    
      'Estudiantes de Ingenieria civil',
      'Ingenieros civiles diseñadores estructurales',
      'Especialistas en estructuras',      
      ];

      

    const itemsHerramientas = [    
        'Uso Etabs',
        'Familiaridad con la Ingenieria Civil',
        'Maxima disposicion a aprender',      
        ];
        const modules = [
          {
            title: 'Módulo 1 - Introducción',
            duration: '42 min',
            clases: 'Son 4 Clases',
            classesList: [
              {
                name: 'Introducción al curso',
                duration: '06:13 min',
              },
              {
                name: 'Entendiendo la arquitectura y el estudio de suelos',
                duration: '08:22 min',
              },
              {
                name: 'Predimensionamiento de estructura',
                duration: '21:38 min',
              },
              {
                name: 'Avalúo de cargas',
                duration: '06:38 min',
              }
            ],
          },
          {
            title: 'Módulo 2 - Entendiento el software',
            duration: '44 min',
            clases: 'Son 10 clases',
            classesList: [
              {
                name: 'Tipos de elementos',
                duration: '03:32 min',
              },
              {
                name: 'Grados de libertad',
                duration: '09:05 min',
              },
              {
                name: 'Ejes locales y ejes globales',
                duration: '09:37 min',
              },
              {
                name: 'Tipos de apoyos',
                duration: '03:16 min',
              },
              {
                name: 'Diafragma rígido',
                duration: '03:10 min',
              },
              {
                name: 'Wall sections',
                duration: '02:45 min',
              },
              {
                name: 'Slab sections',
                duration: '03:01 min',
              },
              {
                name: 'Releases en frames',
                duration: '06:34 min',
              },
              {
                name: 'Pier Labels',
                duration: '01:56 min',
              },
              {
                name: 'Line, Area springs',
                duration: '10:43 min',
              }
            ],
          },
          {
            title: 'Módulo 3 - Modelación en software ETABS',
            duration: '2 hora 15 min',
            clases: 'Son 5 Clases',
            classesList: [
              {
                name: 'Definición de secciones, materiales y geometría',
                duration: '31:18 min',
              },
              {
                name: 'Patrones de carga y aplicación de cargas',
                duration: '20:48 min',
              },
              {
                name: 'Combinaciones de diseño, desplazamientos y servicio con NSR-10',
                duration: '17:49 min ',
              },
              {
                name: 'Fuerza horizontal equivalente y ajuste de modelo',
                duration: '24:10 min',
              },
        {
                name: 'Continuación de ajuste de modelo',
                duration: '41:45 min',
              }
            ],
    
          },
    
          {
            title: 'Módulo 4 - Análisis de resultados',
            duration: '1 hora 06 min',
            clases: 'Son 3 Clases',
            classesList: [
              {
                name: 'Verificación de desplazamientos y R',
                duration: '55:55 min',
              },
              {
                name: 'Verificación de reacciones',
                duration: '04:15 min',
              },
              {
                name: 'Solicitaiones de diseño',
                duration: '06:26 min ',
              }
            ],
    
          },
          {
            title: 'Módulo 5 - Diseño estructural de un muro tipo',
            duration: '2 horas 05 min',
            clases: 'Son 4 Clases',
            classesList: [
              {
                name: 'Definiciones para diseñar con el software',
                duration: '11:10 min',
              },
              {
                name: 'Diseño de muros sin elementos de borde',
                duration: '33:55 min',
              },
              {
                name: 'Diseño de muros con elementos de borde',
                duration: '21:29 min ',
              },
              {
                name: 'Diseño manual típico de un muro',
                duration: '59:15 min ',
              }
            ],
    
          },
          {
            title: 'Módulo 6 - Diseño de la placa de entrepiso',
            duration: '42 min',
            clases: 'Son 4 Clases',
            classesList: [
              {
                name: 'Modificación de las caracteristicas de la placa',
                duration: '03:06 min',
              },
              {
                name: 'Asignación de cuadricula y lectura de solicitaciones',
                duration: '05:54 min',
              },
              {
                name: 'Diseño de placa a flexión y corte',
                duration: '20:08 min ',
              },
              {
                name: 'Diseño de dinteles',
                duration: '13:46 min',
              }
            ],
    
          },
          {
            title: 'Módulo 7 - Modelación de cimentación',
            duration: '30 min',
            clases: 'Son 2 Clases',
            classesList: [
              {
                name: 'Guía para modelación de cimentación y cierre del curso',
                duration: '30:28 min',
              }
            ],
    
          },
         
        ];
    
    const cardDataList = [
      {
        title: 'Clases Ejercicios',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Desarrollamos ejemplos paso a paso para que puedas entender y aprender facilmente, Video Clases 24/7',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 1
        to:'c01',
      },
      {
        title: 'Clases conceptuales ',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Clases produndas de fundamentos y conceptos, Video Clases 24/7',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 2
        to:'c02',
      },
      {
        title: 'Clases Manejo Software',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Clases 100% orientadas al trabajo del dia a dia de los ingenier@s, Video Clases 24/7',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 3
        to:'c03',
      },
      {
        title: 'Material Escrito Complementario',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Te brindamos materiales de apoyo complementario en PDF para complementar las clases',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      },
      {
        title: 'Certificado de Asistencia',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Te otorgamos certificado de asistencia al tomar todas las clases',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      },
      {
        title: ' Preguntas y respuestas',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Respondemos tus preguntas acerca de las clases en nuestro sistema de comentarios',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      }


    ];
    

    const cardMotivation = [
      {
        title: 'Puedas avanzar en tu carrera profesional como diseñador estructural',
        
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 1
        to:'c01',
      },
      {
        title: 'Potencies tu criterios de ingenieria, tomes  decisiones mas acertadas y evites cometer errores ',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',       
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 2
        to:'c02',
      },
      {
        title: 'Adquieras nuevas habilidades y estes list@ para enfrentarte a nuevos retos',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 3
        to:'c03',
      }
    ];





    const cardDataList3 = [
      {
        title: 'Cursos Estructurales',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Cursos de fundamentos, análisis y diseño estructural aplicado al trabajo practico de los Ingenier@s civiles',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 1
        to:'cursos',
      },
   


    ];





    const imageUrl1 = 'https://academy-public-resources.s3.amazonaws.com/publics/student+main.svg';
    const imageUrl2 = 'https://academy-public-resources.s3.amazonaws.com/publics/bridge1.svg';
    const imageUrl3 = 'https://academy-public-resources.s3.amazonaws.com/publics/building+_1.svg';

  
  return (
    <div>
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #141b2d 50%, #232c41)',
        paddingTop: '105px',
        paddingBottom: '20px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } , marginTop: '20px', marginRight: (isMobile2 ? '20px' : isMobile3 ? '30px' : '200px') , marginLeft: (isMobile2 ? '20px' : isMobile3 ? '30px' : '200px') }}>
         
          <Box sx={{
        borderRadius: '10px',      
        backgroundColor:'#141b2b',
        border: '3px solid #4c92dd'
        // Resto de estilos
      }}>
          <SlideUpText text="Modelación y diseño estructural de edificios en muros de concreto con ETABS" variant="h2" color="#4c92dd" marginBottom="20px" />
          <SlideUpText text="Construye conocimiento, potencia tu éxito en la construcción" variant="h4" color="white" marginBottom="20px" />
          </Box>
          <SingleImage2 Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/iconos-cursos/iconoedificios1.svg'></SingleImage2>
        </Grid>
        
       
      </Grid>
    </Box>
    <Box>

    </Box>

    <Box
       sx={{
        backgroundImage: 'linear-gradient(to bottom, #232c41 50%, #232c41)',
        paddingTop: '0px',
        paddingBottom: '0px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
      >
      <VimeoVideoPublicidad videoNumber="846380617" ></VimeoVideoPublicidad>
      </Box>

    {/* Descripcion del curso*/}
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #232c41)',
        paddingTop: '105px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } ,       
      }}        
        >
        <Box marginLeft={'50px'} marginRight={'50px'}>
        <SlideUpText text="Este es un curso donde se enseñan los conocimientos necesarios para entender, modelar y realizar diseños estructurales para edificios de muros en concreto reforzado con en el software Etabs"
           variant="h3" color='white' marginBottom="20px" />
        
        <SlideUpText text="El curso está dividido en seis capítulos, con duración total de 8 horas cuyas normativas de referencia son la NSR-10 y ACI-318"
           variant="h3" color='white' marginBottom="20px" />
        
        <SlideUpText text="Aprenderas a diseñar un edificio de 15 pisos de altura y  recibirás tu certificado de asistencia al entregar las tareas"
           variant="h3" color='white' marginBottom="20px" />

          </Box> 
        
        </Grid>

        <Grid item xs={12} sm={6} sx={{ order: { xs: 2, sm: 2 } }}>
        <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/building_IM1MIN.png' boxHeight='35vh' ></SingleImage>
        </Grid>      
      </Grid>
    </Box>
      {/*Mensaje 2*/}
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 2%, #141b2d)',
        paddingTop: '40px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 2 } }}>
        <SlideUpText text="En Proex Academy creamos este curso para que" variant="h2" color='white' marginBottom="20px" />
         
          <Box display="flex" alignItems="center">
          
          <HomeLandingCard2 cardDataList={cardMotivation} />
          </Box>



                </Grid>
           
      </Grid>
    </Box>
      {/*Imagen transicion*/}

      <Box      
      sx={{
        paddingTop: '40px',
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #3e4396)',
      }}
    >
      <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/building_IM2MIN.png'></SingleImage>
    
    </Box>

      {/*Competencias profesionales a adquirir*/}
      <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #121b2b 80%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Competencias profesionales a adquirir " variant="h2" color="white" marginBottom="20px" />        
          <SlideUpText text="Aprenderas a " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemListCompentencias} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #121b2b 30%, #121b2b)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Tambien Aprenderas" variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemListCompentencias2} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    {/*Imagen transicion*/}
    <Box      
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #121b2b)',
      }}
    >      
      <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/building_IM3MIM.png'></SingleImage>
    
    </Box>



    {/*A quien va dirido*/}

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #232c41)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },
        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="A quien va dirigido el curso " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemsDirigidoCurso} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Herramientas Necesarias " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemsHerramientas} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    {/*Temario*/}
    <Box
  sx={{
    backgroundImage: 'linear-gradient(to top, #232c41 30%, #3e4396)',
    paddingTop: '0px',
    paddingBottom: '40px',
    textAlign: { xs: 'center', sm: 'center' },
    width: '100%',
  }}
>
  <Grid container spacing={2} justifyContent="center">
    <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 }, marginLeft: '20px', marginRight: '20px' }}>
      <Typography variant="h2">Temario Curso</Typography>
      <Typography variant="h2"></Typography>
      <DropdownList modules={modules} />
    </Grid>

  </Grid>
</Box>

    {/*Metodologia Curso*/}

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #232c41)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Metodología " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemsMetodologiaCurso} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

      {/*Teachers*/}
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #3e4396)',
        paddingTop: '0px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
          
      }}
    >
      <Box display="flex" justifyContent="center" paddingTop={5} paddingBottom={5} >
      <SlideUpText text="Los Profesores del Curso " variant="h2" color="white" marginBottom="20px" />        
      </Box> 

      <Grid container spacing={2} >
                
        
            <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }} style={{ marginTop: '10px', paddingTop: '10px' }}>               

              <Box
                sx={{
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  textAlign: { xs: 'center', sm: 'center', md: 'center' },
                }}
              >
                           
                  <Typography variant="h3">Ing. David Naranjo</Typography>
              
                <Box display="flex" alignItems="center" gap="10px" sx={{textAlign: { xs: 'center', sm: 'center', md: 'center' }}}>                  
                <Avatar src="https://academy-public-resources.s3.amazonaws.com/publics/logoescuela+1_rounded.png" sx={{ width: 100, height: 100 }} />
                  <Typography variant="h4">Ingeniero Civil Escuela Colombiana de Ingenieria Julio Garavito</Typography>
                  
                </Box>
                <Box display="flex" alignItems="center" gap="10px"  sx={{textAlign: { xs: 'center', sm: 'center', md: 'center' }}} >
                <Avatar src="https://academy-public-resources.s3.amazonaws.com/publics/logoescuela+1_rounded.png" sx={{ width: 100, height: 100 }} />
                <Typography variant="h4">Especilista en estructuras de la Escuela Colombiana de Ingenieria</Typography>
                </Box>
                <Typography variant="h4">Diseñador estructural con 8 años de experiencia en el diseño de Edificios de muros de concreto, edificios de porticos y combinados</Typography>
                {/**<Typography variant="h4">Ingeniero apasionado por la excelencia y el conocimiento</Typography>*/}
              </Box>

         
                </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } }}>
        <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/dnimagemin.png' boxHeight='40vh' ></SingleImage>
        </Grid>      
      </Grid>
    </Box>



    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #232d41)',
        paddingTop: '5px',
        paddingBottom: '5px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
        <SlideUpText text=" ¿Que te brindamos en Proex Academy? " variant="h2" color='#EA810F' marginBottom="20px" />
        
    </Box>

     {/*Aqui van las card*/} 

     <Box 
            sx={{ height: '50px', overflow: 'auto',margingTop: '50px',
            
            }}/>            
            
            <Box marginTop={3} marginRight={1} marginBottom={4} marginLeft={1} alignSelf='center'
            >
            {/*
            <CardComponent list={list_filters} />  
            */}
            
            <HomeLandingCard cardDataList={cardDataList} />
      </Box> 
      
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
      
      <WhatsAppButtonComponent />
    </div>
    

    <Box display="flex" flexDirection="column" justifyContent="center" paddingTop={5} paddingBottom={5} 
       sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 2%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
       <SlideUpText text="Precios y Planes" variant="h2" color="white" marginBottom="20px" />        
      
      </Box> 



    <Box m={2} marginTop={10}>
        <PricesCourses1 colors={colors} course={2} />
    </Box>


    <Box display="flex" flexDirection="column" justifyContent="center" paddingTop={5} paddingBottom={5} 
       sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 2%, #141b2d)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <SlideUpText text="¡Únete a nuestra comunidad de estudiantes motivados y " variant="h2" color="white" marginBottom="20px" />        
      <SlideUpText text="construye un futuro brillante en la construcción con Proex Academy! " variant="h2" color="white" marginBottom="20px" />        
      </Box> 

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 2%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >

     <Link to="/register">
               <Button style={{ fontSize: isMobile2 ? '1.2rem' : '1.5rem' ,fontWeight: isMobile2 ? 'normal' : '600'}} sx={{ fontSize: '1rem' }} variant='contained' color='secondary'>
                Registrarme y comprar  </Button>             
    </Link> 
                       

       
     
    </Box>




        
        
     


    <FooterLanding></FooterLanding>




    </div>
   
    
    
  );
};

export default Cursoedficiosmuros;
