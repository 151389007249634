import { Typography, Stack, Box, Button, IconButton, useTheme, Hidden, Grid } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import TableRowsIcon from '@mui/icons-material/TableRows';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';

const TopbarSells = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const isMobile = useMediaQuery('(max-width: 1200px)');
  const isMobile2 = useMediaQuery('(max-width: 800px)');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const appBarStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    width: '100%'
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };

  const linkStyles = {
    textDecoration: 'none',
    color: 'inherit',
  };

  return (
    <Box style={appBarStyles}>
      {/* Barra de Oferta */}
      <Box sx={{ backgroundColor: '#0565F5', color: '#000', padding: '10px', textAlign: 'center', fontWeight: 'bold' }}>
        <Link to="/cursos-desarrollo-inteligencia-04" style={linkStyles}>
          <Typography variant="h5" sx={{"color":"white"}}>¡Oferta! Curso de programación de apps hasta el 23 de Julio</Typography>
        </Link>
      </Box>

      <Box display="flex" justifyContent={isMobile ? 'center' : 'space-between'} p={2} sx={{ backgroundColor: '#141b2d', padding: '10px' }}>
        {!isMobile2 && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              alt="profile-user"
              width="120px"
              height="40px"
              src={`../../assets/logoproexblanco.png`}
            />
          </Box>
        )}

        {/* ICONOS */}
        <Box display="flex">
          <Stack direction='row' spacing={2}>
            {/* BOTON MENU MOBILE */}
            <Box display="flex" sx={{ display: isMobile ? 'block' : 'none' }}>
              <IconButton onClick={handleMenuClick}>
                <TableRowsIcon />
              </IconButton>

              <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                style={{ marginTop: '80px', marginLeft: '5px' }}
                anchorEl={null}
                open={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                MenuListProps={{
                  sx: {
                    '& .MuiMenuItem-root': {
                      fontSize: '1rem',
                      padding: '20px',
                    },
                  },
                }}
              >
                <MenuItem onClick={handleClose}>
                  <NavLink to="/" activeClassName="active" style={linkStyles}>
                    <Typography variant="body1">Home</Typography>
                  </NavLink>
                </MenuItem>

            

                <MenuItem onClick={handleClose}>
                  <NavLink to="/cursos" activeClassName="active" style={linkStyles}>
                    <Typography variant="body1">Cursos</Typography>
                  </NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <NavLink to="/precios" activeClassName="active" style={linkStyles}>
                    <Typography variant="body1">Precios</Typography>
                  </NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <NavLink to="/nosotros" activeClassName="active" style={linkStyles}>
                    <Typography variant="body1">Nosotros</Typography>
                  </NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <NavLink to="/empresas" activeClassName="active" style={linkStyles}>
                    <Typography variant="body1">Empresas</Typography>
                  </NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <NavLink to="/register" activeClassName="active" style={linkStyles}>
                    <Typography variant="body1">Registro</Typography>
                  </NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <NavLink to="/login" activeClassName="active" style={linkStyles}>
                    <Typography variant="body1">Login</Typography>
                  </NavLink>
                </MenuItem>
              </Menu>
            </Box>

            <Box sx={{
              display: 'flex',
              gap: theme.spacing(3),
              [theme.breakpoints.down('lg')]: {
                gap: theme.spacing(1),
              },
            }}>
              {isMobile ? null : (
                <Box display="flex" justifyContent="space-between" marginBottom={2}>
                  <Link to="/">
                    <Button sx={{ fontSize: '1rem', border: 'none', fontWeight: isMobile2 ? 'normal' : '600' }} variant='outlined' color='secondary'>Home</Button>
                  </Link>
                  
                  <Link to="/nosotros">
                    <Button sx={{ fontSize: '1rem', border: 'none', fontWeight: isMobile2 ? 'normal' : '600' }} variant='outlined' color='secondary'>Nosotros</Button>
                  </Link>
                  <Link to="/empresas">
                    <Button sx={{ fontSize: '1rem', border: 'none', fontWeight: isMobile2 ? 'normal' : '600' }} variant='outlined' color='secondary'>Empresas</Button>
                  </Link>
                  <Link to="/precios">
                    <Button sx={{ fontSize: '1rem', border: 'none', fontWeight: isMobile2 ? 'normal' : '600' }} variant='outlined' color='secondary'>Precios</Button>
                  </Link>
                </Box>
              )}
              <Link to="/cursos">
                <Button style={{ fontSize: isMobile2 ? '0.8rem' : '1rem', fontWeight: isMobile ? 'normal' : '600' }} sx={{ border: 'none' }} variant='outlined' color='secondary'>Cursos</Button>
              </Link>
              <Link to="/register">
                <Button style={{ fontSize: isMobile2 ? '0.8rem' : '1rem', fontWeight: isMobile ? 'normal' : '600' }} sx={{ fontSize: '1rem' }} variant='contained' color='secondary'>Registrarme</Button>
              </Link>
              <Link to="/login">
                <Button style={{ fontSize: isMobile2 ? '0.8rem' : '1rem', fontWeight: isMobile ? 'normal' : '600' }} sx={{ fontSize: '1rem' }} variant='outlined' color='secondary' fullWidth>Acceder</Button>
              </Link>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default TopbarSells;

