import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';

const DropdownList = ({ modules }) => {
  const theme = useTheme();
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleAccordionChange = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  const listItemStyle = {
    paddingLeft: theme.spacing(2),
  };

  return (
    <div>
      {modules.map((module, index) => (
        <Accordion
          key={index}
          sx={{ marginBottom: theme.spacing(2), backgroundColor: '#3e4396' }}
          expanded={expandedIndex === index}
          onChange={() => handleAccordionChange(index)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  {module.title}
                </Typography>
              </Grid>
              <Grid item>
                <ListItemIcon>
                  <AccessTimeIcon />
                </ListItemIcon>
                <Typography variant="h5">{module.duration}</Typography>
              </Grid>
              <Grid item>
                <ListItemIcon>
                  <LocalLibraryIcon />
                </ListItemIcon>
                <Typography variant="h5">{module.clases}</Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {module.classesList.map((classItem, idx) => (
                <ListItem sx={listItemStyle} key={idx}>
                  <ListItemIcon>
                    <PhotoCameraFrontIcon />
                  </ListItemIcon>
                  <Box marginLeft="10px">
                    <ListItemText primary={classItem.name} />
                    <Typography variant="subtitle2">{classItem.duration}</Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default DropdownList;
