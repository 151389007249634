// MyCourses.js
import React, { useState, useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import CoursesCard from "../../components/CardT1/CardT2";
import data from './AcademyCourses.json';

function MyCourses({ plans3 }) {
  const [datos, setDatos] = useState([]);

  const cursoslocal = localStorage.getItem("pruebacursos");
  const cursoslocal2 = JSON.parse(cursoslocal);

  return (
    <div>
      <Box sx={{ height: '50px', overflow: 'auto', marginTop: '50px' }} />
      <Box>
        {Array.isArray(cursoslocal2) && cursoslocal2.length === 0 && (
          <Typography 
            variant="h6" 
            align="center" 
            sx={{ marginTop: '20px', fontSize: '1.5rem' }}
          >
            ¡Hola, en el MarketPlace puedes comprar uno de nuestros cursos!
          </Typography>
        )}
      </Box>
      <Box marginTop={3} marginRight={1} marginBottom={4} marginLeft={1} alignSelf='center'>
        {Array.isArray(cursoslocal2) ? (
          <CoursesCard cardDataList={cursoslocal2} />
        ) : (
          <Typography>CONTINUAR</Typography>
        )}
      </Box>
    </div>
  );
}

export default MyCourses;
