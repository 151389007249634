import React, { useRef } from 'react';
import { Box, Typography, Button, Grid,ListItem ,ListItemIcon,Stack,Avatar} from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery'
import { tokens } from "../../theme";
import { useTheme } from '@emotion/react';
import SlideUpText from '../../components/TextAnimation1';
import CheckIcon from '@mui/icons-material/Check';
import CardCustom from '../../components/CardT1/CardT5';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeLandingCard from '../../components/CardT1/CardT6';
import HomeLandingCard2 from '../../components/CardT1/CardT7';
import SingleImage from '../../components/images/imagen1';
import HomeLandingCard3 from '../../components/CardT1/CardT8';
import WhatsAppButtonComponent from '../../components/whatsapp/WhatsappButton';
import FacebookButton from '../../components/Facebook/FabookDir';
import InstagramButton from '../../components/Instagram/Instagram';
import FooterLanding from './FooterLandin';
import SingleImage2 from '../../components/images/imagen2';
import DropdownList from '../../components/desplegable_modulos';
import DownloadButton from '../../components/DownLoadButton';
import ItemList from '../../components/Lista1';
import ListItems from '../../components/Lista2';
import CardCustomTeacher from '../../components/CardT1/cardT10';
import PricesCourses1 from './prices1';
import VimeoVideoPublicidad from '../../components/VimeoVideo2';
import { Typed } from 'react-typed';
import SingleImage3 from '../../components/images/imagen3';
import RegisterComponent from '../register/componentRegister';
import { motion } from 'framer-motion';
import CursoBox from './priceCourseBox';
import { useCurrency } from '../../context/CurrencyContext';


const Cursodesarrolloapps05 = () => {

  const currency = useCurrency();

  const getPrice = () => {
    return currency === 'COP' ? "320.000 COP" : "80 USD";
  };
  //https://payco.link/3114570

  const getPaymentLink = () => {
    return currency === 'COP' ? "https://payco.link/3114719" : "https://payco.link/3114570";
  };

  const getImage = () => {
    return currency === 'COP' ? "https://academy-publics.s3.amazonaws.com/OFFER.png" : "https://academy-publics.s3.amazonaws.com/OFFER_INTERNATIONAL.png";
  };

  

  //console.log("Console.log From Cursodesarrolloapps05");
  //console.log(currency);
  //console.log(getPrice());
  

    const theme = useTheme();
    const isMobile3 = useMediaQuery('(max-width: 960px)');
    const isMobile2 = useMediaQuery('(max-width: 600px)');    
    const colors = tokens(theme.palette.mode);
    const pdfUrl = 'https://academy-public-resources.s3.amazonaws.com/publics/temarios/C03.png';
    const handleDownload = () => {
      const link = document.createElement('a');
      link.href = 'https://academy-publics.s3.amazonaws.com/Temario_CursoDesarrolloAplicacionesWeb.pdf';
      link.download = 'Temario_CursoDesarrolloAplicacionesWeb.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    const pricesRef = useRef(null);
    const scrollToPrices = () => {
      pricesRef.current.scrollIntoView({ behavior: 'smooth' });
  };

    const itemListCompentencias = ['Desarrollo de aplicaciones web con ChatGpt',                                    
                                   'Crear, leer, actualizar y eliminar información en una aplicación web',                                   
                                   'Manipulación de archivos excel con javascript y React',
                                   'Creación Interfaces 2D Y 3D',
                                   'Creación de un CRUD con interfaz, backend y base de datos',
                                   'Creación de planos Autocad con Python' ,                                   
                                   'Manejo de Imagenes y videos en una aplicación web'
                                ];
   const itemListCompentencias2 = ['Programación básica en Python',
                                   'Manejo básico de información con Pandas y Numpy',
                                   'Programación básica en HTML & JavaScript',
                                   'Programación básica en React-Js' ,
                                   'Despliegue de Interfaces con Amazon web Services',
                                   'Despliegue de Backend Serverless en Amazon web Services',
                                   'Administración bases de datos en Dynamo DB'
                                   
                                ];

    const itemsMetodologiaCurso = [
    'Inicio del curso 24 de Julio de 2024',
    'Clases Pregrabadas, se liberan capitulos semanalmente' ,
    'Talleres semanales en vivo-online para preguntas los dias 26 julio y 5/9/16 de Agosto 7:00pm (6 horas totales)' ,
    '14 Horas de Clases pregrabadas',    
    'Documentos adicionales de apoyo',    
    'Certificado de Asistencia',
    'Solución de dudas por sistema de comentarios'
    ];

    const itemsDirigidoCurso = [    
      'Estudiantes de ingeniería  o carreras afines a cálculos matematicos',
      'Profesionales de ingeniería o carreras afines a cálculos matematicos',
      'Especialistas en estructuras',      
      ];

      

    const itemsHerramientas = [    
        'Computador Conectado a Internet',
        'Python',
        'HTML,CSS,JavaScript, React-js',      
        ];
    const modules = [
      {
        title: 'Módulo 1 - Bases de Programación',
        duration: '5 horas 30 min',
        clases: 'Son 12 Clases',
        classesList: [
          {
            name: 'Presentacion & Objetivos del curso',
            duration: '5:00 min',
          },
          {
            name: 'Programar con python usando Chat-Gpt',
            duration: '30:00 min',
          },
          {
            name: 'Programar con JavaScript usando Chat-Gpt',
            duration: '30:00 min',
          },
          {
            name: 'Instalaciones necesarias',
            duration: '10:00 min ',
          },
          {
            name: 'Programación básica Python 1',
            duration: '45:00 min',
          },
          {
            name: 'Programación básica Python 2',
            duration: '45:00 min',
          },
          {
            name: 'Programación básica  web  1 (HTML,CSS,JAVASCRIPT,REACT,AWS,API)',
            duration: '45:00 min',
          },
          {
            name: 'Programación básica  web  2 (HTML,CSS,JAVASCRIPT,REACT,AWS,API)',
            duration: '45:00 min',
          },
          {
            name: 'Creacion Bases de datos- AWS Dynamo db',
            duration: '15:00 min',
          },
          {
            name: 'Creacion Login de aplicación-AWS Lambda',
            duration: '15:00 min',
          },
          {
            name: 'Creacion Login-Interfaz',
            duration: '15:00 min',
          },
          {
            name: 'Despliegue de la aplicacion a la web con dominio',
            duration: '30:00 min',
          },
        ],

      },
      {
        title: 'Módulo 2 - Interfaces 2D y 3D',
        duration: '5 horas 10min',
        clases: 'Son 6 Clases',
        classesList: [
          {
            name: 'Cargar Información de Excel a la app',
            duration: '20:00 min',
          },
          {
            name: 'Crear gráficos  en una aplicación ',
            duration: '60:00 min',
          },
          {
            name: 'Crear Interfaces 2D interactivas',
            duration: '60:00 min',
          },
          {
            name: 'Crear Interfaces 3D interactivas',
            duration: '60:00 min',
          },
          {
            name: 'Creación archivos AutoCAD (dxf) con python',
            duration: '60:00 min',
          },
          {
            name: 'Despliegue API y descarga de archivo dxf',
            duration: '50:00 min',
          }
        
        ],

      },
      {
        title: 'Módulo 3 - APP diseño de vigas en sección compuesta',
        duration: '3 horas 20 min',
        clases: 'Son 7 clases',
        classesList: [
          {
            name: 'Flujo del diseño estructural viga en seccion compuesta de entrepiso',
            duration: '10:00 min',
          },
          {
            name: 'Programacion python con Chat-gpt.  Analisis y calculo de propiedades para viga de acero en seccion compuesta',
            duration: '40:00 min',
          },
          {
            name: 'Python con chat gpt  -Diseño a flexion,cortante, vibraciones',
            duration: '20:00 min',
          },
          {
            name: 'Despliegue de API en aws',
            duration: '10:00 min',
          },
          {
            name: 'Interfaz - Datos de entrada',
            duration: '40:00 min',
          },
          {
            name: 'Interfaz - Resultados calculos',
            duration: '40:00 min',
          },
          {
            name: 'Interfaz-  Impresión pdf',
            duration: '40:00 min',
          }
        ],
      },
      {
        title: 'Módulo 4 - APP diseño de zapatas',
        duration: '1 horas 25 min',
        clases: 'Son 5 Clases',
        classesList: [
          {
            name: 'Programacion en Python con chat gpt-Diseño de Zapatas (subir archivos excel)',
            duration: '40:00 min',
          },
          {
            name: 'Despliegue de API en aws',
            duration: '10:00 min',
          },
          {
            name: 'Interfaz Subir Solicitaciones. Programacion con chat gpt',
            duration: '15:00 min ',
          },
          {
            name: 'Interfaz Graficos 2D y 3D para zapatas. Programacion con chat gpt',
            duration: '10:00 min',
          },
          {
            name: 'Impresion PDF',
            duration: '10:00 min',
          }
        ],

      },    
    ];
    
    const cardDataList = [
      {
        title: 'Plantillas',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Te entregamos una plantilla lista para crear tu Software con control de usuarios, solo para que te enfoques en crear tu app',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 1
        to:'c01',
      },
      {
        title: 'Talleres en vivo',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Talleres en vivo semanales para preguntas y respuestas durante la duracion del curso',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 2
        to:'c02',
      },
      {
        title: 'Clases programación Software',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Clases Programacion 100% con chatgpt, ajuste codigos de chatgpt',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 3
        to:'c03',
      },
      {
        title: 'Acceso al curso',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Acceso de por vida al curso',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      },
      {
        title: 'Certificado de Asistencia',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Te otorgamos certificado de asistencia al tomar todas las clases',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      },
      {
        title: ' Preguntas y respuestas',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Respondemos tus preguntas acerca de las clases en nuestro sistema de comentarios',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      }


    ];
    

    const cardMotivation = [
      {
        title: 'Podrás crear tu primera aplicación funcional en solo unas horas, sin necesidad de experiencia previa',
        
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 1
        to:'c01',
      },
      {
        title: 'Aprenderás las bases de programación web, Python, AWS y a programar usando inteligencia artificial (Chat-Gpt) ',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',       
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 2
        to:'c02',
      },
      {
        title: 'Te entregaremos todos los códigos de programación usados en el curso',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 3
        to:'c03',
      }
    ];





    const cardDataList3 = [
      {
        title: 'Cursos Estructurales',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Cursos de fundamentos, análisis y diseño estructural aplicado al trabajo practico de los Ingenier@s civiles',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 1
        to:'cursos',
      },
   


    ];





    const imageUrl1 = 'https://academy-public-resources.s3.amazonaws.com/publics/student+main.svg';
    const imageUrl2 = 'https://academy-public-resources.s3.amazonaws.com/publics/bridge1.svg';
    const imageUrl3 = 'https://academy-public-resources.s3.amazonaws.com/publics/building+_1.svg';

  
  return (
    <div>

  
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #141b2d 50%, #232c41)',
        paddingTop: '105px',
        paddingBottom: '20px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
    
    <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '20px' }}>
    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ padding: isMobile2 ? '20px' : isMobile3 ? '30px' : '50px' }}>
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        textAlign: isMobile3 ? 'center' : 'left',
        marginLeft: isMobile2 ? '10px' : isMobile3 ? '20px' : '100px',
        marginRight: isMobile2 ? '10px' : isMobile3 ? '20px' : '40px',
      }}
    >
      <Typography
        variant="h1"
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        sx={{
          fontSize: isMobile2 ? '2.5rem' : isMobile3 ? '3.5rem' : '6rem',
          color: 'white',
          marginBottom: '20px',
        }}
      >
        ¡Crea <span style={{ borderBottom: '5px solid #08E1BA' }}>aplicaciones</span> web desde cero!
      </Typography>
      <Typography
        variant="h2"
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        sx={{
          fontSize: isMobile2 ? '1.2rem' : isMobile3 ? '2rem' : '2.5rem',
          color: 'white',
          marginBottom: '20px',
        }}
      >
        Curso de desarrollo y despliegue de aplicaciones con Inteligencia artificial
      </Typography>
      <Box
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.7, duration: 0.5 }}
        sx={{
          display: 'flex',
          flexDirection: isMobile3 ? 'column' : 'row',
          alignItems: isMobile3 ? 'center' : 'flex-start',
          gap: '20px',
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#08E1BA',
            color: '#141b2b',
            fontSize: '1.2rem',
            padding: '10px 20px',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#06c0a3',
            },
            '&:active': {
              backgroundColor: '#04a184',
            },
          }}
          onClick={scrollToPrices}
        >
          Oferta Limitada
        </Button>
        <Box mt={1.5}>
          <Typography
            sx={{
              color: 'white',
              fontSize: '1.2rem',
              textAlign: isMobile3 ? 'center' : 'left',
            }}
          >
            ventas solo hasta el 23 de julio
          </Typography>
        </Box>
      </Box>
    </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} sx={{ padding: isMobile2 ? '20px' : isMobile3 ? '30px' : '50px' }}>
        <Box ml={2.5}sx={{ 
        
        }}>

          <SingleImage Urlimage='https://academy-publics.s3.amazonaws.com/heroOriented2.png'></SingleImage>
        </Box>
      </Grid>
    </Grid>

    </Box>

      <Box
       sx={{
        backgroundImage: 'linear-gradient(to bottom, #232c41 50%, #232c41)',
        paddingTop: '0px',
        paddingBottom: '0px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
      >
      <Box mb={2} mr={2} ml={2}>
      <SingleImage Urlimage='https://academy-publics.s3.amazonaws.com/FLECHA.png'></SingleImage>
      </Box>
      <Box mr={0} ml={0}>
      <VimeoVideoPublicidad videoNumber="963699922" ></VimeoVideoPublicidad>
      </Box>
      </Box>

    <Box>

    </Box>

    {/* Descripcion del curso*/}
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #232c41)',
        paddingTop: '80px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } ,       
      }}        
        >
        <Box marginLeft={'50px'} marginRight={'50px'}>
        <SlideUpText text="Este curso está diseñado para estudiantes y profesionales que desean adquirir habilidades en programación y despliegue de aplicaciones"
           variant="h2" color='white' marginBottom="20px" />
        
        <SlideUpText text="Aprenderás desde conceptos básicos hasta técnicas avanzadas, con un enfoque práctico en aplicaciones reales"
           variant="h2" color='white' marginBottom="20px" />

          </Box> 
        
        </Grid>

        <Grid item xs={12} sm={6} sx={{ order: { xs: 2, sm: 2 } }}>
        <SingleImage Urlimage='https://academy-publics.s3.amazonaws.com/Floating+With+VR+1.png' boxHeight='35vh' ></SingleImage>
        </Grid>      
      </Grid>
    </Box>
      {/*Mensaje 2*/}
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 2%, #141b2d)',
        paddingTop: '40px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 2 } }}>
        <SlideUpText text="Te entregaremos una plantilla base con login incluido para que realices tus propias aplicaciones" variant="h2" color='white' marginBottom="20px" />
            <SingleImage Urlimage='https://academy-publics.s3.amazonaws.com/imagenplantilla.png'></SingleImage>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 2 } }}>
        <SlideUpText text="¿Por que tomar este curso?" variant="h2" color='white' marginBottom="20px" />
         
          <Box display="flex" alignItems="center">
          
          <HomeLandingCard2 cardDataList={cardMotivation} />
          </Box>
        </Grid>
      </Grid>
    </Box>
      {/*Imagen transicion*/}

      <Box      
      sx={{
        paddingTop: '40px',
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #3e4396)',
      }}
    >
      <SingleImage Urlimage='https://academy-publics.s3.amazonaws.com/tutor.png'></SingleImage>
    
    </Box>
      {/*Competencias profesionales a adquirir*/}
      <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #121b2b 80%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Competencias profesionales a adquirir " variant="h2" color="white" marginBottom="20px" />                  
          <ListItems items={itemListCompentencias} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #121b2b 30%, #121b2b)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Tambien Aprenderas" variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemListCompentencias2} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
        </Grid>
      </Grid>
    </Box>

    {/*Imagen transicion*/}
    <Box      
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #121b2b)',
      }}
    >      
      <SingleImage Urlimage='https://academy-publics.s3.amazonaws.com/programmer.png'></SingleImage>
    
    </Box>



    {/*Descargar informacion*/}
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #232c41)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },
        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Descargar Información y temario" variant="h2" color="white" marginBottom="20px" /> 
          <SingleImage Urlimage='https://academy-publics.s3.amazonaws.com/download.png' ></SingleImage>     
          <Box mt={5}>
          <Button
        variant="contained"
        size="large"
        href="https://academy-publics.s3.amazonaws.com/Temario_CursoDesarrolloAplicacionesWeb.pdf"
        target="_blank"
        sx={{
          backgroundColor: '#08E1BA',
          color: '#141b2b',
          fontSize: '1.2rem', // Aumentar el tamaño de la fuente
          padding: '0.5rem 0.5rem', // Aumentar el tamaño del botón
          '&:hover': {
            backgroundColor: '#08E1BA',
          },
        }}
      >
        Descargar PDF
      </Button>
      </Box>
          
         </Grid>
      </Grid>

    </Box>
        {/*A quien va dirido*/}

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #232c41)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },
        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="A quien va dirigido el curso " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemsDirigidoCurso} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Herramientas Necesarias " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemsHerramientas} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    {/*Temario*/}
    <Box
  sx={{
    backgroundImage: 'linear-gradient(to top, #232c41 30%, #3e4396)',
    paddingTop: '0px',
    paddingBottom: '40px',
    textAlign: { xs: 'center', sm: 'center' },
    width: '100%',
  }}
>
  <Grid container spacing={2} justifyContent="center">
    <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 }, marginLeft: '20px', marginRight: '20px' }}>
      <Box mb={2}>
      <Typography variant="h2">Temario Curso (14 horas|4 capitulos)</Typography>
      </Box>
      
      <Typography variant="h2"></Typography>
      <DropdownList modules={modules} />
    </Grid>

  </Grid>
</Box>

    {/*Metodologia Curso*/}

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #232c41)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Metodología " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemsMetodologiaCurso} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

      {/*Teachers*/}
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #3e4396)',
        paddingTop: '0px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
          
      }}
    >
      <Box display="flex" justifyContent="center" paddingTop={5} paddingBottom={5} >
      <SlideUpText text="Profesor del Curso " variant="h2" color="white" marginBottom="20px" />        
      </Box> 

    
                
        
    </Box>

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #232c41)',
        paddingTop: '0px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
          
      }}
    >
      <Grid container spacing={2} >
        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }} style={{ marginTop: '10px', paddingTop: '10px' }}>
         <Box mr={2} ml={2}>
          <SingleImage Urlimage='https://academy-publics.s3.amazonaws.com/image+34.png' boxHeight='45vh' ></SingleImage> 
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } }}>

        <Box
                sx={{
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  textAlign: { xs: 'center', sm: 'center', md: 'center' },
                }}
              >
                           
                
                <Typography variant="h3">Ing. Howart Ivan Riaño Aranguren</Typography>
              
                <Box display="flex" alignItems="center" gap="10px" sx={{textAlign: { xs: 'center', sm: 'center', md: 'center' }}}>                  
                <Avatar src="https://academy-public-resources.s3.amazonaws.com/publics/logounal+1_rounded.png" sx={{ width: 100, height: 100 }} />
                  <Typography variant="h4">Ingeniero Civil Universidad Nacional de Colombia</Typography>
                  
                </Box>
                <Box display="flex" alignItems="center" gap="10px"  sx={{textAlign: { xs: 'center', sm: 'center', md: 'center' }}} >
                <Avatar src="https://academy-public-resources.s3.amazonaws.com/publics/logo+uniandes+1_rounded.png" sx={{ width: 100, height: 100 }} />
                <Typography variant="h4">Magister en ingeniería civil con énfasis en estructuras de la Universidad de los Andes-Colombia</Typography>
                </Box>
                <Typography variant="h4">Diseñador estructural con 10 años experiencia </Typography>
                <Typography variant="h4">Entusiasta de la programación </Typography>
              </Box>
        
        </Grid>      
      </Grid>
    </Box>


    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #232d41)',
        paddingTop: '5px',
        paddingBottom: '5px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
        <SlideUpText text=" ¿Que te brindamos en Proex Academy? " variant="h2" color='#EA810F' marginBottom="20px" />
        
    </Box>

     {/*Aqui van las card*/} 

     <Box 
            sx={{ height: '50px', overflow: 'auto',margingTop: '50px',
            
            }}/>            
            
            <Box marginTop={3} marginRight={1} marginBottom={4} marginLeft={1} alignSelf='center'
            >
            {/*
            <CardComponent list={list_filters} />  
            */}
            
            <HomeLandingCard cardDataList={cardDataList} />
      </Box> 
      
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
      
      <WhatsAppButtonComponent />
    </div>
    
    
    <Box  display="flex" flexDirection="column" justifyContent="center" paddingTop={5} paddingBottom={5} 
    
       sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 2%, #141b2b2)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
       <SlideUpText text="Precios y Planes" variant="h2" color="white" marginBottom="5px" />        
      
      
      </Box> 
      

      <Box marginBottom={1} ref={pricesRef}>        
        <Box mr={2} ml={2} mb={5}>
          <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }} >         
            <SingleImage Urlimage={getImage()} boxHeight='45vh' urlRedirection={getPaymentLink()}></SingleImage> 
          </Grid>
        </Box>
        <Box 
      
        display="flex"
      justifyContent="center"
      alignItems="center">
        <RegisterComponent TEXTOPRINCIPAL={"Registro en el curso de desarrollo y despliegue de aplicaciones "}></RegisterComponent>
        </Box>
        <Box mt={5}   display="flex"
      justifyContent="center"
      alignItems="center">
        <CursoBox
        curso="Desarrollo y despliegue de Applicaciones con inteligencia artificial"
        precio={getPrice()}
        descripcion="Acceso por un año al curso y sus recursos
        Pago electrónico o físico"
        linkDePago={getPaymentLink()}
      />
      </Box>

      </Box>

 


    <Box display="flex" flexDirection="column" justifyContent="center" paddingTop={5} paddingBottom={5} 
       sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 2%, #141b2d)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <SlideUpText text="¡Únete a nuestra comunidad de estudiantes motivados y " variant="h2" color="white" marginBottom="20px" />        
      <SlideUpText text="construye un futuro brillante en tu profesión con Proex Academy! " variant="h2" color="white" marginBottom="20px" />        
      </Box> 

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 2%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >


    </Box>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>      
      <WhatsAppButtonComponent />
    </div>
    <FooterLanding></FooterLanding>




    </div>
   
    
    
  );
};

export default Cursodesarrolloapps05;
