import React from 'react';
import { Card, CardContent, CardActions, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

 
const CardFreeApps = ({ title, description, imageUrl,to }) => {

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(`/freeapps/${to}`);
  };

  const CardContainer = styled(Card)(({ theme }) => ({
    maxWidth: 400,
    margin: '0 auto',
    background: 'linear-gradient(to right, #FF5F00, #FF3000)', // Verde
    color: '#fff',
    borderRadius: 10,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 12,
    letterSpacing: 1,
  }));

  const Image = styled('img')({
    width: '100%',
    height: 200,
    objectFit: 'cover',
    borderRadius: '10px 10px 0 0',
  });

  const Actions = styled(CardActions)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
  }));

  const ButtonStyled = styled(Button)(({ theme }) => ({
    color: '#fff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 1,
  }));

  return (
    <CardContainer>
      <Image src={imageUrl} alt="Card" />
      <CardContent>
        <Title variant="h2" component="div">
          {title}
        </Title>
        <Typography variant="body1" component="div">
          {description}
        </Typography>
      </CardContent>
      <Actions>
        
        <ButtonStyled onClick={handleButtonClick} variant="contained">Entrar</ButtonStyled>
        
        
      </Actions>
    </CardContainer>
  );
};

export default CardFreeApps;
