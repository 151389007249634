import React from 'react';
import { Grid, Box, Card, CardContent, Typography } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

const CardComponent = ({ list }) => {
  const theme = useTheme();
  const isMobile =  useMediaQuery(theme.breakpoints.down('lg'));
  const margins = isMobile ? theme.spacing(1) : theme.spacing(5);
  return (
    <Grid container spacing={2}>
      {list.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Box m={margins} >
            <Card sx={{ backgroundColor: item.color }}>
              <CardContent>
                <Typography variant="h5" component="div" sx={{ color: item.textColor }}>
                  {item.text}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardComponent;
