import React from 'react';
import { Box, Typography } from '@mui/material';

const ListaComponentes = ({ lista }) => {
  return (
    <Box marginTop={1}>    
      {lista.map((item, index) => (
        <Box key={index} p={2} mb={2} bgcolor="white">
          <Typography color="primary" variant="h3">{item.titulo}</Typography>          
        </Box>
      ))}
 
    </Box>
  );
};

export default ListaComponentes;
