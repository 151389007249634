import { useMediaQuery, useTheme, Box } from '@mui/material';

const SingleImage = ({ Urlimage, boxHeight, boxWidth, urlRedirection }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  let imageSize = isMobile ? '220px' : '300px'; // Tamaño para dispositivos móviles
  if (isTablet) {
    imageSize = '400px'; // Tamaño para tabletas
  }

  const handleClick = () => {
    if (urlRedirection) {
      window.location.href = urlRedirection;
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop={0} // Elimina el margen superior
      onClick={handleClick}
      sx={{ cursor: urlRedirection ? 'pointer' : 'default' }} // Cambia el cursor si hay redirección
    >
      <img
        src={Urlimage}
        alt="Imagen"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          width: 'auto',
          height: 'auto',
        }}
      />
    </Box>
  );
};

export default SingleImage;
