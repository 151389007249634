import React from 'react';
import { useTheme } from '@emotion/react';
import { Grid, Paper, Box, Tabs, Tab,Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomBoxList from './inputcomponent';
import DrawingComponent from './drawing2d';
import DrawingComponentt from './draw2dtree';
import DownloadButtonPDF from './pdfReact';


const SplitComponentConstructor = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const data = {
    title: 'Mi Documento',
    paragraphs: [
      'Este es un párrafo de ejemplo.',
      'Aquí puedes agregar más párrafos.',
    ],
    equations: [
      'Ecuación 1: x + y = z',
      'Ecuación 2: a^2 + b^2 = c^2',
    ],
    images: [
      'https://academy-public-resources.s3.amazonaws.com/publics/academy.png',
      'https://academy-public-resources.s3.amazonaws.com/publics/academy.png',
    ],
    tables: [
      {
        headers: ['Columna 1', 'Columna 2', 'Columna 3'],
        rows: [
          ['Dato 1', 'Dato 2', 'Dato 3'],
          ['Dato 4', 'Dato 5', 'Dato 6'],
        ],
      },
      {
        headers: ['Columna A', 'Columna B'],
        rows: [
          ['Dato A1', 'Dato B1'],
          ['Dato A2', 'Dato B2'],
          ['Dato A3', 'Dato B3'],
        ],
      },
    ],
  };
  

  const coordinates = [
    { x: 0, y: 0 },
    { x: 1, y: 0 },
    { x: 1, y: 1 },
    { x: 0, y: 1 },
    { x: 0, y: 0 }, // Repite el primer punto para cerrar la forma
  ];
  const dataList = [
    {
      id: 1,
      title: 'Cuadro 1',
      backgroundColor: '#3e4396',
      textColor: 'white',
      imageURL: 'https://academy-public-resources.s3.amazonaws.com/publics/iconos-cursos/iconoavaluos.svg', // URL de la imagen para este cuadro (opcional)
      maxWidth: '100%', // Ejemplo: ajustar al ancho máximo disponible
      maxHeight: '200px', // Ejemplo: altura máxima de 250 píxeles
      '2dgraphic': true, // Palabra clave para renderizar el componente DrawingComponent
      fields: [
        {
          id: 1,
          label: 'Campo 1',
          description: 'Descripción del Campo 1',
          units: 'Unidades',
          type: 'integer',
          value: '',
        },
        {
          id: 2,
          label: 'Campo 2',
          description: 'Descripción del Campo 2',
          units: 'Unidades',
          type: 'float',
          value: '',
          additionalDescription: 'Descripción adicional del Campo 2',
        },
        {
          id: 3,
          label: 'Campo 3',
          description: 'Descripción del Campo 3',
          units: 'Unidades',
          type: 'string',
          value: '',
        },
        {
          id: 4,
          label: 'Campo 4',
          description: 'Descripción del Campo 4',
          units: 'Unidades',
          type: 'select',
          value: '',
          selections: ['Opción 1', 'Opción 2', 'Opción 3'],
        },
      ],
    },
    {
      id: 2,
      title: 'Cuadro 2',
      backgroundColor: '#141b2b',
      textColor: 'white',
      imageURL: 'https://academy-public-resources.s3.amazonaws.com/publics/iconos-cursos/iconoavaluos.svg', // URL de la imagen para este cuadro (opcional)
      maxWidth: '100%', // Ejemplo: ajustar al ancho máximo disponible
      maxHeight: '250px', // Ejemplo: altura máxima de 250 píxeles
      fields: [
        {
          id: 1,
          label: 'Campo 1',
          description: 'Descripción del Campo 1',
          units: 'Unidades',
          type: 'integer',
          value: '',
        },
        {
          id: 2,
          label: 'Campo 2',
          description: 'Descripción del Campo 2',
          units: 'Unidades',
          type: 'float',
          value: '',
          additionalDescription: 'Descripción adicional del Campo 2',
        },
        {
          id: 3,
          label: 'Campo 3',
          description: 'Descripción del Campo 3',
          units: 'Unidades',
          type: 'string',
          value: '',
        },
        {
          id: 4,
          label: 'Campo 4',
          description: 'Descripción del Campo 4',
          units: 'Unidades',
          type: 'select',
          value: '',
          selections: ['Opción 1', 'Opción 2', 'Opción 3'],
        },
      ],
      
    },
  ];
  
 
  

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {isMobile ? (
       
      <Grid item xs={12} marginTop={0}>
        <Box ma={0} alignContent={'center'} alignItems={'center'} fontWeight="bold">
          <Typography color="secondary" variant="h5">hola</Typography>
        </Box>
        <Paper sx={{ bgcolor: '#45ceac' }}>
          <Tabs >
            <Tab label="Clases" sx={{ color: '#2f2f2f' }} />
            <Tab label="Recursos" sx={{ color: '#2f2f2f' }} />
            <Tab label="Comentarios" sx={{ color: '#2f2f2f' }} />
          </Tabs>
        
        </Paper>
      </Grid>
      ) : (
        <Grid container spacing={2} style={{ width: '100%', padding: theme.spacing(2) }}>
          <Grid item xs={6}>
            <Paper style={{ height: '100%', padding: theme.spacing(2), backgroundColor: '#232c41' }}>
              <Box style={{ overflow: 'auto', maxHeight: '95vh', color: 'black' }}>
                {/* Contenido de prueba para la zona 1 */}
                <Box m={5} mt={15}>
                <CustomBoxList data={dataList} />;
                </Box>
                <Box>
                <DrawingComponentt coordinates={coordinates} />
                </Box>
                           
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper style={{ height: '100%', padding: theme.spacing(2), backgroundColor: '#232c41' }}>
              <Box style={{ overflow: 'auto', maxHeight: '95vh', color: 'black' }}>
                {/* Contenido de prueba para la zona 2 */}
                <Typography>hola aca</Typography>
                <Typography>hola aca</Typography>
                <Typography>hola aca</Typography>
                <DownloadButtonPDF data={data} />
              </Box>
            </Paper>

          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default SplitComponentConstructor;
