import React from 'react';
import { Box, Typography, Button, Grid,ListItem ,ListItemIcon,Stack,Avatar} from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery'
import { tokens } from "../../theme";
import { useTheme } from '@emotion/react';
import SlideUpText from '../../components/TextAnimation1';
import CheckIcon from '@mui/icons-material/Check';
import CardCustom from '../../components/CardT1/CardT5';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeLandingCard from '../../components/CardT1/CardT6';
import HomeLandingCard2 from '../../components/CardT1/CardT7';
import SingleImage from '../../components/images/imagen1';
import HomeLandingCard3 from '../../components/CardT1/CardT8';
import WhatsAppButtonComponent from '../../components/whatsapp/WhatsappButton';
import FacebookButton from '../../components/Facebook/FabookDir';
import InstagramButton from '../../components/Instagram/Instagram';
import FooterLanding from './FooterLandin';
import SingleImage2 from '../../components/images/imagen2';
import DropdownList from '../../components/desplegable_modulos';
import DownloadButton from '../../components/DownLoadButton';
import ItemList from '../../components/Lista1';
import ListItems from '../../components/Lista2';
import CardCustomTeacher from '../../components/CardT1/cardT10';
import PricesCourses1 from './prices1';
import VimeoVideoPublicidad from '../../components/VimeoVideo2';

const CusosPuentes1Page = () => {
    const theme = useTheme();
    const isMobile3 = useMediaQuery('(max-width: 960px)');
    const isMobile2 = useMediaQuery('(max-width: 600px)');    
    const colors = tokens(theme.palette.mode);
    const pdfUrl = 'https://academy-public-resources.s3.amazonaws.com/publics/temarios/C03.png';

    const itemListCompentencias = ['Vigas I de acero rectas',
                                   'Vigas cajón de acero rectas y curvas',
                                   'Vigas para momentos positivos y negativos' ,
                                   'En seccion simple y compuesta',
                                   'Tableros de concreto para puentes', 
                                   'Barreras de concreto ',
                                   'Barandas peatonales de acero', 
                                   'Arriostrados y conexiones',
                                  
                                ];
   const itemListCompentencias2 = ['Avalúos de cargas para puentes',                                  
                                  'Modelación en el software CSI Bridge', 
                                  'Interpretacion resultados software CSI Bridge', 
                                  'Acerca de Procesos Constructivos para superestructuras ',
                                  'Fundamentos de comportamiento del  concreto reforzado',
                                  'Fundamentos de comportamiento del acero (enfocado en puentes)',
                                  ];

    const itemsMetodologiaCurso = [
    
    'Clases Pregrabadas, acceso 24/7',
    '30 Horas de Clases',
    'Presentaciones de las clases',
    'Documentos adicionales de apoyo',    
    'Certificado de Asistencia',
    'Solución de dudas por sistema de comentarios'
    ];

    const itemsDirigidoCurso = [
    
      'Estudiantes de Ingenieria civil',
      'Ingenieros civiles diseñadores estructurales',
      'Especialistas en estructuras',      
      ];

      

    const itemsHerramientas = [    
        'Uso CSI Bridge',
        'Familiaridad con la Ingenieria Civil',
        'Maxima disposicion a aprender',      
        ];
    const modules = [
      {
        title: 'Módulo 1 - Introducción',
        duration: '2 horas 49 min',
        clases: 'Son 5 Clases',
        classesList: [
          {
            name: 'Introducción al curso',
            duration: '2:48 min',
          },
          {
            name: 'Tipos de Puentes en Acero',
            duration: '28:50 min',
          },
          {
            name: 'Concepción y aplicabilidad puentes de placa y viga en acero',
            duration: '49:53 min ',
          },
          {
            name: 'Diseño por factores de resistencia de carga',
            duration: '49:14 min',
          },
          {
            name: 'Introducción a normativas CCP-14 Y AASHTO LRFD para diseño de puentes',
            duration: '14:26 min',
          },
        ],

      },
      {
        title: 'Avaluo Cargas y diseño de tablero',
        duration: '5 horas 55 min',
        clases: 'Son 8 clases',
        classesList: [
          {
            name: 'Caracteristicas generales y ubicación del puente',
            duration: '32:08 min',
          },
          {
            name: 'Avaluó de cargas-Parte 1',
            duration: '50:38 min',
          },
          {
            name: 'Avaluó de cargas-Parte 2',
            duration: '50:38 min',
          },
          {
            name: 'Solicitaciones en un tablero de concreto reforzado',
            duration: '17:57 min',
          },
          {
            name: 'Métodos de Análisis para tableros',
            duration: '38:41 min',
          },
          {
            name: 'Comportamiento Concreto Reforzado',
            duration: '49:05 min',
          },
          {
            name: 'Analisis con software',
            duration: '39:01 min',
          },
          {
            name: 'Diseño de tablero',
            duration: '1:19:41 horas',
          }
        ],
      },
      {
        title: 'Módulo 3 - Diseño de Barrera',
        duration: '1 horas 32 min',
        clases: 'Son 4 Clases',
        classesList: [
          {
            name: 'Diseño de barreras e importancia',
            duration: '33:16 min',
          },
          {
            name: 'Avaluo de cargas para barreras',
            duration: '9:29 min',
          },
          {
            name: 'Análisis de barreras- método líneas de fluencia',
            duration: '9:26 min ',
          },
          {
            name: 'Diseño de barrera',
            duration: '39:52 min',
          }
        ],

      },

      {
        title: 'Módulo 4 - Comportamiento vigas acero puentes y diseño de vigas tipo I',
        duration: '12 horas 53 min',
        clases: 'Son 17 Clases',
        classesList: [
          {
            name: 'Entendiendo el proceso constructivo',
            duration: '55:09 min',
          },
          {
            name: 'Comportamiento de acero estructural para puentes-Parte 1',
            duration: '40:37 min',
          },
          {
            name: 'Comportamiento de acero estructural para puentes-Parte 2',
            duration: '42:31 min ',
          },
          {
            name: 'Comportamiento de vigas de acero',
            duration: '1:10:24 horas',
          },
          {
            name: 'Modelacion Conceptual',
            duration: '21:27 min',
          },
          {
            name: 'Modelacion estructural-Parte 1',
            duration: '1:10:24 horas',
          },
          {
            name: 'Modelacion estructural-Parte 2',
            duration: '46:42 min ',
          },
          {
            name: 'Modelacion estructural-Parte 3',
            duration: '1:02:09 horas',
          },
          {
            name: 'Analisis aproximado vigas de una sola luz',
            duration: '18:09 min',
          },
          {
            name: 'Analisis aproximado factores de carga',
            duration: '49:51 min',
          },
          {
            name: 'Generalidades y limites dimensionales vigas I',
            duration: '33:12 min ',
          },
          {
            name: 'Resistencia a flexion y cortante vigas I',
            duration: '46:11 min',
          },
          {
            name: 'Parametros de secciones Vigas I',
            duration: '30:28 min',
          },
          {
            name: 'Diseño estructural por Constructibilidad y Estado limite de servicio vigas I',
            duration: '50:51 min',
          },
          {
            name: 'Diseño estructural estado limite de servicio y Estado limite de fatiga vigas I',
            duration: '47:00 min ',
          },
          {
            name: 'Diseño conectores de cortante y rigidizadores vigas I',
            duration: '56:32 min',
          },
          {
            name: 'Consideraciones puentes Petaonales',
            duration: '22:50 min',
          }
        ],

      },
      {
        title: 'Módulo 5 - Diseño vigas cajon de Acero',
        duration: '3 horas 55 min',
        clases: 'Son 8 Clases',
        classesList: [
          {
            name: 'Entendiendo el proceso constructivo',
            duration: '23:24 min',
          },
          {
            name: 'Comportamiento viga cajon de acero',
            duration: '29:03 min',
          },
          {
            name: 'Generalidades y limites dimensionales',
            duration: '8:13 min ',
          },
          {
            name: 'Calculo parámetros de secciones',
            duration: '47:56 min',
          },
          {
            name: 'Resistencia a la torsion',
            duration: '18:22 min',
          },
          {
            name: 'Diseño estructural por contructibilidad M-',
            duration: '58:49 min',
          },
          {
            name: 'Diseño estructural por estado limite de servicio M-',
            duration: '11:52 min ',
          },
          {
            name: 'Diseño Estructural por estado limite de resistencia M-',
            duration: '37:25 min',
          }
        ],

      },
      {
        title: 'Módulo 6 - Diseño de Barandas peatonales',
        duration: '1 hora 13 min',
        clases: 'Son 4 Clases',
        classesList: [
          {
            name: 'Conceptos y avaluo de cargas para diseño de barandas',
            duration: '8:41 min',
          },
          {
            name: 'Comportamiento del acero estructural',
            duration: '25:53 min',
          },
          {
            name: 'Analisis de baranda Peatonal',
            duration: '20:14 min ',
          },
          {
            name: 'Diseño de baranda Peatonal',
            duration: '18:47 min',
          }
        ],

      },
      {
        title: 'Módulo 7 - Diseño de conexiones, arriostrados y diafragmas',
        duration: '1 hora 36 min',
        clases: 'Son 4 Clases',
        classesList: [
          {
            name: 'Modelacion para diseño de arriostrados y diafragmas',
            duration: '17:03 min',
          },
          {
            name: 'Diseño de arriostrados y diafragmas Con Software',
            duration: '50:58 min',
          },
          {
            name: 'Diseño de Elementos a tension y compresion',
            duration: '28:55 min ',
          },
          {
            name: 'Diseño de conexiones',
            duration: '35:03 min',
          }
        ],

      },
     
    ];
    
    const cardDataList = [
      {
        title: 'Clases Ejercicios',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Desarrollamos ejemplos paso a paso para que puedas entender y aprender facilmente, Video Clases 24/7',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 1
        to:'c01',
      },
      {
        title: 'Clases conceptuales ',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Clases produndas de fundamentos y conceptos, Video Clases 24/7',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 2
        to:'c02',
      },
      {
        title: 'Clases Manejo Software',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Clases 100% orientadas al trabajo del dia a dia de los ingenier@s, Video Clases 24/7',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 3
        to:'c03',
      },
      {
        title: 'Material Escrito Complementario',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Te brindamos materiales de apoyo complementario en PDF',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      },
      {
        title: 'Certificado de Asistencia',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Te otorgamos certificado de asistencia al tomar todas las clases',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      },
      {
        title: ' Preguntas y respuestas',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Respondemos tus preguntas acerca de las clases en nuestro sistema de comentarios',
        gradientColors: ['#f2c594','#f2c594'], // Colores del gradiente para el Curso 3
        to:'c04',
      }


    ];
    

    const cardMotivation = [
      {
        title: 'Puedas avanzar en tu carrera profesional como diseñador estructural',
        
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 1
        to:'c01',
      },
      {
        title: 'Potencies tu criterios de ingenieria, tomes  decisiones mas acertadas y evites cometer errores ',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',       
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 2
        to:'c02',
      },
      {
        title: 'Adquieras nuevas habilidades y estes list@ para enfrentarte a nuevos retos',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        
        gradientColors: ['#141b2d','#141b2d'], // Colores del gradiente para el Curso 3
        to:'c03',
      }
    ];





    const cardDataList3 = [
      {
        title: 'Cursos Estructurales',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba1.PNG',
        description: 'Cursos de fundamentos, análisis y diseño estructural aplicado al trabajo practico de los Ingenier@s civiles',
        gradientColors: ['#64e1c0','#64e1c0'], // Colores del gradiente para el Curso 1
        to:'cursos',
      },
   


    ];





    const imageUrl1 = 'https://academy-public-resources.s3.amazonaws.com/publics/student+main.svg';
    const imageUrl2 = 'https://academy-public-resources.s3.amazonaws.com/publics/bridge1.svg';
    const imageUrl3 = 'https://academy-public-resources.s3.amazonaws.com/publics/building+_1.svg';

  
  return (
    <div>

  
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #141b2d 50%, #232c41)',
        paddingTop: '105px',
        paddingBottom: '20px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } , marginTop: '20px', marginRight: (isMobile2 ? '20px' : isMobile3 ? '30px' : '200px') , marginLeft: (isMobile2 ? '20px' : isMobile3 ? '30px' : '200px') }}>
         
          <Box sx={{
        borderRadius: '10px',
        backgroundColor:'#141b2b',
        border: '3px solid #9de781'
        // Resto de estilos
      }}>
          <SlideUpText text="Diseño estructural puentes de acero en vigas tipo «I» y tipo cajón" variant="h2" color="#9de781" marginBottom="20px" />
          <SlideUpText text="Construye conocimiento, potencia tu éxito en la construcción" variant="h4" color="white" marginBottom="20px" />
          </Box>
          <SingleImage2 Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/iconos-cursos/iconopuentes2.svg'></SingleImage2>
        </Grid>
        
       
      </Grid>
    </Box>

      <Box
       sx={{
        backgroundImage: 'linear-gradient(to bottom, #232c41 50%, #232c41)',
        paddingTop: '0px',
        paddingBottom: '0px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
      >
      <VimeoVideoPublicidad videoNumber="846341732" ></VimeoVideoPublicidad>
      </Box>

    <Box>

    </Box>

    {/* Descripcion del curso*/}
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #232c41)',
        paddingTop: '80px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } ,       
      }}        
        >
        <Box marginLeft={'50px'} marginRight={'50px'}>
        <SlideUpText text="Este es un programa  donde se enseñan los conocimientos necesarios para diseñar las vigas tipo I y  tipo cajón de acero en puentes vehiculares; En luces simples, continuas, rectas y curvas  de acuerdo a los códigos de diseño estructural AASHTO LRFD & CCP-14"
           variant="h3" color='white' marginBottom="20px" />
        
        <SlideUpText text="El curso esta divido en 7 capítulos con duración total de 30 horas"
           variant="h3" color='white' marginBottom="20px" />
        
        <SlideUpText text="Al finalizar el curso podrás tener todos los conocimientos para hacer tus propios diseños estructurales de puentes de acero y recibiras tu certificado de asistencia al curso"
           variant="h3" color='white' marginBottom="20px" />
          </Box> 
        
        </Grid>

        <Grid item xs={12} sm={6} sx={{ order: { xs: 2, sm: 2 } }}>
        <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/puentevigai1+2+min.png' boxHeight='35vh' ></SingleImage>
        </Grid>      
      </Grid>
    </Box>
      {/*Mensaje 2*/}
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 2%, #141b2d)',
        paddingTop: '40px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 2 } }}>
        <SlideUpText text="En Proex Academy creamos este curso de puentes para que" variant="h2" color='white' marginBottom="20px" />
         
          <Box display="flex" alignItems="center">
          
          <HomeLandingCard2 cardDataList={cardMotivation} />
          </Box>



                </Grid>
           
      </Grid>
    </Box>
      {/*Imagen transicion*/}

      <Box      
      sx={{
        paddingTop: '40px',
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #3e4396)',
      }}
    >
      <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/puentevigai2.jpg'></SingleImage>
    
    </Box>
      {/*Competencias profesionales a adquirir*/}
      <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #121b2b 80%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Competencias profesionales a adquirir " variant="h2" color="white" marginBottom="20px" />        
          <SlideUpText text="Aprenderas a diseñar" variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemListCompentencias} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #121b2b 30%, #121b2b)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Tambien Aprenderas" variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemListCompentencias2} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    {/*Imagen transicion*/}
    <Box      
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #121b2b)',
      }}
    >      
      <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/puentevigacajon1+1_min.jpg'></SingleImage>
    
    </Box>



    {/*A quien va dirido*/}

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #232c41)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },
        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="A quien va dirigido el curso " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemsDirigidoCurso} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Herramientas Necesarias " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemsHerramientas} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

    {/*Temario*/}
    <Box
  sx={{
    backgroundImage: 'linear-gradient(to top, #232c41 30%, #3e4396)',
    paddingTop: '0px',
    paddingBottom: '40px',
    textAlign: { xs: 'center', sm: 'center' },
    width: '100%',
  }}
>
  <Grid container spacing={2} justifyContent="center">
    <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 }, marginLeft: '20px', marginRight: '20px' }}>
      <Typography variant="h2">Temario Curso</Typography>
      <Typography variant="h2"></Typography>
      <DropdownList modules={modules} />
    </Grid>

  </Grid>
</Box>

    {/*Metodologia Curso*/}

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 30%, #232c41)',
        paddingTop: '40px',
        paddingBottom: '10px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },        
      }}
      >
      <Grid container spacing={2} justifyContent="center" >
        <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 1 } ,marginRight:'20px', marginLeft:'20px'}}>
          <SlideUpText text="Metodología " variant="h2" color="white" marginBottom="20px" />        
          <ListItems items={itemsMetodologiaCurso} backgroundColor="#141b2b" textColor="white" iconColor="#64e1c0" textSize="h4" iconSize={30}/>        
         </Grid>
      </Grid>
    </Box>

      {/*Teachers*/}
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #3e4396)',
        paddingTop: '0px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
          
      }}
    >
      <Box display="flex" justifyContent="center" paddingTop={5} paddingBottom={5} >
      <SlideUpText text="Los Profesores del Curso " variant="h2" color="white" marginBottom="20px" />        
      </Box> 

      <Grid container spacing={2} >
                
        
            <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }} style={{ marginTop: '10px', paddingTop: '10px' }}>               

              <Box
                sx={{
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  textAlign: { xs: 'center', sm: 'center', md: 'center' },
                }}
              >
                           
                  <Typography variant="h3">Ing. Oscar Eduardo Triviño Ayala</Typography>
              
                <Box display="flex" alignItems="center" gap="10px" sx={{textAlign: { xs: 'center', sm: 'center', md: 'center' }}}>                  
                <Avatar src="https://academy-public-resources.s3.amazonaws.com/publics/logouniversidad+Javeriana+1_rounded.png" sx={{ width: 100, height: 100 }} />
                  <Typography variant="h4">Ingeniero Civil Pontifica Universidad Javeriana-Colombia</Typography>
                  
                </Box>
                <Box display="flex" alignItems="center" gap="10px"  sx={{textAlign: { xs: 'center', sm: 'center', md: 'center' }}} >
                <Avatar src="https://academy-public-resources.s3.amazonaws.com/publics/logo+uniandes+1_rounded.png" sx={{ width: 100, height: 100 }} />
                <Typography variant="h4">Magister en ingeniería civil con énfasis en estructuras de la Universidad de los Andes-Colombia</Typography>
                </Box>
                <Typography variant="h4">Diseñador estructural con 9 años de experiencia en el diseño de Puentes, Estructuras ambientales, Industriales y edificaciones</Typography>
                <Typography variant="h4">Ingeniero apasionado por la excelencia y el conocimiento</Typography>
              </Box>

         
                </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } }}>
        <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/otimage3_min.png' boxHeight='40vh' ></SingleImage>
        </Grid>      
      </Grid>
    </Box>

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #232c41)',
        paddingTop: '0px',
        paddingBottom: '40px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
          
      }}
    >
      <Grid container spacing={2} >
        
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }} style={{ marginTop: '10px', paddingTop: '10px' }}>
         
          <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/hrimage3_min.png' boxHeight='45vh' ></SingleImage> 
        </Grid>
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } }}>

        <Box
                sx={{
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  textAlign: { xs: 'center', sm: 'center', md: 'center' },
                }}
              >
                           
                  <Typography variant="h3">Ing. Howart Ivan Riaño Aranguren</Typography>
              
                <Box display="flex" alignItems="center" gap="10px" sx={{textAlign: { xs: 'center', sm: 'center', md: 'center' }}}>                  
                <Avatar src="https://academy-public-resources.s3.amazonaws.com/publics/logounal+1_rounded.png" sx={{ width: 100, height: 100 }} />
                  <Typography variant="h4">Ingeniero Civil Universidad Nacional de Colombia</Typography>
                  
                </Box>
                <Box display="flex" alignItems="center" gap="10px"  sx={{textAlign: { xs: 'center', sm: 'center', md: 'center' }}} >
                <Avatar src="https://academy-public-resources.s3.amazonaws.com/publics/logo+uniandes+1_rounded.png" sx={{ width: 100, height: 100 }} />
                <Typography variant="h4">Magister en ingeniería civil con énfasis en estructuras de la Universidad de los Andes-Colombia</Typography>
                </Box>
                <Typography variant="h4">Diseñador estructural con 9 años experiencia en el diseño de Puentes, estructuras civiles y edificaciones de acero estructural</Typography>
                <Typography variant="h4">Ingeniero apasionado por la calidad e innovación </Typography>
              </Box>
        
        </Grid>      
      </Grid>
    </Box>


    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #232d41)',
        paddingTop: '5px',
        paddingBottom: '5px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
        <SlideUpText text=" ¿Que te brindamos en Proex Academy? " variant="h2" color='#EA810F' marginBottom="20px" />
        
    </Box>

     {/*Aqui van las card*/} 

     <Box 
            sx={{ height: '50px', overflow: 'auto',margingTop: '50px',
            
            }}/>            
            
            <Box marginTop={3} marginRight={1} marginBottom={4} marginLeft={1} alignSelf='center'
            >
            {/*
            <CardComponent list={list_filters} />  
            */}
            
            <HomeLandingCard cardDataList={cardDataList} />
      </Box> 
      
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
      
      <WhatsAppButtonComponent />
    </div>
    

    <Box display="flex" flexDirection="column" justifyContent="center" paddingTop={5} paddingBottom={5} 
       sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 2%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
       <SlideUpText text="Precios y Planes" variant="h2" color="white" marginBottom="20px" />        
      
      </Box> 



    <Box m={2} marginTop={10}>
        <PricesCourses1 colors={colors} />
    </Box>


    <Box display="flex" flexDirection="column" justifyContent="center" paddingTop={5} paddingBottom={5} 
       sx={{
        backgroundImage: 'linear-gradient(to top, #3e4396 2%, #141b2d)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <SlideUpText text="¡Únete a nuestra comunidad de estudiantes motivados y " variant="h2" color="white" marginBottom="20px" />        
      <SlideUpText text="construye un futuro brillante en la construcción con Proex Academy! " variant="h2" color="white" marginBottom="20px" />        
      </Box> 

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 2%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >

     <Link to="/register">
               <Button style={{ fontSize: isMobile2 ? '1.2rem' : '1.5rem' ,fontWeight: isMobile2 ? 'normal' : '600'}} sx={{ fontSize: '1rem' }} variant='contained' color='secondary'>
                Registrarme y comprar  </Button>             
    </Link> 
                       

       
     
    </Box>




        
        
     


    <FooterLanding></FooterLanding>




    </div>
   
    
    
  );
};

export default CusosPuentes1Page;
