import {Typography,Paper,Stack,TextField,Button,Box, Select,MenuItem,FormControl,InputLabel } from "@mui/material";
import { useState} from "react";
import Header from "../../components/Header";


function RecoverPage() {
   

    {/*Controles Verificacion de Email*/}
    const [email, setEmail] = useState("");  
    const [Cluecellphone,setCluecellphone]=useState("");     
    const [numbers,setnumners]=useState("");    
    const [password,setpassword]=useState("");    

    const handleEmailChange = (event) => {
      const value = event.target.value;      
      setEmail(value);
    };

    const handleCluecellphone = (event) => {
          setCluecellphone(event);
          console.log(event) 
        }

    
    const handlenumbersChange = (event) => {
          const value = event.target.value;      
          setnumners(value);
        };

    const handlepasswordChange = (event) => {
      const value = event      
      setpassword(value);
    }

    const [isRecoverVisible,setRecoverVisible]=useState(false);
    const handleRecoverVisible=(event)=>{          
          setRecoverVisible(event);
          console.log(event) 
        }
       
    const VerifyEmail=async()=>{
      const response = await fetch('https://8ori4qvsnc.execute-api.us-east-1.amazonaws.com/dev/recoverpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "typeoperation": "verify-email",
          "email-academy": email        
        })
      });    

        const data = await response.json();  
        const validation=data.emailexist           
        const Cluecellphone=data.cellphoneclue          
        
        
        if (validation==false)
        {alert("Ups, tu correo no esta registrado.");}

        handleRecoverVisible(validation)        
        console.log(validation)
        console.log(Cluecellphone+"10")
        handleCluecellphone(Cluecellphone)
    }
        

    const RecoverPassword=async()=>{
      const response = await fetch('https://8ori4qvsnc.execute-api.us-east-1.amazonaws.com/dev/recoverpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "typeoperation": "showpassword",
            "email-academy": email,
            "numbers": numbers            
        })
      });    

        const data = await response.json();  
        const password=data.password  
        const labelpassword=data.labelpassword 
        console.log(labelpassword)
        handlepasswordChange(labelpassword)
    }
        
    return (      
      
      <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',
      padding: '30px' 
      }}
      >

      <Box m="30px" sx={{ display: 'flex', width:'400px',  height: '80vh', alignItems: 'center', justifyContent: 'center',
      //borderLeft: '2px solid grey',
      //border: '1px solid #CCCCCC', // Establece un borde gris claro de 1 píxel    
      padding: '20px', // Establece un padding de 20 píxeles en todos los lados del componente Box
      }}> 
          <Stack direction='column' 
          spacing={4}>
            <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
              
                <img
                  alt="profile-user"
                  width="240px"
                  height="80px"
                  src={`../../assets/logoproexblanco.png`}                  
                />
          
            </Box>
            <Header title="RECUPERACIÓN CONTRASEÑA" subtitle="" />
            <TextField variant="filled" onChange={handleEmailChange} label='Correo' />
            <Button variant='contained' color='secondary' onClick={VerifyEmail}>Verificar Correo</Button>  
            
            {isRecoverVisible ? (
              <Stack direction='column' spacing={4} marginBottom={10}>
          <Typography>Tu correo ha sido Verificado</Typography>
          <Typography>Tu Celular es: {Cluecellphone}</Typography>
          <TextField variant="filled" onChange={handlenumbersChange} label='Escribe los tres numeros Faltantes' />
          <Button variant='contained' color='secondary' onClick={RecoverPassword}>Recuperar Contraseña</Button>
          <Typography  variant="h4">Respuesta</Typography>
          <Typography color="#4cceac" variant="h3">{password}</Typography>
          
          
          </Stack>
            ) : (
          <Typography></Typography>
            )}
            
            
           
          </Stack>
        </Box>
        </Box>
     
    );
    
  }
  export default RecoverPage