import { useState,useEffect } from "react";

import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import {BrowserRouter as Router,Route,Routes,Navigate} from 'react-router-dom'


import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
//import Calendar from "./scenes/calendar/calendar";



import { CssBaseline, ThemeProvider,Box } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import MyPrograms from './scenes/EmisProgramas';
import MyProjects from './scenes/EmyProjects';
import MySheets from './scenes/EmySheets';
import NewSheet from './scenes/EnewSheet';
import Enterprise from './scenes/Enterprise';
import HomePage from "./scenes/Home/Home";
import IndexPage from './scenes/Index';
import LoginPage from './scenes/LLogin';
import RecoverPage from './scenes/LRecover';
import RegisterPage from './scenes/register/LRegister';
import MarketPlaceapps from "./scenes/Marketplaceapps";
import MarketPlace from "./scenes/Marketplace";
import ThankYouPage from "./scenes/sells/tankyoupage";

import MyNoteBook from './scenes/MiBlockNotas';
import Myapps from "./scenes/Apps";
import MyFreeApps from './scenes/FreeApps/MisAppsFree';
import MyCourses from './scenes/Courses';
import MyFreeCourses from './scenes/MisCursosFree';
import MyTasks from './scenes/MisTareas';
import NotFund from './scenes/NotFound';
import User from './scenes/User';
import PrivateStatistics from './scenes/YPrivateEstadisticas';
import GestionCursos from './scenes/YPrivateGestionCursos';
import GestionUsuarios from './scenes/YPrivateGestionUsuarios';
import GesionAplicaciones from './scenes/YPrivateGestionAplicaciones.js';
import Dashboard from "./scenes/dashboard";

import MyAccount from "./scenes/Account";
import MyEbooks from "./scenes/Ebooks";
import MyFreeEbooks from "./scenes/FreeEbooks";
import NotificationsCenter from "./scenes/Notifications";
import TemplateCourses from "./scenes/Courses/TemplateCourses";
import MyappConstructor from "./scenes/Apps/Constructor";
import FreeAppS01 from "./scenes/FreeApps/FreeAppS01";
import FreeEbookS01 from "./scenes/FreeEbooks/FreeEbookS01";
import ScrollToTop from "./components/ScrollTop";


import AppComponent from "./components/freeapps/appejemplo";
import CommentsComponent from "./components/VideoComments";
import CourseComponent from "./scenes/Courses/CourseComponent";
import SplitComponentConstructor from "./scenes/AppConstructor/constructor";


import AnalysisWindow from "./scenes/analysis";
import MuroVoladizoNsr10 from "./scenesApps/MuroVoladizoNsr10";

function PrivateRoutes({ onLogout,usernameoficial,plans2 }) {
  const [theme,colorMode]=useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const usernameoficial2=usernameoficial

  
  {/**Cada curso que se cree se debe poner aca la verficiacion de acceso* */}
  const cursoslocales1=localStorage.getItem("pruebacursos")  
  const cursoslocales2=JSON.parse(cursoslocales1)
  console.log(cursoslocales2)
  console.log("Prueba verificacion");
  const existeS01 = cursoslocales2.some(objeto => objeto.code === "s01");
  const existeS02 = cursoslocales2.some(objeto => objeto.code === "s02");
  const existeS03 = cursoslocales2.some(objeto => objeto.code === "s03");
  const existeS04 = cursoslocales2.some(objeto => objeto.code === "s04");
  const existeS05 = cursoslocales2.some(objeto => objeto.code === "s05");
  {/**Cada curso que se cree se debe poner aca la verficiacion de acceso* */}
  console.log(existeS01)
  console.log(existeS02)
  console.log(existeS03)
  console.log(existeS04)
  console.log(existeS05)



  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">  
              
          <Sidebar isSidebar={isSidebar} usernamesidebar={usernameoficial2}  />
          <main className="content">          
            <Topbar setIsSidebar={setIsSidebar} onLogout={onLogout} />   
            <ScrollToTop></ScrollToTop>    
            <Routes>   
              {/*provisional routes*/}      
              <Route path='/pruebas123100' element={<SplitComponentConstructor/>}/>  
              <Route path='/pruebasapp' element={<MuroVoladizoNsr10/>}/>
              <Route path='/dashboard' element={<Dashboard/>}/>               
              <Route path="/team" element={<Team />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />              
              <Route path="/geography" element={<Geography />} /> 
              {/*principal private routes*/}      
              <Route path='/myprograms' element={<MyPrograms/>}/>  
              <Route path='/myprojects' element={<MyProjects/>}/>  
              <Route path='/mysheets' element={<MySheets/>}/>  
              <Route path='/newsheet' element={<NewSheet/>}/>  
              <Route path='/myenterprise' element={<Enterprise/>}/>  
              <Route path='/' element={<HomePage/>}/>  
              <Route path='/marketplace' element={<MarketPlace/>}/>  
              <Route path='/marketplaceapps' element={<MarketPlace/>}/>  
              <Route path='/notebook' element={<MyNoteBook/>}/>  
              <Route path='/myapps' element={<Myapps/>}/>  
              <Route path='/freeapps' element={<MyFreeApps/>}/>  
              <Route path='/courses' element={<MyCourses  plans3={plans2} />}/> 
              <Route path='/freecourses' element={<MyFreeCourses/>}/>                
              <Route path='/mytasks' element={<MyTasks/>}/>  
              <Route path='/user' element={<User/>}/>
              <Route path='/administration' element={<MyAccount/>}/>
              <Route path='/myebooks' element={<MyEbooks/>}/>
              <Route path='/freebooks' element={<MyFreeEbooks/>}/>
              <Route path='/notifications' element={<NotificationsCenter/>}/>

              {/*Free Courses routes*/}  
              <Route path='/freeapps/s01' element={<AppComponent/>}/>
              <Route path='/freeapps/s02' element={<FreeAppS01/>}/>
              <Route path='/freeapps/s03' element={<FreeAppS01/>}/>
              <Route path='/freeapps/s04' element={<FreeAppS01/>}/>
              <Route path='/freeapps/s05' element={<FreeAppS01/>}/>
              <Route path='/freeapps/s06' element={<FreeAppS01/>}/>

              {/*Free Ebooks routes*/}  
              <Route path='freebooks/s01' element={<FreeEbookS01/>}/>
              <Route path='freebooks/s02' element={<FreeEbookS01/>}/>
              <Route path='freebooks/s03' element={<FreeEbookS01/>}/>
              <Route path='freebooks/s04' element={<FreeEbookS01/>}/>
              <Route path='freebooks/s05' element={<FreeEbookS01/>}/>
              <Route path='freebooks/s06' element={<FreeEbookS01/>}/>
              
              {/*Main Courses routes*/}  
              <Route path='/courses/s01' element={<CourseComponent access={existeS01} course={"s01"} descriptioncourse={"Diseño estructural Puentes de acero en Vigas I y Cajon"}/>}/>
              <Route path='/courses/s02' element={<CourseComponent access={existeS02} course={"s02"} descriptioncourse={"Modelación y diseño de Edificios en Muros de concreto"}/>}/>
              <Route path='/courses/s03' element={<CourseComponent access={existeS03} course={"s03"} descriptioncourse={"Fundamentos para diseño estructural"}/>}/>
              <Route path='/courses/s04' element={<CourseComponent access={existeS04} course={"s04"} descriptioncourse={"Avaluó de cargas para edificaciones"}/>}/>
              

              {/*Free Courses routes*/}  
              <Route path='/courses/cf01' element={<TemplateCourses/>}/>
              <Route path='/courses/cf02' element={<TemplateCourses/>}/>
              <Route path='/courses/cf03' element={<TemplateCourses/>}/>
              <Route path='/courses/cf04' element={<TemplateCourses/>}/>
              <Route path='/courses/cf05' element={<TemplateCourses/>}/>
              
              
              {/*Courses private routes*/}  
              <Route path='/curso' element={<TemplateCourses/>}/>  
              {/*Apps private routes*/}  
              <Route path='/constructor' element={<MyappConstructor/>}/>  
              {/*Super private routes*/}      
              <Route path='/estadisticas' element={<PrivateStatistics/>}/>  
              <Route path='/gestioncursos' element={<GestionCursos/>}/>  
              <Route path='/gestionusuarios' element={<GestionUsuarios/>}/>  
              <Route path='/gestionaplicaciones' element={<GesionAplicaciones/>}/>   
              <Route path='*' element={<NotFund/>}/>           
              <Route path='/thankyoupage' element={<ThankYouPage/>}/> 
              
              <Route path='/analisis' element={<AnalysisWindow/>}/>      
     
            </Routes>
          </main>
         
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default PrivateRoutes;
