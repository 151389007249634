import React from 'react';
import { Grid, Card, CardContent, CardActions, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const CoursesCardLanding = ({ cardDataList }) => {
  const navigate = useNavigate();

  const handleButtonClick = (to) => {
    navigate(`/courses/${to}`);
  };
  const CardContainer = styled(Card)(({ theme, gradientColors, bordercolor }) => {
    const gradient = gradientColors.join(', '); // Genera la cadena de gradiente
  
    return {
      maxWidth: 400,
      margin: '0 auto',
      background: `linear-gradient(to right, ${gradient})`, // Utiliza la cadena de gradiente generada
      borderRadius: 10,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)',
      borderColor: bordercolor,
      borderWidth: '3px',
      borderStyle: 'solid',
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 300,
      },
    };
  });

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 12,
    letterSpacing: 1,
  }));

  const Image = styled('img')(({ theme }) => ({
    width: '32%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: '10px 10px 0 0',
    marginTop: '20px',
  
    [theme.breakpoints.down('sm')]: {
      width: '32%',
      height: 'auto',
      borderRadius: '10px',
      marginTop: '15px',
    },
  }));

  const ImageContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  });

  const Actions = styled(CardActions)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  }));

  const ButtonStyled = styled(Button)(({ theme }) => ({
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 1,
  }));
 
  return (
    <Grid container spacing={2}>
      {cardDataList.map((cardData, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <CardContainer gradientColors={cardData.gradientColors} bordercolor={cardData.bordercolor}>
            <ImageContainer>
              <Image src={cardData.imageUrl} alt="Card" />
            </ImageContainer>
            <CardContent>
              <Title color={cardData.colortext} variant="h2" component="div">
                {cardData.title}
              </Title>
              <Typography variant="body1" component="div">
                {cardData.description}
              </Typography>
            </CardContent>
            <Actions>
              {/*
              <ButtonStyled onClick={() => handleButtonClick(cardData.to)}variant="contained" style={{ backgroundColor: '#fff', color: '#141b2b' }}>
                Detalles
              </ButtonStyled>
              */}
              <ButtonStyled onClick={() => handleButtonClick(cardData.to)}variant="contained" style={{ backgroundColor: '#fff', color: '#141b2b' }}>
                Ver Curso
              </ButtonStyled>
            </Actions>
          </CardContainer>
        </Grid>
      ))}
    </Grid>
  );
};

export default CoursesCardLanding;

