import {Paper,Stack,TextField,Button,Box, Select,MenuItem,FormControl,InputLabel,CircularProgress,Grid } from "@mui/material";


import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from "react";
import { useNavigate,Link } from "react-router-dom";
import { useEffect} from "react";
import CardFreeApps from "../../components/CardT1/CardT3";
function FreeApp() {
    const cardData = [
      {
        title: 'Flexion Viga Tee',
        description: 'Calculo a flexion de viga Tee de concreto reforzado',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba.PNG',
        to:'s01'
      },
      {
        title: 'Flexion Viga Rectangular',
        description: 'Calculo a flexion de viga Tee de concreto reforzado',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba.PNG',
        to:'s02'
      },
      {
        title: 'Cortante Viga',
        description: 'Calculo a cortante de viga rectangular o tee en concreto reforzado',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba.PNG',
        to:'s03'
      },
      {
        title: 'Soldadura Filete',
        description: 'Calculo soldadura de filete',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba.PNG',
        to:'s04'
      },
      {
        title: 'Calculo perfiles de acero Cuadrados',
        description: 'Calculo capacidad a flexion y corte perfiles de acero cuadrado',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba.PNG',
        to:'s05'
      },
      {
        title: 'Flexion Viga Tee',
        description: 'Calculo a flexion de viga Tee de concreto reforzado',
        imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba.PNG',
        to:'s06'
      },      
    ];
    
    return (
      <Box sx={{marginTop:10,marginLeft:5,marginRight:5, marginBottom:5}}>

      <Grid container spacing={2} justifyContent="center">
      {cardData.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <CardFreeApps
            title={card.title}
            description={card.description}
            imageUrl={card.imageUrl}
            to={card.to}
          />
        </Grid>
      ))}
      </Grid>
    
        

      </Box>

    )
  }
  export default FreeApp