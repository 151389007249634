import React from 'react';
import { Box, Typography, Button, Grid,ListItem ,ListItemIcon,Stack} from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery'
import { tokens } from "../../theme";
import { useTheme } from '@emotion/react';
import SlideUpText from '../../components/TextAnimation1';
import CheckIcon from '@mui/icons-material/Check';
import CardCustom from '../../components/CardT1/CardT5';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeLandingCard from '../../components/CardT1/CardT6';
import HomeLandingCard2 from '../../components/CardT1/CardT7';
import SingleImage from '../../components/images/imagen1';
import HomeLandingCard3 from '../../components/CardT1/CardT8';

import FacebookButton from '../../components/Facebook/FabookDir';
import InstagramButton from '../../components/Instagram/Instagram';
import FooterLanding from './FooterLandin';
import PricesCourses2 from './prices2';
import PricesCourses1 from './prices1';
import WhatsAppButtonComponent from '../../components/whatsapp/WhatsappButton';


const MainPageSells = () => {
    const theme = useTheme();
    const isMobile2 = useMediaQuery('(max-width: 720px)');
    const colors = tokens(theme.palette.mode);

 
  return (
    <div>
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #141b2d 50%, #232c41)',
        paddingTop: '105px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } }}>
         
          <SlideUpText text="Conoce nuestros Planes!" variant="h1" color="white" marginBottom="20px" />
          <SlideUpText text="Pensados especialmente para ti" variant="h3" color={colors.blueAccent[400]} marginBottom="20px" />
          <SlideUpText text="" variant="h1" color="white" marginBottom="20px" />
          <SlideUpText text="" variant="h1" color="white" marginBottom="20px" />
          
        </Grid>
        
       
      </Grid>
    </Box>


    <Box
     
      sx={{
        backgroundImage: 'linear-gradient(to top, #232c41 30%, #232c41)',
        textAlign: { xs: 'center', sm: 'center' },
                
      }}
    >
      <SlideUpText text="Tres Opciones:" variant="h1" color="white" marginBottom="20px" />
      <PricesCourses1></PricesCourses1>      
    </Box>

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #232c41 50%, #3e4396)',
        paddingTop: '80px',
        paddingBottom: '80px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } }}>
         
          <SlideUpText text="Registrarme y Comprar Aqui!" variant="h1" color="white" marginBottom="20px" />
          
          <Button variant="contained" color="secondary" component={Link} to="/register"
          sx={{ fontSize: '1rem',border: 'none',fontWeight: isMobile2 ? 'normal' : '600' }}
          >
            REGISTRAME Y  COMPRAR
          </Button>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>      
            <WhatsAppButtonComponent />
          </div>

      
          
        </Grid>
        
       
      </Grid>
    </Box>

   
    
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #3e4396)',
        paddingTop: '40px',
        paddingBottom: '60px',
        textAlign: { xs: 'center', sm: 'center', md: 'center' },
        width: '100%',
      }}
      >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 1 } }}>
          <SlideUpText text="Medios de Pago Aceptados" variant="h1" color='white' marginBottom="20px" />          
          <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/epayco.PNG' boxHeight='45vh' ></SingleImage>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }}>
       
          <Box display="flex" justifyContent="center">   
          <Grid container direction="column" alignItems="center">
          <SlideUpText text="Para Pagos Fuera de Colombia con Tarjeta de credito, Western Union, PayPal o Contáctanos" variant="h2" color='white' marginBottom="20px" />          
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>      
            <WhatsAppButtonComponent />
          </div>          
          <Button variant="contained" color="secondary" component={Link} to="/register"
          sx={{ fontSize: '1.5rem',border: 'none',fontWeight: isMobile2 ? 'normal' : '600' }}
          >
            REGISTRARME Y COMPRAR
          </Button>
        
          </Grid>  

          
          </Box>
          
        </Grid>
      </Grid>
    </Box>



   

    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #141b2d)',
        paddingTop: '5px',
        paddingBottom: '5px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } }}>
        <Box display="flex" flexDirection="column" alignItems="center">
 
    </Box>
        
        
        </Grid>
           
      </Grid>
    </Box>

    

    <FooterLanding></FooterLanding>




    </div>
   
    
    
  );
};

export default MainPageSells;
