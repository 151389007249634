import SlideUpText from "../../components/TextAnimation1";
import { Box, Typography, Button, Grid,ListItem ,ListItemIcon,Stack} from '@mui/material';

function FooterLanding() {
    return (
        <Box
        sx={{
          backgroundImage: 'linear-gradient(to top, #141b2d 30%, #141b2d)',
          paddingTop: '10px',
          paddingBottom: '20px',
          textAlign: { xs: 'center', sm: 'center' },
          width: '100%',
          
        }}
      >
        <Grid container spacing={1}>
          
          <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } }}>
          <SlideUpText text="" variant="h5" color='white' marginBottom="20px" />    
          <SlideUpText text="Contáctanos: admincursos@proexingenieria.com" variant="h5" color='white' marginBottom="20px" />    
          <SlideUpText text="WhatsApp: +57 3005584478/ +57 3107340158" variant="h5" color='white' marginBottom="20px" />    
          <SlideUpText text="Proex Academy by Proex Ingenieria SAS-Todos los derechos reservados-2024" variant="h5" color='white' marginBottom="20px" />    
          
          </Grid>
             
        </Grid>
      </Box>

    );
  }
  export default FooterLanding