import React, { useState } from 'react';
import {
  
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@mui/material';

import { Box} from '@mui/material';
import { PlayArrow } from '@mui/icons-material';

const videos = [
    {
      id: 1,
      title: 'Diseño Estructural Puentes en vigas de acero tipo I y tipo cajon',
      image: 'https://academy-public-resources.s3.amazonaws.com/publics/iconos-cursos/icono_pueentesi.svg',
      videoUrl: '846341732',
      description: 'Este es un programa donde se enseñan los conocimientos necesarios para diseñar las vigas tipo I y tipo cajón de acero en puentes vehiculares; En luces simples, continuas, rectas y curvas de acuerdo a los códigos de diseño estructural AASHTO LRFD & CCP-14',
      accordionContent: [
        {
          title: 'Caracteristicas Curso',
          content: 'Son 30 horas de clase, Uso de Software CSI Bridge y Sap-2000,  Ejercicios practicos a lo largo de todo el curso. Se eneseña paso a paso a modelas y calcular puentes en vigas de acero',
        },
        {
          title: 'Modulo 1 Introducción - 2 Horas 49min- Son 5 Clases',
          content: 'Objetivo: Acercar al estudiante a tipos de puentes en acero, aplicabilidad de puentes de placa y viga y codigos de diseño CCP-14 Y AASHTO LRFD',     
        },
        {
          title: 'Modulo 2 Avaluó Cargas y diseño de tablero- 5 Horas 5min- Son 8 Clases',
          content: 'Objetivo: El estudiante aprenda a realizar avaluós de cargas para puentes, analice y diseño un tablero en concreto reforzado',     
        },
        {
          title: 'Modulo 3 Diseño de barrera- 1 Horas 32min- Son 4 Clases',
          content: 'Objetivo: El estudiante aprenda a analizar y diseñar barrerras concreto reforzado',     
        },
        {
          title: 'Modulo 4 Comportamiento Vigas acero puentes y diseño vigas tipo I- 12 Horas 53min- Son 17 Clases',
          content: 'Objetivo: El estudiante aprenda modelar un puente curvo y continuo en Csi Bridge. El estudiante aprenda todo lo necesario para diseñar completamente un puente de vigas de acero tipo I',     
        },
        {
          title: 'Modulo 5 Diseño vigas cajón  de acero- 3 Horas 55min- Son 8 Clases',
          content: 'Objetivo: El estudiante aprenda todo lo necesario para diseñar completamente un puente de vigas de acero tipo Cajón, incluyendo por torsion',     
        },
        {
          title: 'Modulo 6 Diseño barandas peatonales - 1 hora 13 min - Son 4 clases',
          content: 'Objetivo: El estudiante aprenda todo lo necesario para realizar analisis y diseño estructural de barandas peatonales',     
        },
        {
          title: 'Modulo 7 Diseño conexiones, arriostrados y diafragmas - 1 hora 36 min - Son 4 clases',
          content: 'Objetivo: El estudiante aprenda a diseñar conexiones soldadas y diseño de elementos a tension y compresion para los arriostrados y diafragmas',     
        },
        
      ],
      backgroundColor: '#141b2b', // Color de fondo de la tarjeta
      textColor: '#9de781', // Color del texto de la tarjeta
      borderColor: '#9de781', // Color del borde de la tarjeta
      borderWidth: '3px', // Grosor del borde de la tarjeta
    },
    {
      id: 2,
      title: 'Fundamentos para diseño estructural de edificaciones',
      image: 'https://academy-public-resources.s3.amazonaws.com/publics/iconos-cursos/icono-fundamentos-edficiaciones.svg',
      videoUrl: '846394469',
      description: 'Este es un curso fundamental para que aprendas y fortalezcas todos los conocimientos necesarios para introducirte en el diseño estructural de edificaciones Aprenderás los principales materiales y sistemas estructurales usados en la construcción, su comportamiento y una aproximación conceptual hacia el diseño estructural',
      accordionContent: [
        {
          title: 'Caracteristicas Curso',
          content: 'Son 4 horas y 25min de clase, Curso conceptual',
        },
        {
          title: 'Modulo 1 - Introducción ',
          content: 'Presentación general del curso',
        },
        {
          title: 'Modulo 2 Estudios y diseños en edificaciones- 45min -Son 3 clases',
          content: 'Objetivo: El estudiante aprenda genralidades de estudios arquitectonicos, geotecicos y de redes',
        },
        {
          title: 'Modulo 3 Materiales de edificaciones- 54min -Son 7 clases',
          content: 'Objetivo: El estudiante aprenda caracteristicas principales de amaposteria, concreto acero estrucutural y otros materiales',
        },
        {
          title: 'Modulo 4 Elementos estructurales de edificios- 1 hora 31min -Son 10 clases',
          content: 'Objetivo: El estudiante aprenda de los principales sistemas estructurales en edificios como tipos de entrepisos, muros de mamposteria, porticos de concreto reforzado, sistema combinado en concreto, sistema de muros en concreto, porticos de acero, sistemas arriostrado de acero, sistemas de contencion y cimentaciones',
        },
        {
          title: 'Modulo 5 Especificaciones generales y normativa- 32min -Son 3 clases',
          content: 'Objetivo: El estudiante aprenda las generalidades sobre las normativas de diseño estructura en acero y concreto',
        },
        {
          title: 'Modulo 6 Comportamiento simplificado de elementos- 24min -Son 5 clases',
          content: 'Objetivo: El estudiante aprenda las bases de comportamiento mecanido de lsoas, vigas, columnas muros, riostras y elmentos de contención ',
        },
        {
          title: 'Modulo 7 Proceso general de diseño- 14min -Son 2 clases',
          content: 'Objetivo: El estudiante aprenda la importancia del proceso contructivo y el procedimiento general de diseño',
        },

      ],
      backgroundColor: '#141b2b', // Color de fondo de la tarjeta
      textColor: '#4c92dd', // Color del texto de la tarjeta
      borderColor: '#4c92dd', // Color del borde de la tarjeta
      borderWidth: '3px', // Grosor del borde de la tarjeta
    },
    {
        id: 3,
        title: 'Modelación y diseño estructural edificaciones en muros de concreto con Etabs',
        image: 'https://academy-public-resources.s3.amazonaws.com/publics/iconos-cursos/icocono_edificiosmuros1.svg',
        videoUrl: '846380617',
        description: 'Este es un programa donde se enseñan los conocimientos necesarios modelar y diseñar Edificios en muros de concreto con el Sotware Etabs con Normativa NSR-10',
        accordionContent: [
          {
            title: 'Caracteristicas Curso',
            content: 'Son 8 horas y 11min de clase, Curso Aplicativo, conceptos y ejercicios uso Normativas NSR-10 & ACI 318 ',
          },
          {
            title: 'Modulo 1  Introducción - 42min -Son 4 clases',
            content: 'Objetivo: El estudiante aprenda acerca del predimensionamiento y avaluo de cargas para un edificio de muros de concreto reforzado',
          },
          {
            title: 'Modulo 2 Entendiendo el software ETABS- 44min -Son 10 clases',
            content: 'Objetivo: El estudiante aprenda sobre los diferentes elementos del Etabs tales como grados de libertas, ejes. apoyos, diafragmas, wall sections, slab sections, frames, piers labels y springs.',
          },
          {
            title: 'Modulo 3 Modelación en software ETABS- 2 horas 15min -Son 5 clases',
            content: 'Objetivo: El estudiante aprenda a modelar completamente un edificio en Etabs, con las conbinaciones de diseño NSR-10 y ajustes por fuerza horizontal equivalente',
          },
          {
            title: 'Modulo 4 Análisis de resultados- 1 hora 6min -Son 3 clases',
            content: 'Objetivo: El estudiante aprenda a interpretar los resultados de desplazameintos, reacciones y solicitaciones en el software Etabs',
          },
          {
            title: 'Modulo 5 Diseño estructural de Muros - 2 horas 5min -Son 4 clases',
            content: 'Objetivo: El estudiante aprenda a realizar diseños estructurales de los muros con y sin elemento del borde, con el sofware y manualmente',
          },
          {
            title: 'Modulo 6 Diseño de la placa de entrepiso- 42min -Son 4 clases',
            content: 'Objetivo: El estudiante aprenda a realizar el diseño estructura de la placa de entrepiso y vigas dinteles del edificio de muros',
          },
          {
            title: 'Modulo 7 Modelación de la cimentación- 30min -Son 2 clases',
            content: 'Objetivo: El estudiante aprenda a modelar la cimentación en el edificio de muros',
          }
        ],
        backgroundColor: '#141b2b', // Color de fondo de la tarjeta
        textColor: '#4c92dd', // Color del texto de la tarjeta
        borderColor: '#4c92dd', // Color del borde de la tarjeta
        borderWidth: '3px', // Grosor del borde de la tarjeta
      },
      {
        id: 4,
        title: 'Avaluó de cargas para edificaciones con ASCE-7 y NSR-10',
        image: 'https://academy-public-resources.s3.amazonaws.com/publics/iconos-cursos/icocono_avaluo_cargas.svg',
        videoUrl: '846404956',
        description: 'Este es un curso para que aprendas las herramientas necesarias para realizar avalúos de cargas en diversas tipo de estructuras usadas en la construcción moderna de edificios El curso está basado de acuerdo con la norma estadounidense ASCE-7 y la NSR-10, al finalizar el curso podrás realizar avalúos de cargas en proyectos de diseño estructural de edificios',
        accordionContent: [
          {
            title: 'Caracteristicas Curso',
            content: 'Son 4 horas y 25min de clase, Curso Aplicativo, conceptos y ejercicios uso Normativas ASCE-7 y NSR-10',
          },
          {
            title: 'Modulo 1  Introducción - 23min -Son 3 clases',
            content: 'Objetivo: El estudiante aprenda acerca de tipos de estructuras existente',
          },
          {
            title: 'Modulo 2 Normatividad ASCE7 & NSR-10 - 47min -Son 3 clases',
            content: 'Objetivo: El estudiante reconosca la normativa ASCE-7 su relacion con NRS-10 ',
          },
          {
            title: 'Modulo 3 Tipos de cargas - 25min -Son 4 clases',
            content: 'Objetivo: El estudiante aprenda a realizar cargas pemanentes, transitorias, empujes, vehiculares y otras cargas',
          },
          {
            title: 'Modulo 4 Cargas en edificaciones - 57min -Son 3 clases',
            content: 'Objetivo: El estudiante aprenda a realizar avaluós de cargas en losas, escaleras, cubiertas y muros de contencion para edificaciones',
          },
          {
            title: 'Modulo 5 Estructuras asimilables a edificaciones- 1 hora- Son 4 clases',
            content: 'Objetivo: El estudiante aprenda a realizar avaluós de cargas en racks de almacenamiento, racks de tuberias, estructuras industriales y naves industriales',
          },
          {
            title: 'Modulo 6 Estructuras diferentes a edificaciones - 12min -Son 2 clases',
            content: 'Objetivo: El estudiante aprenda a realizar avaluós de cargas en torres, chimenas, torres de energia y telecomunicaciones ',
          },          
          {
            title: 'Modulo 7 Modelación de la cimentación- 17min -Son 2 clases',
            content: 'Objetivo: El estudiante aprenda a realizar avaluós de carga para tanques de agua enterrados y superficiales',
          },
          {
            title: 'Modulo 8 Cargas en puentes- 17min -Son 3 clases',
            content: 'Objetivo: El estudiante aprenda realizar avaluós para puentes vehiculares y peatonales',
          }
        ],
        backgroundColor: '#141b2b', // Color de fondo de la tarjeta
        textColor: '#4c92dd', // Color del texto de la tarjeta
        borderColor: '#4c92dd', // Color del borde de la tarjeta
        borderWidth: '3px', // Grosor del borde de la tarjeta
      },

      {
        id: 4,
        title: 'Avaluó de cargas para edificaciones con ASCE-7 y NSR-10',
        image: 'https://academy-public-resources.s3.amazonaws.com/publics/logocoursefour.png',
        videoUrl: '963699922',
        description: 'Este es un curso para que aprendas a desarrollar aplicaciones web con python y react js usando chat gpt para programar. Te enseñamos desde los básico hasta que puedas desplegar en la web una aplicación completamente funcional.',
        accordionContent: [
          {
            title: 'Caracteristicas Curso',
            content: 'Son 15 horas de clases pregrabadas y 6 horas de talleres virtuales on-line, Curso  completamente aplicativo y te entregamos plantilla base para que empieces a desarrollar tus proyectos',
          },
          {
            title: 'Modulo 1  Bases de programación - 5 horas 10 min -Son 12 clases',
            content: 'Objetivo: El estudiante aprenda conceptos basicos de programacion en python, javascript, html y react js. Tambien que el estudiante aprenda a usar chat gpt para programar y desplegar la aplicación ',
          },
          {
            title: 'Modulo 2 Creacion Interfaz  2D, 3D , graficos y planos DXF',
            content: 'Objetivo: Objetivo: El estudiante aprenda las bases para realizar interfaces graficas en 2D, 3D, creación de gráficos de representación de información y creación de archivos dxf con python',
          },
          {
            title: 'Modulo 3 Modulo 3 Programación de App diseño vigas de acero en sección compuesta',
            content: 'Objetivo: Aplicación de conocimientos para programar una aplicación de cálculo estructural que imprime resultados en PDF',
          },
          {
            title: 'Modulo 4 Programación de App diseño de Zapatas',
            content: 'Objetivo: Aplicación de conocimientos para programar una aplicación de cálculo estructural que recibe gran cantidad de datos desde archivos de excel, calcula e imprime resultados en PDF',
          },          
        ],
        backgroundColor: '#141b2b', // Color de fondo de la tarjeta
        textColor: '#4c92dd', // Color del texto de la tarjeta
        borderColor: '#D52A33', // Color del borde de la tarjeta
        borderWidth: '3px', // Grosor del borde de la tarjeta
      },
  ];
  

const VideoCard = ({ video, onPlayClick }) => {
  const { backgroundColor, textColor, borderColor, borderWidth } = video;

  const playButtonStyle = {
    fontSize: '4rem', // Tamaño del ícono de reproducción
  };

  const cardStyle = {
    borderRadius: '8px',
    backgroundColor: backgroundColor,
    border: `${borderWidth} solid ${borderColor}`, // Establecer color y grosor del borde
  };

  const textStyle = {
    color: textColor,
  };

  return (
    <Card sx={cardStyle}>
      <CardActionArea onClick={onPlayClick}>
        <CardMedia
             component="img"
             height="200"
             width="80%"
             image={video.image}
             alt={video.title}
             style={{ objectFit: 'contain' }}
        />
        <CardContent>
          <Typography variant="h5" sx={textStyle}>
            {video.title}
          </Typography>
        </CardContent>
      </CardActionArea>
      <Button size="large" color="secondary" startIcon={<PlayArrow sx={playButtonStyle} />} onClick={onPlayClick}>
        Play
      </Button>
    </Card>
  );
};

const VideoPopup = ({ open, onClose, video }) => {
    const dialogStyle = {
      backgroundColor: '#3d4453', // Cambiar el color de fondo del popup
    };
  
    const dialogTitleStyle = {
      backgroundColor: '#3d4453', // Cambiar el color de fondo de la cabecera del popup
      color: 'white', // Cambiar el color del texto de la cabecera del popup
    };
  
    const accordionSummaryStyle = {
      backgroundColor: '#3e4396', // Cambiar el color de fondo de los encabezados de los acordeones
      color: 'white', // Cambiar el color del texto de los encabezados de los acordeones
    };
  
    const accordionDetailsStyle = {
      backgroundColor: '#3e4396', // Cambiar el color de fondo del contenido de los acordeones
    };
  
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth BackdropProps={{ invisible: true }}>
        <Box mt={5} mb={1}>
        <DialogTitle sx={dialogTitleStyle}>{video.title}</DialogTitle>
        </Box>
        <DialogContent sx={dialogStyle}>
          <iframe
            src={`https://player.vimeo.com/video/${video.videoUrl}?h=1cc3c46f24&title=0&byline=0&portrait=0`}
            width="100%"
            height="400"
            title="Video Player"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <Box mt={0} mb={2}>
          <Typography variant="h5">Descripción</Typography>
          </Box>
          <Typography>{video.description}</Typography>
          <Box mt={3} mb={2}>
          <Typography variant="h5">Objetivos del curso</Typography>
          </Box>
          {video.accordionContent.map((accordion, index) => (
            <Accordion key={index}>
              <AccordionSummary sx={accordionSummaryStyle}>{accordion.title}</AccordionSummary>
              <AccordionDetails sx={accordionDetailsStyle}>
                <Typography>{accordion.content}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </DialogContent>
      </Dialog>
    );
  };
  
  

const VideoListSellsPopUp = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handlePlayClick = (video) => {
    setSelectedVideo(video);
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setSelectedVideo(null);
  };

  return (
    <Grid container spacing={2}>
      {videos.map((video) => (
        <Grid item xs={12} sm={6} md={4} key={video.id}>
          <VideoCard video={video} onPlayClick={() => handlePlayClick(video)} />
        </Grid>
      ))}
      {selectedVideo && (
        <VideoPopup open={openPopup} onClose={handleClosePopup} video={selectedVideo} />
      )}
    </Grid>
  );
};

export default VideoListSellsPopUp;
