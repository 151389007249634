import React, { createContext, useContext, useState, useEffect } from 'react';

// Crea el contexto
const CurrencyContext = createContext();

export const useCurrency = () => useContext(CurrencyContext);

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState('USD');

  useEffect(() => {
    // Determina la localización del usuario
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        if (data.country_name === 'Colombia') {
          setCurrency('COP');
        } else {
          setCurrency('USD');
        }
      })
      .catch(() => {
        setCurrency('USD'); // Fallback en caso de error
      });
  }, []);

  return (
    <CurrencyContext.Provider value={currency}>
      {children}
    </CurrencyContext.Provider>
  );
};

