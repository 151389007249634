import React from 'react';
import { Typography } from '@mui/material';
import { motion } from 'framer-motion';

const SlideUpText = ({ text, variant, color, marginBottom }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, delay: 0.2 }}
    >
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Typography variant={variant} sx={{ color, marginBottom }}>
          {text}
        </Typography>
      </motion.div>
    </motion.div>
  );
};

export default SlideUpText;
