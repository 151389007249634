import {Paper,Stack,TextField,Button,Box, Select,MenuItem,FormControl,InputLabel,CircularProgress,Grid } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from "react";
import { useNavigate,Link } from "react-router-dom";
import { useEffect} from "react";
import CardFreeApps from "../../components/CardT1/CardT3";
import EbookComponent from "../../components/Ebook/EbookComponent";
import { Latex } from 'react-latex';


function FreeEbookS01() {
  const sections = [
    {
      title: 'Ejemplo Diseño Estructural de una viga de concreto reforzado segun ACI-318-19 & NSR-10',
      description: 'by Ingeniero Howart Ivan Riaño Aranguren',
      image: 'https://academy-books.s3.amazonaws.com/freebooks/fb-01/TIPOSFALLASAFLEXION.png',
      content: [
        { type: 'titulo', text: 'Modo de Falla por tensión' },
        { type: 'parrafo', text: '' },
        { type: 'parrafo', text: 'Antes de comenzar a desarrollar el ejemplo se debe tener muy presente que de acuerdo con el capitulo 9.3.3.1 del ACI 318-19 las vigas de concreto reforzado que tienen una fuerza axial ultima actuante como Pu<=0.10*f’c*Ag deber ser diseñadas para que sea la tracción la que controle el modo de falla en la viga. Para esto se debe asegurar que la deformación unitaria en la zona a tracción et sea mayor a et>0.003+ey, donde ey es la deformación unitaria en el acero que para un acero refuerzo con esfuerzo de fluencia de 420MPa la deformación unitaria del acero es  ey=fy/Es=420MPa/200.000 MPa=0.0021.' },
        // Más elementos de contenido de la sección 1...
      ]
    },
    {
      title: 'Datos del ejercicio',
      description: 'A continuación se presentan los datos con los que se desarrollará el ejercicio de capacidad de una viga de concreto a flexión. El ejercicio no abarca el chequeo de deflexiones. Las ecuaciones utilizadas son aplicables para ACI 318-19 & NSR-10 en Sistema Internacional de unidades',
      content: [
        { type: 'titulo', text: 'Geometría' },
        { type: 'imagen', url: 'https://academy-books.s3.amazonaws.com/freebooks/fb-01/refbeam.png', text: 'Descripción de la imagen' },
        { type: 'listaNoNumerada', items: ['h=400mm Altura Viga ', 'b=300mm Base Viga ', 'recubrimiento mínimo a fleje=40mm [Tabla 20.5.1.3.1 ACI-318-19]', 'Distancia borde viga inferior a centroide de refuerzos inferiores  z=40mm(recubrimiento)+9.5mm(fleje#3)+(12.7mm/2)barra#4=55.85mm', 'Distancia borde viga superior a centroide de refuerzos superiores  zt=40mm(recubrimiento)+9.5mm(fleje#3)+(12.7mm/2)barra#4=55.85mm,',
          'd=h-z=344.15mm2 altura efectiva para momento positivo',
          'dtop=h-zt=344.15mm2 altura efectiva para momento negativo'
        ] },
        { type: 'titulo', text: 'Materiales' },
        { type: 'listaNoNumerada', items: ['fc=28MPa  Resistencia del concreto   ', 'fy=420MPa    Esfuerzo de fluencia del acero   ', 'Es=200000 MPa  Módulo de elasticidad del acero', 'Φ=0.90   [Tabla 21.2.2 ACI-318-19] '] },
        { type: 'titulo', text: 'Solicitaciones' },
        { type: 'listaNoNumerada', items: ['Mu_pos=60 kN-m  momento último positivo   ', 'Mu_neg=70 kN-m  momento último negativo'] },
        { type: 'parrafo', text: 'Nota: En el presente ejemplo se proporcionan la mayoría de parámetros para realizar el diseño de la viga, sin embargo en un ejercicio de trabajo real de diseño estructural el profesional se deberá enfrentar a decidir si cambia las dimensiones de la viga, la resistencia del concreto y la posición del centroide de los refuerzos para realizar los cálculos de aceros requeridos y posterior revisión del diseño final' }                
      ],
    },
    {
      title: 'Calculo As requerido',
      description: 'Calculo cuantia, As requerido y As dispuesto',
      content: [
        { type: 'subtitulo', text: 'Cuantia Acero Momento Positivo (refuerzo inferior)' },
        { type: 'formula', text: '\\rho = \\left(0.85 \\cdot \\left(\\frac{fc}{fy}\\right) \\cdot \\left(1 - \\sqrt{1 - \\left(\\frac{2 \\cdot Mu_pos}{0.85 \\cdot fc \\cdot \\phi \\cdot b \\cdot d^2}\\right)}\\right)\\right)' },
        { type: 'formula', text: '\\rho = \\left(0.85 \\cdot \\left(\\frac{28MPa}{420MPa}\\right) \\cdot \\left(1 - \\sqrt{1 - \\left(\\frac{2 \\cdot 60kNm}{0.85 \\cdot (28MPa*1000) \\cdot \\ 0.9 \\cdot (300mm/1000) \\cdot (344.15mm/1000)^2}\\right)}\\right)\\right)' },
        { type: 'formula', text: '\\rho_{\\text{pos}} = 0.0047' },
        {type: 'parrafo', text: 'Al momento de calcular la cuantia el punto crucial es disponer las variables en las mismas unidades para que las operaciones sean correctas. Por eso se multipllica fc*1000 para pasar a kN-m, y las dimensiones se dividen en 1000 para pasarlas a metros. Ademas se debe tener presente que si el momento es excesivamente grande para la viga en cuestion la ecuacion de la cuantia no tiene solucion y por lo tanto les dara un error o un numero imaginario.' },        
        { type: 'formula', text: 'A_{\\text{s-}{\\text{reqpos}}} = \\rho \\cdot b \\cdot d' },
        { type: 'formula', text: 'A_{\\text{s-}{\\text{reqpos}}} = 485\\,\\text{mm}^2' },
        { type: 'formula', text: 'A_{\\text{s-}{\\text{dispuesto-pos}}} = 516\\,\\text{mm}^2' },
        {type: 'parrafo', text: 'Se decide disponer cuatro barras #4 (4*129mm2=516mm2) las cuales cumplen el area requerida  para momento positivo (zona inferior de la viga)' },        
        {type: 'parrafo', text: '________________________________________________________________________' },        
        { type: 'subtitulo', text: 'Cuantia Acero Momento Positivo (refuerzo superior)' },
        { type: 'formula', text: '\\rho = \\left(0.85 \\cdot \\left(\\frac{fc}{fy}\\right) \\cdot \\left(1 - \\sqrt{1 - \\left(\\frac{2 \\cdot Mu_neg}{0.85 \\cdot fc \\cdot \\phi \\cdot b \\cdot dtop^2}\\right)}\\right)\\right)' },
        { type: 'formula', text: '\\rho = \\left(0.85 \\cdot \\left(\\frac{28MPa}{420MPa}\\right) \\cdot \\left(1 - \\sqrt{1 - \\left(\\frac{2 \\cdot 70kNm}{0.85 \\cdot (28MPa*1000) \\cdot \\ 0.9 \\cdot (300mm/1000) \\cdot (344.15mm/1000)^2}\\right)}\\right)\\right)' },
        { type: 'formula', text: '\\rho_{\\text{pos}} = 0.0055' },
        {type: 'parrafo', text: 'Al momento de calcular la cuantia el punto crucial es disponer las variables en las mismas unidades para que las operaciones sean correctas. Por eso se multipllica fc*1000 para pasar a kN-m, y las dimensiones se dividen en 1000 para pasarlas a metros. Notese que para momenti negativo se uso Mu_negativo y dtop. Ademas se debe tener presente que si el momento es excesivamente grande para la viga en cuestion la ecuacion de la cuantia no tiene solucion y por lo tanto les dara un error o un numero imaginario.' },        
        { type: 'formula', text: 'A_{\\text{s-}{\\text{reqpos}}} = \\rho \\cdot b \\cdot d' },
        { type: 'formula', text: 'A_{\\text{s-}{\\text{reqpos}}} = 568\\,\\text{mm}^2' },
        { type: 'formula', text: 'A_{\\text{s-}{\\text{dispuesto-neg}}} = 645\\,\\text{mm}^2' },
        {type: 'parrafo', text: 'Se decide disponer cinco barras #4 (5*129mm2=645mm2) las cuales cuemplen el area requerida  para momento negativo (zona superior de la viga)' },        
      ],
    },
    {
      title: 'Calculo Mn y fiMn',
      description: 'Calculo resistencia a flexion positiva y negativa de la viga',     
      
      content: [
        { type: 'imagen', url: 'https://academy-books.s3.amazonaws.com/freebooks/fb-01/main.png', text: 'Descripción de la imagen' },
        { type: 'imagen', url: 'https://academy-books.s3.amazonaws.com/freebooks/fb-01/main2.png', text: 'Descripción de la imagen' },     
        {type: 'parrafo', text: '-' },        
        {type: 'parrafo', text: '________________________________________________________________________' },        
        { type: 'titulo', text: 'Calculo Momento Nominal Positivo' },        
        { type: 'formula', text: '\\phi \\cdot Mn_pos = \\phi \\cdot (Asdispos \\cdot fy) \\cdot (d - \\frac{a}{2})' },
        { type: 'formula', text: 'a = \\frac{{A_sdispos \\cdot f_y}}{{0.85 \\cdot f_c \\cdot b}}' },
        { type: 'formula', text: 'a = \\ 30.35 mm' },
        { type: 'formula', text: '\\phi \\cdot Mn_pos = \\ 64.17 \\cdot (kN \\ m)> Mu=60(kN\\ m) \\therefore\\ check OK' },    
        {type: 'parrafo', text: '________________________________________________________________________' },        
        { type: 'titulo', text: 'Calculo Momento Nominal Negativo' },        
        { type: 'formula', text: '\\phi \\cdot Mn_neg= \\phi \\cdot (Asdisneg \\cdot fy) \\cdot (dtop - \\frac{a_n}{2})' },
        { type: 'formula', text: 'a_n = \\frac{{A_sdisneg \\cdot f_y}}{{0.85 \\cdot f_c \\cdot b}}' },
        { type: 'formula', text: 'a_n = \\ 37.94 mm' },
        { type: 'formula', text: '\\phi \\cdot Mn_neg = \\ 79.28 \\cdot (kN \\ m)> Mu=70(kN\\ m) \\therefore\\ check OK' },    
        
       
      ],
    },
    {
      title: 'Chequeo refuerzo minimo',
      //description: 'Calculo resistencia a flexion positiva y negativa de la viga',     
      
      content: [        
        { type: 'titulo', text: 'Chequeo Refuerzo Minimo positivo' },        
        { type: 'formula', text: 'As_{min1} = 0.25 \\left( \\frac{\\sqrt{f_c}}{f_y} \\right) b\\cdot d' },
        { type: 'formula', text: 'As_{min2} = \\left( \\frac{\\ 1.4 }{f_y} \\right) b\\cdot d' },
        { type: 'formula', text: 'As_{exepcion} = \\ 1.33\\cdot Mu_pos' },
        {type: 'parrafo', text: 'Si el area de acero dispuesto es mayor a 1.33As_requerido entonces no se requiere cumplir el area de refuerzo minimo, en caso contrario se debe disponer en la viga por lo menos el area de acero minimo [ver 9.6.1.1]' },        
        { type: 'formula', text: 'As_{min1} = 325mm^2' },
        { type: 'formula', text: 'As_{min2} = 344mm^2' },
        { type: 'formula', text: 'As_{exepcion} = 645mm2' },
        { type: 'formula', text: 'As_{dispuesto} = 516mm^2\\therefore check=ok, se cumple con refuerzo minimo' },
        {type: 'parrafo', text: '________________________________________________________________________' },   
        { type: 'titulo', text: 'Chequeo Refuerzo Minimo negativo' },        
        { type: 'formula', text: 'As_{min1} = 0.25 \\left( \\frac{\\sqrt{f_c}}{f_y} \\right) b\\cdot dtop' },
        { type: 'formula', text: 'As_{min2} = \\left( \\frac{\\ 1.4 }{f_y} \\right) b\\cdot dtop' },
        { type: 'formula', text: 'As_{exepcion} = \\ 1.33\\cdot Mu_neg' },
        {type: 'parrafo', text: 'Si el area de acero dispuesto es mayor a 1.33As_requerido entonces no se requiere cumplir el area de refuerzo minimo, en caso contrario se debe disponer en la viga por lo menos el area de acero minimo [ver 9.6.1.1]' },        
        { type: 'formula', text: 'As_{min1} = 325mm^2' },
        { type: 'formula', text: 'As_{min2} = 344mm^2' },
        { type: 'formula', text: 'As_{exepcion} = 755mm2' },
        { type: 'formula', text: 'As_{dispuesto} = 645mm^2\\therefore check=ok, se cumple con refuerzo minimo' },
      ],
    },
    {
      title: 'Chequeo deformación unitaria a traccion minima',
      //description: 'Calculo resistencia a flexion positiva y negativa de la viga',    
      
      content: [        
        { type: 'titulo', text: 'Chequeo deformacion unitaria Minima para momento positivo' },        
        {type: 'parrafo', text: 'Para el calculo de la deformacion unitaria en la viga es necesario calcular la distancia desde la fibra extrema a compresion hasta el eje Neutro para esto se utiliza la ecuacion a=B1*c. Donde B1 vale 0.85 para concretos de hasta 28MPa de resistencia, para concreto de mayor resitencia B1 disminuye a razon de 0.05 por cada 7 Mpa de aumento en el fc hasta llegar al valor de 0.65 donde permanera constante' },        
        { type: 'formula', text: 'a = \\beta_1 \\cdot c' },
        {type: 'parrafo', text: 'donde a es la altura del bloque de compresiones calculado durante el diseño y se require despejar c  que es la distancia desde la fibra extrema de compresion hasta el eje neutro de la viga' },        
        { type: 'formula', text: 'c = \\ 35.706 \\cdot mm' },
        {type: 'parrafo', text: 'Habiendo calculado el valor de c, se procede a calcular la deformacion unitaria de la zona entraccion:' },        
        { type: 'formula', text: '\\varepsilon_{tminimo} = \\ 0.005 - acorde con ACI 318-19'},
        { type: 'formula', text: '\\varepsilon_c = \\ 0.003 - acorde con ACI 318-19' },
        { type: 'formula', text: '\\varepsilon_{tcal} = \\varepsilon_c \\left(\\frac{d-c}{c}\\right)' },                
        { type: 'formula', text: '\\varepsilon_{tcal} = \\ 0.0259>0.005\\therefore check=OK' },
        {type: 'parrafo', text: '________________________________________________________________________' },   
        { type: 'titulo', text: 'Chequeo deformacion unitaria Minima para momento negativo' },        
        { type: 'formula', text: 'a_n = \\beta_1 \\cdot c_n' },
        { type: 'formula', text: 'c_n = \\ 44.635 \\cdot mm' },
        { type: 'formula', text: '\\varepsilon_{tcal_n} = \\varepsilon_c \\left(\\frac{dtop-c_n}{c_n}\\right)' },                
        { type: 'formula', text: '\\varepsilon_{tcal} = \\ 0.0201>0.005\\therefore check=OK' },
        { type: 'titulo', text: 'FIN EJERCICIO' },        
       
      ],
    },
  ];
  
  <EbookComponent sections={sections} />;
  





  const ejemploformato = [
      {
        title: 'Ejemplo Diseño Estructural de una viga de concreto reforzado',
        description: 'by Ingeniero Howart Ivan Riaño Aranguren',
        image: 'https://i.blogs.es/ceda9c/dalle/1366_2000.jpg',
        content: [
          { type: 'titulo', text: 'Título de la sección 1' },
          { type: 'parrafo', text: 'Este es un párrafo de la sección 1.' },
          { type: 'parrafo', text: 'Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum.' },              
          { type: 'parrafo', text: 'Otra frase de la sección 1.' },
          { type: 'formula', text: '\\int_{-\\infty}^\\infty \\exp(-x^2) dx = \\sqrt{\\pi}' },
          { type: 'formula', text: '\\int_{-\\infty}^\\infty \\exp(-x^2) dx = \\sqrt{\\pi}' },
          // Más elementos de contenido de la sección 1...
        ]
      },
      {
        title: 'Sección 2-Primer Capitulo',
        description: 'Esta es la descripción de la sección 1',
        content: [
          { type: 'titulo', text: 'Título de la sección 1' },
          { type: 'parrafo', text: 'Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum.' },       
          { type: 'parrafo', text: 'Otra frase de la sección 1.' },
          // Más elementos de contenido de la sección 1...
        ]
      },
      {
        title: 'Sección 3',
        description: 'Esta es la descripción de la sección 1',
        content: [
          { type: 'subtitulo', text: 'Subtítulo de la sección 2' },
          { type: 'parrafo', text: 'Este es un párrafo de la sección 2.' },        
          // Más elementos de contenido de la sección 2...
        ]
      },
      // Más secciones...
    ];

        
    return (
      <Box sx={{marginTop:10,marginLeft:0,marginRight:0, marginBottom:5}}>              
        <EbookComponent sections={sections} />
      </Box>

    )
  }
  export default FreeEbookS01