import {Paper,Stack,TextField,Button,Box, Select,MenuItem,FormControl,InputLabel,CircularProgress,Grid } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from "react";
import { useNavigate,Link } from "react-router-dom";
import { useEffect} from "react";
import CardFreeApps from "../../components/CardT1/CardT3";
function FreeAppS01() {
        
    return (
      <Box sx={{marginTop:10,marginLeft:5,marginRight:5, marginBottom:5}}>      

      </Box>

    )
  }
  export default FreeAppS01