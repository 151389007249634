import React from 'react';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

const DownloadButton = ({ color, size, fileUrl, buttonText }) => {
  const handleDownload = () => {
    // Lógica para descargar el archivo
    window.open(fileUrl, '_blank');
  };
  
  const isMobile2 = useMediaQuery('(max-width: 720px)');

  return (
    <Button variant="contained" color={color} size={size}
    sx={{ fontSize: '1rem',border: 'none',fontWeight: isMobile2 ? 'normal' : '600' , }}
    onClick={handleDownload}>
      {buttonText}
    </Button>
  );
};

export default DownloadButton;
