import React, { useState } from "react";
import { Grid, Paper, Stack, TextField, Button, Box, Select, MenuItem, FormControl, InputLabel, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function RegisterComponent({TEXTOPRINCIPAL}) {
    const navigateOut = useNavigate();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [education, setEducation] = useState("");
    const [cellphone, setCellphone] = useState("");
    const [password, setPassword] = useState("");

    const [fieldErrors, setFieldErrors] = useState({
        name: false,
        email: false,
        education: false,
        cellphone: false,
        password: false,
    });

    const handleNameChange = (event) => {
        const value = event.target.value;
        setName(value);
        setFieldErrors((prevErrors) => ({
            ...prevErrors,
            name: value === '',
        }));
    };

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmail(value);
        setFieldErrors((prevErrors) => ({
            ...prevErrors,
            email: value === '' || !validateEmail(value),
        }));
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEducationChange = (event) => {
        const value = event.target.value;
        setEducation(value);
        setFieldErrors((prevErrors) => ({
            ...prevErrors,
            education: value === '',
        }));
    };

    const handleCellphoneChange = (event) => {
        const value = event.target.value;
        setCellphone(value);
        setFieldErrors((prevErrors) => ({
            ...prevErrors,
            cellphone: value === '',
        }));
    };

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
        setFieldErrors((prevErrors) => ({
            ...prevErrors,
            password: value === '',
        }));
    };

    const onRegister = async () => {
        const response = await fetch('https://8ori4qvsnc.execute-api.us-east-1.amazonaws.com/dev/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                typeoperation: "create",
                "email-academy": email,
                name: name,
                cellphone: cellphone,
                education_level: education,
                password: password,
                statustudent: false,
                statusworker: false,
                statusdirector: false,
                statusfree: true,
                owner: false,
                apps: [],
                courses: [],
                books: [],
                enterprise: "",
                plan: [{ courses: ["none"] }, { ebooks: ["none"] }, { apps: ["none"] }]
            })
        });

        if (!response.ok) {
            const message = `An error has occurred: ${response.status}`;
            throw new Error(message);
        }

        const data = await response.json();
        console.log(data.stateregister);
        alert(data.body);
    }

    const [isLoading, setIsLoading] = useState(false);

    const handleRegisterClick = async () => {
        if (name === '') {
            setFieldErrors((prevErrors) => ({
                ...prevErrors,
                name: true,
            }));
        }
        if (email === '') {
            setFieldErrors((prevErrors) => ({
                ...prevErrors,
                email: true,
            }));
        }
        if (education === '') {
            setFieldErrors((prevErrors) => ({
                ...prevErrors,
                education: true,
            }));
        }
        if (cellphone === '') {
            setFieldErrors((prevErrors) => ({
                ...prevErrors,
                cellphone: true,
            }));
        }
        if (password === '') {
            setFieldErrors((prevErrors) => ({
                ...prevErrors,
                password: true,
            }));
        }

        if (fieldErrors.email) {
            return;
        }
        if (name === '' || email === '' || education === '' || cellphone === '' || password === '') {
            return;
        }

        setIsLoading(true);
        try {
            await onRegister();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="center" 
        mr={2}
        ml={2}
    
        sx={{
            backgroundColor: '#202736',
            border: '2px solid #08E1BA',
            borderRadius: '16px',
            padding: '20px',
            textAlign: 'center',
            
          }}
          
        >
            <Box elevation={3} sx={{ padding: 2 }}>
                <Stack spacing={2}>
                    <Typography variant="h4" textAlign="center">{TEXTOPRINCIPAL}</Typography>
                    <TextField
                        variant="filled"
                        label='Nombre'
                        error={fieldErrors.name}
                        helperText={fieldErrors.name && 'Este campo es requerido'}
                        onChange={handleNameChange}
                    />
                    <TextField
                        variant="filled"
                        label='Correo'
                        error={fieldErrors.email}
                        helperText={fieldErrors.email && 'Este campo es requerido, Ingresar Correo Valido'}
                        onChange={handleEmailChange}
                    />
                    <FormControl variant="filled" fullWidth>
                        <InputLabel id="education-label">Nivel Educativo</InputLabel>
                        <Select
                            labelId="education-label"
                            value={education}
                            onChange={handleEducationChange}
                            error={fieldErrors.education}
                        >
                            <MenuItem value={"Estudiante-Universitario"}>Estudiante Universitario</MenuItem>
                            <MenuItem value={"Profesional"}>Profesional</MenuItem>
                            <MenuItem value={"Especialista"}>Especialista</MenuItem>
                            <MenuItem value={"Magister"}>Magister</MenuItem>
                            <MenuItem value={"Doctorado"}>Doctorado</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        variant="filled"
                        label='Celular/WhatsApp'
                        error={fieldErrors.cellphone}
                        helperText={fieldErrors.cellphone && 'Este campo es requerido'}
                        onChange={handleCellphoneChange}
                    />
                    <TextField
                        variant="filled"
                        type="password"
                        label='Contraseña'
                        error={fieldErrors.password}
                        helperText={fieldErrors.password && 'Este campo es requerido'}
                        onChange={handlePasswordChange}
                    />
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={handleRegisterClick}
                        disabled={isLoading}
                        startIcon={isLoading && <CircularProgress size={20} />}
                    >
                        {isLoading ? 'Registrando...' : 'Registrarme'}
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}

export default RegisterComponent;
