import {NavLink} from 'react-router-dom'
import { Box } from "@mui/material";

function Myapps() {
    return (
        <div>
            <Box height={50} />
            <h2>These are my apps</h2>
            <ul>
                <li>
                    <NavLink to='/constructor'>Constructor</NavLink>                    
                </li>   
            </ul>
        </div>

    )
  }
export default Myapps
