import React from 'react';
import { Stage, Layer, Line, Circle } from 'react-konva';

const DrawingComponentt = ({ coordinates }) => {
  return (
    <Stage width={window.innerWidth} height={window.innerHeight}>
      <Layer>
        {/* Dibujar líneas */}
        {coordinates.map((coord, index) => {
          const nextCoord = coordinates[index + 1];
          if (nextCoord) {
            return (
              <Line
                key={index}
                points={[coord.x, coord.y, nextCoord.x, nextCoord.y]}
                stroke="black"
                strokeWidth={2}
              />
            );
          }
          return null;
        })}
        {/* Dibujar puntos */}
        {coordinates.map((coord, index) => (
          <Circle
            key={index}
            x={coord.x}
            y={coord.y}
            radius={5}
            fill="red"
            draggable
          />
        ))}
      </Layer>
    </Stage>
  );
};

export default DrawingComponentt;

