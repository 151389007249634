
import {NavLink} from 'react-router-dom'
import { Box } from "@mui/material";
import CardComponent from '../components/CardT1/CardT1';
import CoursesCard from '../components/CardT1/CardT2';


const bull="hola"
function MyFreeCourses() {

    const list_filters = [
        { text: 'Ir a Mis Cursos Iniciados', color: '#4cceac', textColor: '#141B2D' }      
      ];

    const cardDataList = [
        {
          title: 'Curso 1',
          imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba.PNG',
          description: 'Descripción del Curso 1',
          gradientColors: ['#42899b', '#114c66'], // Colores del gradiente para el Curso 1
          to:'cf01'
        },
        {
          title: 'Curso 2',
          imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba.PNG',
          description: 'Descripción del Curso 2',
          gradientColors:['#42899b', '#114c66'], // Colores del gradiente para el Curso 2
          to:'cf02'
        },
        {
          title: 'Curso 3',
          imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba.PNG',
          description: 'Descripción del Curso 3',
          gradientColors: ['#42899b', '#114c66'], // Colores del gradiente para el Curso 3
          to:'cf03'
        },
        {
          title: 'Curso 4',
          imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba.PNG',
          description: 'Descripción del Curso 3',
          gradientColors: ['#42899b', '#114c66'], // Colores del gradiente para el Curso 3
          to:'cf04'
        },
        {
          title: 'Curso 5',
          imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/imagendeprueba.PNG',
          description: 'Descripción del Curso 3',
          gradientColors: ['#42899b', '#114c66'], // Colores del gradiente para el Curso 3
          to:'cf05'
        },

      ];

    return (
      <div><Box
            sx={{ height: '50px', overflow: 'auto',margingTop: '50px' }}/>            
            <Box marginTop={2} marginRight={1} marginBottom={4} marginLeft={1}>
            <CardComponent list={list_filters} />  
            <CoursesCard cardDataList={cardDataList} />
            </Box>            
            
            <ul>
                <li>
                    <NavLink to='/curso'>Link provisional</NavLink>                    
                </li>   
            </ul>
        </div>
      
    )
  }
  export default MyFreeCourses
  