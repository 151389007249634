import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, ListItem, ListItemIcon, Stack, useTheme, useMediaQuery } from '@mui/material';
import PricesCourses1 from './sells/prices1';
import SingleImage from '../components/images/imagen1';
import WhatsAppButtonComponent from '../components/whatsapp/WhatsappButton';
import VideoListSellsPopUp from './sells/popUpsVideos';

const MarketPlace = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const marginTop = isMobile ? theme.spacing(10) : theme.spacing(10);
  const marginBottom = isMobile ? theme.spacing(1) : theme.spacing(2);
  const marginLeft = isMobile ? theme.spacing(1) : theme.spacing(2);
  const marginRight = isMobile ? theme.spacing(1) : theme.spacing(2);

  const cursoslocal = localStorage.getItem("plan_user");
  const [prices, setPrices] = useState(null);

 

  const marginpop = isMobile ? 5 : 5; // Establecer diferentes valores de margen para mobile y desktop

  useEffect(() => {
    if (cursoslocal === '3m' || cursoslocal === 'plan_academy') {
      setPrices(false);
    } else {
      setPrices(true);
    }
  }, [cursoslocal]);

  return (
    <div>
      <Box sx={{
        marginTop: marginTop,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
        marginRight: marginRight
      }}>
        {prices ? (
          <Box textAlign="center" sx={{ marginBottom: theme.spacing(50) }}> {/* Agregamos el textAlign="center" para centrar */}
            <Box>
              <Typography variant="h2" sx={{ marginBottom: theme.spacing(1) }}>MarketPlace Cursos</Typography> {/* Agregamos el marginBottom */}
              <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/nonactisubs.png'></SingleImage>


              
              <Typography variant="h2" sx={{ marginBottom: theme.spacing(1) }}>Cursos Dispobibles</Typography> 
              <Box m={marginpop}>
              <VideoListSellsPopUp></VideoListSellsPopUp>
              </Box>
            



              <Typography variant="h2" sx={{ marginBottom: theme.spacing(1) }}>Precios y planes</Typography> {/* Agregamos el marginBottom */}
              <PricesCourses1 />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>      
                <WhatsAppButtonComponent />
              </div> 
            </Box>
          </Box>
        ) : (
          <Box textAlign="center"> {/* Agregamos el textAlign="center" para centrar */}
            <Typography variant="h2" sx={{ marginBottom: theme.spacing(2) }}>MarketPlace Cursos</Typography> {/* Agregamos el marginBottom */}
            <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/activesubs.png'></SingleImage>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default MarketPlace;

