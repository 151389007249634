import React from "react";
import { useMediaQuery } from '@mui/material';

function VimeoVideoPublicidad({ videoNumber }) {
  const isDesktop = useMediaQuery('(min-width:1200px)');
  const isTablet = useMediaQuery('(min-width:768px) and (max-width:1199px)');
  const isMobile = useMediaQuery('(max-width:767px)');

  let height;
  if (isDesktop) {
    height = 720;
  } else if (isTablet) {
    height = 600;
  } else if (isMobile) {
    height = 400;
  }

  const videoSrc = `https://player.vimeo.com/video/${videoNumber}?h=1cc3c46f24&title=0&byline=0&portrait=0`;

  return (
    <iframe
      src={videoSrc}
      width="100%"
      height={height}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
}

export default VimeoVideoPublicidad;
