import { Box } from "@mui/material";

function MyEbooks() {
    return (
        <div>
            <Box height={50} />
            <h2>These are my Ebooks</h2>           
        </div>
    )
  }
export default MyEbooks