import React, { useState, useEffect } from 'react';
import { Box, Pagination, useMediaQuery } from '@mui/material';

const ImageSlider = ({ imageUrls,time }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        (prevIndex + 1) % imageUrls.length
      );
    }, time);

    return () => {
      clearInterval(interval);
    };
  }, [imageUrls]);

  const handleChangePage = (event, newIndex) => {
    setCurrentImageIndex(newIndex - 1);
  };

  const imageWidth = isMobile ? '100%' : '700px';
  const imageHeight = isMobile ? 'auto' : '400px';

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      minHeight="200px"
    >
      <img
        src={imageUrls[currentImageIndex]}
        alt={`Image ${currentImageIndex}`}
        style={{ maxWidth: imageWidth, height: imageHeight }}
      />
      <Pagination
        count={imageUrls.length}
        page={currentImageIndex + 1}
        onChange={handleChangePage}
      />
    </Box>
  );
};

export default ImageSlider;
