import React from 'react';
import { Grid, Card, CardContent, CardActions, Button, Typography,Box,useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate,Link } from 'react-router-dom';

const HomeLandingCard3 = ({ cardDataList }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();

  const handleButtonClick = (to) => {
    navigate(`/courses/${to}`);
  };

  const CardContainer = styled(Card)(({ theme, gradientColors }) => ({
    maxWidth: 400,
    margin: '0 auto',
    background: `linear-gradient(to right, ${gradientColors[0]}, ${gradientColors[1]})`,
    color: '#141b2d',
    borderRadius: 10,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 12,
    letterSpacing: 1,
  }));

  const Image = styled('img')({
    width: '100%',
    height: 400,
    objectFit: 'cover',
    borderRadius: '10px 10px 0 0',
  });

  const ButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '100%',
    marginTop: '40px'
  });

  return (
    <Box display="flex" justifyContent="center" marginTop="100">
      <Grid container spacing={2}>
        {cardDataList.map((cardData, index) => (
          <Grid item xs={12} sm={10} md={10} key={index}>
            <CardContainer
              gradientColors={cardData.gradientColors}
              sx={{ height: isMobile ? '250px' : '300px' }}
            >
              {/*<Image src={cardData.imageUrl} alt="Card" />*/}

              <CardContent>
                <Title variant="h2" component="div" align="center">
                  {cardData.title}
                </Title>
                <Typography variant="body1" component="div" align="center" sx={{ marginTop: '50px' }}>
                  {cardData.description}
                </Typography>
                <ButtonContainer>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/cursos"
                    sx={{ marginBottom: '12px' }}
                  >
                    VER CURSOS
                  </Button>
                </ButtonContainer>
              </CardContent>
            </CardContainer>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HomeLandingCard3;
