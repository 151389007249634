import React, { useState, useEffect } from 'react';
import { Container, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { ArrowBack, Menu as MenuIcon, ArrowForward } from '@mui/icons-material';
import { InlineMath } from 'react-katex';

const EbookComponent = ({ sections }) => {
  const [currentSection, setCurrentSection] = useState(0);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const toggleMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const changeSection = (index) => {
    setCurrentSection(index);
    closeMenu();
  };

  useEffect(() => {
    // Scroll to top of page when the current section changes
    window.scrollTo(0, 0);
  }, [currentSection]);

  const { title, description, image, content } = sections[currentSection];

  return (
    <Container
      maxWidth="md"
      sx={{
        paddingTop: '2rem',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        '@media (max-width: 600px)': {
          paddingLeft: '1rem',
          paddingRight: '1rem',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '2rem',
          width: '100%',
        }}
      >
        <IconButton
          onClick={() => changeSection(currentSection - 1)}
          disabled={currentSection === 0}
          sx={{ color: '#141b2d' }}
        >
          <ArrowBack sx={{ color: '#141b2d' }} />
        </IconButton>
        <IconButton onClick={toggleMenu} sx={{ color: '#141b2d' }}>
          <MenuIcon sx={{ color: '#141b2d' }} />
        </IconButton>
        <IconButton
          onClick={() => changeSection(currentSection + 1)}
          disabled={currentSection === sections.length - 1}
          sx={{ color: '#141b2d' }}
        >
          <ArrowForward sx={{ color: '#141b2d' }} />
        </IconButton>
      </div>

      <Typography variant="h3" sx={{ marginBottom: '2rem', textAlign: 'center', color: '#88001b' }}>
        {title}
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: '2rem', textAlign: 'center' }}>
        {description}
      </Typography>

      {image && (
        <div
          sx={{
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '2rem',
          }}
        >
          <img src={image} alt="Imagen del título" style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
      )}

      {content.map((item, index) => (
        <div key={index} sx={{ marginBottom: '2rem' }}>
          {item.type === 'titulo' && (
            <Typography variant="h4" sx={{ color: 'black' }}>
              {item.text}
            </Typography>
          )}
          {item.type === 'subtitulo' && <Typography variant="h5">{item.text}</Typography>}
          {item.type === 'subsubtitulo' && <Typography variant="h6">{item.text}</Typography>}
          {item.type === 'parrafo' && (
            <Typography variant="h5" sx={{ textAlign: 'justify', marginBottom: '1rem' }}>
              {item.text}
            </Typography>
          )}
          {item.type === 'imagen' && (
            <div
              sx={{
                textAlign: 'center',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '2rem',
              }}
            >
              <img src={item.url} alt={item.alt} style={{ maxWidth: '100%', height: 'auto' }} />
            </div>
          )}
          {item.type === 'formula' && (
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
              <InlineMath>{item.text}</InlineMath>
            </div>
          )}
          {item.type === 'listaNumerada' && (
            <ol style={{ marginBottom: '1rem', fontSize: '1rem' }}>
              {item.items.map((listItem, i) => (
                <li key={i}>{listItem}</li>
              ))}
            </ol>
          )}
          {item.type === 'listaNoNumerada' && (
            <ul style={{ marginBottom: '1rem', fontSize: '1rem' }}>
              {item.items.map((listItem, i) => (
                <li key={i}>{listItem}</li>
              ))}
            </ul>
          )}
        </div>
      ))}

      <div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '2rem',
          width: '100%',
        }}
      >
        <IconButton
          onClick={() => changeSection(currentSection - 1)}
          disabled={currentSection === 0}
          sx={{ color: '#141b2d' }}
        >
          <ArrowBack sx={{ color: '#141b2d' }} />
        </IconButton>
        <IconButton onClick={toggleMenu} sx={{ color: '#141b2d' }}>
          <MenuIcon sx={{ color: '#141b2d' }} />
        </IconButton>
        <IconButton
          onClick={() => changeSection(currentSection + 1)}
          disabled={currentSection === sections.length - 1}
          sx={{ color: '#141b2d' }}
        >
          <ArrowForward sx={{ color: '#141b2d' }} />
        </IconButton>
      </div>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        getContentAnchorEl={null}
      >
        {sections.map((section, index) => (
          <MenuItem key={index} onClick={() => changeSection(index)}>
            {section.title}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

export default EbookComponent;
