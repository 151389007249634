import React from 'react';
import { Grid, Card, CardContent, CardActions, Button, Typography,Box } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const HomeLandingCard = ({ cardDataList }) => {
  const navigate = useNavigate();

  const handleButtonClick = (to) => {
    
    navigate(`/courses/${to}`);;
  };
  const CardContainer = styled(Card)(({ theme, gradientColors }) => ({
    maxWidth: 400,
    margin: '0 auto',
    background: `linear-gradient(to right, ${gradientColors[0]}, ${gradientColors[1]})`,
    color: '#141b2d',
    borderRadius: 10,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 12,
    letterSpacing: 1,
  }));

  const Image = styled('img')({
    width: '100%',
    height: 200,
    objectFit: 'cover',
    borderRadius: '10px 10px 0 0',
  });



  return (
    <Grid container spacing={1}>
      {cardDataList.map((cardData, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <CardContainer gradientColors={cardData.gradientColors}>
          
            {/*<Image src={cardData.imageUrl} alt="Card" />*/}
            
            <CardContent>
              <Title variant="h2" component="div" align="center">
                {cardData.title}
              </Title>
              <Typography variant="body1" component="div" align="center">
                {cardData.description}
              </Typography>
            </CardContent>           
          </CardContainer>
        </Grid>
      ))}
    </Grid>
  );
};

export default HomeLandingCard;
