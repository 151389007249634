import React from 'react';
import { Box, Typography, Button } from '@mui/material';


const CursoBox = ({ curso, precio, descripcion, linkDePago }) => {
  const handleClick = () => {
    window.location.href = linkDePago;
  };


  return (
    <Box
    mr={2}
    ml={2}
      sx={{
        backgroundColor: '#202736',
        border: '2px solid red',
        borderRadius: '16px',
        padding: '20px',
        textAlign: 'center',
      
       
        
      }}
    >
        <Box m={3}>
      <Typography
        variant="h3"
        component="h2"
        gutterBottom
        sx={{ color: '#f64854', marginBottom: '24px' }}
      >
        {curso}
      </Typography>
      </Box>
      <Box m={5}>
      <Typography
        variant="h1"
        component="p"
        gutterBottom
        sx={{ color: '#ea810f', fontWeight: 'bold', marginBottom: '24px' }}
      >
        ${precio}
      </Typography>
      </Box>
      <Box m={5}>
      <Typography
        variant="h4"
        paragraph
        sx={{ color: 'white', marginBottom: '24px' }}
      >
        {descripcion}
      </Typography>
      </Box>
      <Box mb={2}>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#f64854',
          color: '#141b2b',
          width: '100%',
          height: '35px',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: '#4cceac',
          },
        }}
        onClick={handleClick}
      >
        Ir a comprar
      </Button>
      </Box>
    </Box>
  );
};

export default CursoBox;
