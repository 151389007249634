import { Stack,Grid,Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockTransactions } from "../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

import LineChart from "./LineChart";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import useMediaQuery from '@mui/material/useMediaQuery';



function VideoListReproduction() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    

    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));



    return (
      <Box m="20px" sx={{        
        display: isDesktop ? 'block' : 'none',
        marginTop:1
      }} >
        {/* HEADER */}
      
  
        {/* GRID & CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >
                   
          <Box
            gridColumn="span 15"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            overflow="auto"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              colors={colors.grey[100]}
              p="15px"
            >
              <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                Lista Clases
              </Typography>
            </Box>
            {mockTransactions.map((transaction, i) => (
              <Box
                key={`${transaction.txId}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                p="15px"
              >
                <Box>
                  <Typography
                    color={colors.greenAccent[500]}
                    variant="h5"
                    fontWeight="600"
                  >
                    {transaction.txId}
                  </Typography>
                 
                </Box>
                
                <Box color={colors.grey[100]}>{transaction.date}</Box>
               
                <PlayCircleOutlineIcon></PlayCircleOutlineIcon>
              </Box>
            ))}
          </Box>
  
     
         
         
        </Box>
      </Box>
    );
  };
  
  
  export default VideoListReproduction