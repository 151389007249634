import {Link} from 'react-router-dom'
import { Box } from "@mui/material";
import SingleImage from '../../components/images/imagen1';


function HomePage() {
  return (
    <Box marginTop={10}>
    <h1></h1>
        <Box m={2} marginTop={5}>
        <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/welcomepage+1_min.png'></SingleImage>
        </Box>
    </Box>
    )
}
export default HomePage
  
  