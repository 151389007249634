import React, { useState, useEffect } from 'react';
import { Stage, Layer, Circle, Line, Text, Shape, Image } from 'react-konva';

const DrawingComponent = ({ coordinates, lineColor, pointColor, textColor, fillColor, imageURL }) => {
  const [bounds, setBounds] = useState({ minX: 0, minY: 0, maxX: 0, maxY: 0 });
  const [scale, setScale] = useState(1);
  const [lineLengths, setLineLengths] = useState([]);
  const [isClosed, setIsClosed] = useState(false);

  const calculateBounds = (coordinates) => {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    coordinates.forEach((coord) => {
      if (coord.x < minX) minX = coord.x;
      if (coord.x > maxX) maxX = coord.x;
      if (coord.y < minY) minY = coord.y;
      if (coord.y > maxY) maxY = coord.y;
    });

    return { minX, minY, maxX, maxY };
  };

  useEffect(() => {
    const newBounds = calculateBounds(coordinates);
    const scaleX = 450 / (newBounds.maxX - newBounds.minX);
    const scaleY = 350 / (newBounds.maxY - newBounds.minY);
    const newScale = Math.min(scaleX, scaleY);

    setBounds(newBounds);
    setScale(newScale);

    // Calcular las longitudes de las líneas
    const lengths = [];
    for (let i = 1; i < coordinates.length; i++) {
      const { x: x1, y: y1 } = coordinates[i - 1];
      const { x: x2, y: y2 } = coordinates[i];
      const length = calculateLineLength(x1, y1, x2, y2);
      lengths.push(length);
    }
    setLineLengths(lengths);

    // Comprobar si la figura está cerrada
    setIsClosed(
      coordinates.length >= 3 &&
        coordinates[0].x === coordinates[coordinates.length - 1].x &&
        coordinates[0].y === coordinates[coordinates.length - 1].y
    );
  }, [coordinates]);

  const calculateLineLength = (x1, y1, x2, y2) => {
    const dx = x2 - x1;
    const dy = y2 - y1;
    return Math.sqrt(dx * dx + dy * dy).toFixed(2);
  };

  const transformedCoordinates = coordinates.map((coord) => ({
    x: 25 + (coord.x - bounds.minX) * scale,
    y: 375 - (coord.y - bounds.minY) * scale,
  }));

  const getFillPoints = () => {
    return transformedCoordinates.flatMap((coord) => [coord.x, coord.y]);
  };

  const imageWidth = bounds.maxX - bounds.minX;
  const imageHeight = bounds.maxY - bounds.minY;
  const imageScale = Math.min(600 / imageWidth, 350 / imageHeight);

  const renderGrid = () => {
    const grid = [];
    const gridSize = 50;

    for (let x = 0; x < 500; x += gridSize) {
      grid.push(<Line key={`vertical-${x}`} points={[x, 0, x, 400]} stroke="#ddd" strokeWidth={1} />);
    }

    for (let y = 0; y < 400; y += gridSize) {
      grid.push(<Line key={`horizontal-${y}`} points={[0, y, 500, y]} stroke="#ddd" strokeWidth={1} />);
    }

    return grid;
  };

  const stageWidth = 500;
  const stageHeight = 400;

  const containerWidth = window.innerWidth; // Ancho del contenedor
  const offsetX = (containerWidth - stageWidth) / 2; // Desplazamiento en X para centrar

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Stage width={stageWidth} height={stageHeight}>
        <Layer>
          {renderGrid()}
          {imageURL && (
            <Image
              image={imageURL}
              width={imageWidth * imageScale}
              height={imageHeight * imageScale}
              x={25 + (bounds.minX - bounds.minX) * scale}
              y={375 - (bounds.minY - bounds.minY) * scale}
            />
          )}
          {isClosed && (
            <Shape
              sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(transformedCoordinates[0].x, transformedCoordinates[0].y);
                transformedCoordinates.slice(1).forEach((coord) => {
                  context.lineTo(coord.x, coord.y);
                });
                context.closePath();
                context.fillStrokeShape(shape);
              }}
              fill={fillColor || '#FFFF99'}
            />
          )}
          {transformedCoordinates.map((coord, index) => (
            <React.Fragment key={index}>
              {/* Puntos */}
              <Circle x={coord.x} y={coord.y} radius={5} fill={pointColor || '#9de781'} />
              {/* Líneas */}
              {index > 0 && (
                <React.Fragment>
                  <Line
                    points={[
                      transformedCoordinates[index - 1].x,
                      transformedCoordinates[index - 1].y,
                      coord.x,
                      coord.y,
                    ]}
                    stroke={lineColor || 'red'}
                    strokeWidth={2}
                  />
                  <Text
                    x={(transformedCoordinates[index - 1].x + coord.x) / 2}
                    y={(transformedCoordinates[index - 1].y + coord.y) / 2}
                    text={lineLengths[index - 1]}
                    fontSize={12}
                    fill={textColor || '#000000'}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </Layer>
      </Stage>
    </div>
  );
};

export default DrawingComponent;
