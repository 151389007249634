import {Typography,Paper,Stack,TextField,Button,Box, Select,MenuItem,FormControl,InputLabel,LinearProgress,CircularProgress } from "@mui/material";
import Header from "../../components/Header";
import { Link } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from "react";

function LoginPage({onLogin}) {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleEmailChange = (event) => {
      const value = event.target.value;
      //console.log("Email:", value);
      setEmail(value);
    };

    const handlePasswordChange = (event) => {
      const value = event.target.value;
      //console.log("Password:", value);
      setPassword(value);
    };
    
    const [isLoading, setIsLoading] = useState(false);

    const handleLoginClick =  async () => {
      setIsLoading(true);    
      try {
        await onLogin(email, password);
        // La autenticación fue exitosa
        setIsLoading(false);
        // Resto de la lógica después de iniciar sesión exitosamente
      } catch (error) {
        // Ocurrió un error en la autenticación
        setIsLoading(false);
        // Resto de la lógica de manejo de error
      }
    };

    

  
    return (      
      
      <div>
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',
        padding: '1px',
        marginTop: '64px',
        marginBottom: '64px'  
        }}
        >

        <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'center',
              display: isDesktop ? 'block' : 'none'
              }}>              
                <img
                  alt="pet-proexacademy"
                  width="400px"
                  height="400px"
                  src='https://academy-public-resources.s3.amazonaws.com/publics/loginimage2+1_min.png'     
                  style={{ borderRadius: '5%' }}             
                />

        </Box>


        <Box m="10px" sx={{ display: 'flex', width:'350px',  height: '80vh', alignItems: 'center', justifyContent: 'center',
        //borderLeft: '2px solid grey',
        //border: '1px solid #CCCCCC', // Establece un borde gris claro de 1 píxel    
        //padding: '10px', // Establece un padding de 20 píxeles en todos los lados del componente Box
        }}> 
            <Stack direction='column' 
            spacing={4}
            sx={{ width: '80%' }}

            >
              <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>

                  <img
                    alt="profile-user"
                    width="180px"
                    height="70px"
                    src={`../../assets/logoproexblanco.png`}                  
                  />

              </Box>
              
              <Header title="LOGIN EN ACADEMY" subtitle="" />
              
                <TextField type="email" variant="filled" label='correo' 
                value={email}
                onChange={handleEmailChange}
                />
                <TextField type="password" variant="filled" label='contraseña'
                value={password}
                onChange={handlePasswordChange}
                />  

           

                <Button variant='contained' color='secondary'
                type="submit"
                onClick={handleLoginClick}
                disabled={isLoading}
                startIcon={isLoading && <CircularProgress size={20} />}
                >{isLoading ? 'Iniciando sesión...' : 'Iniciar sesión'}</Button> 
                {/*<LinearProgress />*/}
                

              <Box sx={{ display: 'flex', justifyContent: 'center', margin:'0'}}>
                <Link to="/recover">
                  <Button variant="outlined" color="primary" style={{ color: '#ba3e36' }}>Recuperar contraseña</Button>     
                </Link>
              </Box>

            
              
                <Link to="/register">
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ width: '100%' }} // Establecer el ancho al 100%
                  >
                    Registrarme
                  </Button>
                </Link>
              

              <Typography variant="body1" component="div">
              </Typography>                  
            </Stack>
          </Box>
        </Box>
    </div>  
    );
    
  }
  export default LoginPage