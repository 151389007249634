import React, { useState } from 'react';
import { Typography, TextField, Grid, MenuItem } from '@mui/material';
import DrawingComponent from './drawing2d';

const CustomBoxList = ({ data }) => {
  const [fieldsData, setFieldsData] = useState(data);

  const coordinates = [
    { x: 0, y: 0 },
    { x: 5 ,y: 0 },
    { x: 5 ,y: 0.30 },
    { x: 3.50 ,y: 0.30 },
    { x: 3.50 ,y: 2.80 },
    { x: 3.00 ,y: 2.80 },
    { x: 3.00 ,y: 0.30 },
    { x: 0 ,y: 0.30 },
    { x: 0 ,y: 0 },
    
    
  ];

  const handleFieldValueChange = (boxIndex, fieldIndex, event) => {
    const updatedFieldsData = [...fieldsData];
    const value = event.target.value;
    const field = updatedFieldsData[boxIndex].fields[fieldIndex];

    if (field.type === 'integer') {
      // Validar si es un valor entero válido
      if (Number.isInteger(Number(value)) || value === '') {
        field.value = value;
      }
    } else if (field.type === 'float') {
      // Validar si es un valor flotante válido
      if (!isNaN(value) || value === '') {
        field.value = value;
      }
    } else {
      // Otros tipos de campo (string, select)
      field.value = value;
    }

    setFieldsData(updatedFieldsData);
  };

  return (
    <div>
      {fieldsData.map((box, boxIndex) => (
        <div
          key={boxIndex}
          style={{
            borderRadius: '8px',
            backgroundColor: box.backgroundColor,
            padding: '16px',
            marginBottom: '16px',
          }}
        >
          <Typography variant="h2">
            <span style={{ color: box.textColor }}>{box.title}</span>
          </Typography>
          {box.imageURL && (
            <div style={{ marginTop: '5px',marginBottom: '0px' }}>
              <img
                src={box.imageURL}
                alt="Box Image"
                style={{ width: '100%', maxWidth: box.maxWidth, maxHeight: box.maxHeight }}
              />
            </div>
          )}
          {box['2dgraphic'] && <DrawingComponent coordinates={coordinates} lineColor={"blue"} pointColor={"green"} textColor={"#ea810f"} fillColor={"#C3D1D9"} backgroundColor="blue"/>}
          <Grid container spacing={2}>
            {box.fields.map((field, fieldIndex) => (
              <Grid item xs={12} sm={6} key={fieldIndex}>
                <div>
                  <Typography variant="subtitle1" style={{ color: box.textColor }}>
                    {field.label}
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {field.type === 'select' && field.selections ? (
                      <TextField
                        variant="outlined"
                        style={{ marginRight: '8px' }}
                        select
                        value={field.value || ''}
                        onChange={(event) => handleFieldValueChange(boxIndex, fieldIndex, event)}
                      >
                        {field.selections.map((selection, selectionIndex) => (
                          <MenuItem key={selectionIndex} value={selection}>
                            {selection}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        variant="outlined"
                        style={{ marginRight: '8px' }}
                        value={field.value || ''}
                        placeholder={field.description}
                        onChange={(event) => handleFieldValueChange(boxIndex, fieldIndex, event)}
                        type={field.type}
                      />
                    )}
                    <Typography variant="body2" style={{ color: box.textColor }}>
                      {field.units}
                    </Typography>
                  </div>
                  {field.additionalDescription && (
                    <Typography variant="body2" style={{ color: box.textColor }}>
                      {field.additionalDescription}
                    </Typography>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default CustomBoxList;
