import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const ListItems = ({ items, backgroundColor, textColor, iconColor, textSize, iconSize }) => {
  return (
    <List>
      {items.map((item, index) => (
        <ListItem
          key={index}
          sx={{
            backgroundColor: backgroundColor,
            marginBottom: '16px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            paddingLeft: '32px',
            border:'10'
          }}
        >
          <ListItemIcon sx={{ color: iconColor, fontSize: iconSize }}>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography variant={textSize} sx={{ color: textColor }}>{item}</Typography>
          </ListItemText>
          
        </ListItem>
      ))}
    </List>
  );
};

export default ListItems;
