import React from 'react';
import { Box, Typography, Button, Grid,ListItem ,ListItemIcon,Stack} from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery'
import { tokens } from "../../theme";
import { useTheme } from '@emotion/react';
import SlideUpText from '../../components/TextAnimation1';
import CheckIcon from '@mui/icons-material/Check';
import CardCustom from '../../components/CardT1/CardT5';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeLandingCard from '../../components/CardT1/CardT6';
import HomeLandingCard2 from '../../components/CardT1/CardT7';
import SingleImage from '../../components/images/imagen1';
import HomeLandingCard3 from '../../components/CardT1/CardT8';
import WhatsAppButtonComponent from '../../components/whatsapp/WhatsappButton';
import FacebookButton from '../../components/Facebook/FabookDir';
import InstagramButton from '../../components/Instagram/Instagram';
import FooterLanding from './FooterLandin';
import ImageSlider from '../../components/ImageList';


const PostSells = () => {
    const theme = useTheme();
    const isMobile2 = useMediaQuery('(max-width: 720px)');
    const colors = tokens(theme.palette.mode);

  return (
    <div>
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #141b2d 50%, #141b2d)',
        paddingTop: '80px',
        paddingBottom: '5px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
       
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } }}>
         
          <SlideUpText text="¡Hola, esperamos que el proceso de compra haya sido satisfactorio!" variant="h1" color="white" marginBottom="20px" />
          <SlideUpText text="Si adquiriste uno de nuestros cursos o planes pronto te contactaremos para habilitarte los cursos en la plataforma," variant="h3" color={colors.blueAccent[400]} marginBottom="20px" />
          <SlideUpText text="por ahora puedes acceder y disfrutar de los recursos gratuitos" variant="h3" color={colors.blueAccent[400]} marginBottom="20px" />
          <Button variant="contained" color="secondary" component={Link} to="/login"
          sx={{ fontSize: '1rem',border: 'none',fontWeight: isMobile2 ? 'normal' : '600' }}
          >
            Acceder
          </Button>
          <Typography>________________</Typography>
          <SlideUpText text="Si aun no te has registrado lo puedes hacer ahora y luego acceder" variant="h3" color={colors.blueAccent[400]} marginBottom="20px" />
          <Button variant="contained" color="secondary" component={Link} to="/register"
          sx={{ fontSize: '1rem',border: 'none',fontWeight: isMobile2 ? 'normal' : '600' }}
          >
            Registrarme
          </Button>

          <SingleImage Urlimage='https://academy-public-resources.s3.amazonaws.com/publics/bridge+and+logo_min.png'></SingleImage>
        </Grid>
        
       
      </Grid>
    </Box>
    
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
      
      <WhatsAppButtonComponent />
    </div>




   
  

   
    

    


    <Box
      sx={{
        backgroundImage: 'linear-gradient(to top, #141b2d 30%, #141b2d)',
        paddingTop: '5px',
        paddingBottom: '5px',
        textAlign: { xs: 'center', sm: 'center' },
        width: '100%',
        
      }}
    >
      <Grid container spacing={1}>
        
        <Grid item xs={12} sm={12} sx={{ order: { xs: 1, sm: 1 } }}>
        <Box display="flex" flexDirection="column" alignItems="center">
      <Box width={200} mb={2}>
        <InstagramButton />
      </Box>
      <Box width={200}>
        <FacebookButton />
      </Box>
    </Box>
        
        
        </Grid>
           
      </Grid>
    </Box>

    <FooterLanding></FooterLanding>




    </div>
   
    
    
  );
};

export default PostSells;
