import {Link} from 'react-router-dom'
import { Box,Typography,Button} from "@mui/material";
import CoursesCardLanding from '../../components/CardT1/CardT9';
import FooterLanding from './FooterLandin';

function MainCourses() {
  const cardDataCourses = [
    {
      title: 'Diseño Estructural de Puentes en vigas de acero tipo "I" y tipo Cajón',
      imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/iconos-cursos/icono_pueentesi.svg',
      description: '30 horas-Desde conceptos básicos hasta avanzados-Ejercicios aplicativos-Uso Software Csi Bridge ',
      gradientColors: ['#141b2b','#141b2b'], // Colores del gradiente para el Curso 1
      to:'cursos-estructuras-puentes-01',
      bordercolor:'#9de781',
      maintextcolor:'red'
    },
    {
      title: 'Modelación  y diseño estructural para edificaciones en Muros de concreto con Etabs',
      imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/iconos-cursos/icocono_edificiosmuros1.svg',
      description: '8 horas 11 minutos-Desde conceptos básicos hasta avanzados-Ejercicios aplicativos-Uso Software Etabs',
      gradientColors: ['#141b2b','#141b2b'], // Colores del gradiente para el Curso 2
      to:'cursos-estructuras-edificios-01',
      bordercolor:'#4c92dd',
      
    },
    {
      title: 'Fundamentos para diseño estructural de Edificaciones',
      imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/iconos-cursos/icono-fundamentos-edficiaciones.svg',
      description: '4 Horas 26 minutos-Conceptos básicos para iniciarse en diseño estructural',
      gradientColors: ['#141b2b','#141b2b'], // Colores del gradiente para el Curso 3
      to:'cursos-estructuras-edificios-02',
      bordercolor:'#4c92dd',
     
    },
    {
      title: 'Avaluos de cargas para Edificaciones',
      imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/iconos-cursos/icocono_avaluo_cargas.svg',
      description: '4 Horas 25 minutos-Conceptos básicos para iniciarse en diseño estructural',
      gradientColors: ['#141b2b','#141b2b'] , // Colores del gradiente para el Curso 3
      to:'cursos-estructuras-edificios-03',
      bordercolor:'#4c92dd',
      
    },
    {
      title: 'Desarrollo y despliegue de aplicaciones con Inteligencia artificial',
      imageUrl: 'https://academy-public-resources.s3.amazonaws.com/publics/logocoursefour.png',
      description: '6 Horas 25 minutos-De cero a aplicacion en funcionando en internet',
      gradientColors: ['#141b2b','#141b2b'] , // Colores del gradiente para el Curso 3
      to:'cursos-desarrollo-inteligencia-04',
      bordercolor:'#D52A33',      
    }
  ];
  return (
    <Box>
      <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #141b2d 50%, #232c41 )',
        paddingTop: '110px',
        paddingBottom: '40px',
        textAlign: 'center',
          }}
        >
          <Typography variant="h4" sx={{ marginBottom: '20px' }}>
            ¡Bienvenido a los Cursos de Proex Academy!
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '20px' }}>
            Te Invitamos a explorarlos
          </Typography>

      </Box>
        
      <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #232c41 50%, #141b2d )',
        paddingTop: '10px',
        paddingBottom: '40px',
        textAlign: 'center',
          }}
        >

          <CoursesCardLanding cardDataList={cardDataCourses} />
         
        
      </Box>
      <FooterLanding></FooterLanding>
    </Box>
  
  
  
  );
};
export default MainCourses
  
  