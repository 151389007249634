import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, TextField, Button, Typography } from '@mui/material';
import { ConsoleSqlOutlined } from '@ant-design/icons';

const CommentsComponent = ({ personName, idvideo, idlastvideo, codecourse ,nameVideo,nameCourse}) => {
  const theme = useTheme();
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [replyTexts, setReplyTexts] = useState({});
  const [commentInfo, setCommentInfo] = useState(null); // Variable para guardar el texto y la fecha del comentario
  const [replyInfo,setReplyInfo]= useState(null); // Variable para guardar el texto y la fecha de la replica

  
  const cursocodigo = codecourse;
  const [idLast, setIdLast] = useState(idlastvideo);

  const recovercommnets=JSON.parse(localStorage.getItem('comentariosiniciales'))
      console.log("Local Stotage desde VideoComments")
      console.log(recovercommnets)

  useEffect(() => {   
    const postComments = async () => {
      try {
        const response = await fetch('https://8ori4qvsnc.execute-api.us-east-1.amazonaws.com/dev/comments', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(commentInfo)
        });

        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    if (commentInfo) {
      postComments();
    }
  }, [commentInfo]);


  useEffect(() => {   
    const postReply = async () => {
      try {
        const response = await fetch('https://8ori4qvsnc.execute-api.us-east-1.amazonaws.com/dev/comments', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(replyInfo)
        });

        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error('Error fetching reply:', error);
      }
    };

    if (replyInfo) {
      postReply();
    }
  }, [replyInfo]);

  useEffect(() => {
    const storedComments = localStorage.getItem('comentariosiniciales');
    console.log("Comentarios Iniciales desde componente de comentarios")   
    console.log(storedComments)

    if (storedComments) {
      const parsedComments = JSON.parse(storedComments);
      const filteredComments = parsedComments.filter((comment) => comment.class === idvideo);
      setComments(filteredComments);
      setIdLast(parsedComments.idlast);
    }
  }, [idvideo]);

  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };

  const handleReplyChange = (event, commentId) => {
    const newTexts = {
      ...replyTexts,
      [commentId]: event.target.value,
    };
    setReplyTexts(newTexts);
  };

  const handleCommentSubmit = () => {
    if (commentText.trim() === '') return;

    const newComment = {
      id: Date.now().toString(),
      text: commentText,
      author: `${personName}`,
      date: new Date().toISOString(),
      replies: [],
    };

    const updatedComments = [...comments, newComment];

    setComments(updatedComments);
    setCommentText('');

 
    

    // Guardar el texto y la fecha del comentario
    setCommentInfo({
      "type-operation": "post",
      "typecomment": "comment",
      "course": codecourse,
      "nameCourse":nameCourse,
      "class": idvideo,
      "nombreclase":nameVideo,
      "author": personName,
      "date": new Date().toISOString(),
      "text": commentText,
      "replies": []
    });

   
  };

  const handleReplySubmit = (commentId) => {
    const commentIndex = comments.findIndex((comment) => comment.id === commentId);
    const textMainComment= (comments.find(comment => comment.id === commentId)).text;

    if (commentIndex === -1) return;

    if (replyTexts[commentId].trim() === '') return;

    const newReply = {
      id: Date.now().toString(),
      text: replyTexts[commentId],
      author: `${personName}`,
      date: new Date().toISOString(),
    };

    const updatedComments = [...comments];
    updatedComments[commentIndex].replies.push(newReply);

    setComments(updatedComments);
    setReplyTexts((prevReplyTexts) => ({
      ...prevReplyTexts,
      [commentId]: '',
    }));

    setReplyInfo({
      "type-operation": "post",
      "typecomment": "reply",
      "course": codecourse,
      "id_comentario": textMainComment,
      "author": personName,
      "date": newReply.date,
      "text": newReply.text,
      "nameCourse":nameCourse, 
      "nombreclase":nameVideo,
    });
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleString('es-ES', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  console.log("Informacion replica")
  console.log(replyInfo)

  return (
    <Box
      sx={{
        marginTop: '1',
        marginBottom: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: '#888888',
        borderRadius: theme.spacing(1),
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.up('sm')]: {
          width: '70%',
          margin: '0 auto',
        },
      }}
    >
      <Box m={2}>
        <Typography>Comentarios y Aportes</Typography>
      </Box>
      <TextField
        label="Deja tu comentario o Aporte"
        multiline
        rows={2}
        value={commentText}
        onChange={handleCommentChange}
        variant="outlined"
        fullWidth
        InputProps={{ style: { color: '#141b2b' } }}
      />
      <Box marginTop={3}>
        <Button variant="contained" color="secondary" onClick={handleCommentSubmit}>
          Enviar comentario
        </Button>
      </Box>

      <Box sx={{ marginTop: theme.spacing(2), width: '100%' }}>
        {comments.map((comment) => (
          <Box
            key={comment.id}
            sx={{
              backgroundColor: '#1f2a40',
              padding: theme.spacing(2),
              marginBottom: theme.spacing(1),
              borderRadius: theme.spacing(1),
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                color: 'white',
                fontWeight: 'bold',
                marginBottom: theme.spacing(1),
              }}
            >
              {comment.text} - {comment.author} - {formatDate(comment.date)}
            </div>

            <Box
              width="100%"
              sx={{
                display: 'flex',
                marginTop: theme.spacing(1),
              }}
            >
              <TextField
                rows={2}
                sx={{ flexGrow: 1, marginRight: theme.spacing(1), width: '100%' }}
                label="Responder"
                size="large"
                value={replyTexts[comment.id] || ''}
                onChange={(event) => handleReplyChange(event, comment.id)}
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ width: '100%' }}
                onClick={() => handleReplySubmit(comment.id)}
              >
                Aporte
              </Button>
            </Box>
            {comment.replies.map((reply) => (
              <Box
                marginTop={2}
                marginBottom={2}
                key={reply.id}
                sx={{
                  backgroundColor: 'white',
                  color: '#141b2b',
                  padding: theme.spacing(1),
                  marginBottom: theme.spacing(1),
                  borderRadius: theme.spacing(1),
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  marginLeft: theme.spacing(4),
                }}
              >
                <div>
                  {reply.text} - {reply.author} - {formatDate(reply.date)}
                </div>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CommentsComponent;
