import VideoPlayer from '../../components/VimeoVideoReproductor';
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React, { useState, useEffect, useRef } from 'react';
import dataCourses from './AcademyClasses.json';


function CourseComponent({ access,course,descriptioncourse }) {

  // Codigo de curso ****Cambia para cada curso
  const cursoActual=course
  const descripcionCurso=descriptioncourse
  const [codeLastVideo,setCodeLastVideo]=useState(null)


  
  const getComments = async (cursoActual) => {
    try {
      const response = await fetch('https://8ori4qvsnc.execute-api.us-east-1.amazonaws.com/dev/comments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          course: cursoActual,
          'type-operation': 'get'           
        })
      });

      const data = await response.json();
      const initialComments = data.comments;
      

      setCodeLastVideo(initialComments[0]["idlast"])
      localStorage.setItem('comentariosiniciales', JSON.stringify(initialComments));
      console.log("Local Stotage desde CourseComponent")
      console.log(initialComments)

      const recover=JSON.parse(localStorage.getItem('comentariosiniciales'))
      console.log("Local Stotage desde CourseComponent 2")
      console.log(recover)



    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };
 
  
  useEffect(() => {
    // Aquí puedes llamar a la función getComments con el valor adecuado para cursoActual
    getComments(course);

    const idlastvideo2= localStorage.getItem('comentariosiniciales')
   
    

  }, []);

  
  {/*
  console.log("Informacion obtenida desde el json");
  console.log(dataCourses);*/}

  // Obtener informacion de cursos desde el localstorage
  const data=localStorage.getItem("pruebacursos")
  
  {/*
  const cursosClasesValue = JSON.parse(data).filter(item => item.code === cursoActual)[0]?.cursos_clases || [];
  const descriptionCurrentCourse = JSON.parse(data).filter(item => item.code === cursoActual)[0]?.title || "curso provisional";*/}

  // Filtrar por code="s01" desde el Json  
  const cursosClasesValue = dataCourses.filter(item => item.code === cursoActual)[0]?.cursos_clases || [];
  const descriptionCurrentCourse = dataCourses.filter(item => item.code === cursoActual)[0]?.title || "curso provisional";
  localStorage.setItem('currentcourse', descriptionCurrentCourse);
  // Obtener UserName desde el local Storage
  const username=localStorage.getItem("username")  


  
  if (access) {
    return (
      <div style={{ margin: 0 }}>
        
        <VideoPlayer videos={cursosClasesValue} curso={descripcionCurso} idlastvideo1={codeLastVideo} cursocode={course} />
        
      </div>
    );
  } else {
    return (
      <div style={{ margin: 0 }}>
        <Typography variant="h1">Te invitamos a adquirir uno de nuestros planes</Typography>
      </div>
    );
  }
}

export default CourseComponent;

