import { useMediaQuery, useTheme, Box } from '@mui/material';

const SingleImage2 = ({ Urlimage, boxHeight,boxwidth}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  let imageSize = isMobile ? '200px' : '300px'; // Tamaño para dispositivos móviles
  if (isTablet) {
    imageSize = '400px'; // Tamaño para tabletas
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={boxHeight || '20vh'}     
      marginTop={0} // Elimina el margen superior
    >
      <img
        src={Urlimage}
        alt="Imagen"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          width: 'auto',
          height: 'auto',
        }}
      />
    </Box>
  );
};

export default SingleImage2;
