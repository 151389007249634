import React from 'react';
import { Grid, Typography,Box } from '@mui/material';
import ListaComponentes from './inputcomponent';

const AppComponent = () => {
    const lista = [
        {
          titulo: 'Componente de imagen',
          tipo: 'imagen',
          datos: {
            url: 'https://ejemplo.com/imagen.jpg',
            descripcion: 'Una imagen de ejemplo'
          }
        },
        {
          titulo: 'Componente de número real',
          tipo: 'real',
          datos: {
            descripcion: 'Ingrese un número real:',
            unidades: 'metros',
            letra: 'A'
          }
        },
        {
          titulo: 'Componente de número entero',
          tipo: 'entero',
          datos: {
            descripcion: 'Ingrese un número entero:',
            unidades: 'piezas',
            letra: 'B'
          }
        },
        {
          titulo: 'Componente de texto',
          tipo: 'string',
          datos: {
            descripcion: 'Ingrese un texto:',
            unidades: '',
            letra: 'C'
          }
        },
        {
          titulo: 'Componente de selección',
          tipo: 'select',
          datos: {
            descripcion: 'Seleccione una opción:',
            opciones: ['Opción 1', 'Opción 2', 'Opción 3'],
            unidades: '',
            letra: 'D'
          }
        }
      ];

  return (
    <Grid container spacing={2} justifyContent="center">        
      <Grid item xs={12} sm={6} md={4}>
        <Box marginTop={10}>
            <Typography variant='h2'>IMPUTS</Typography>                    
        </Box>
        <Box >        
            <ListaComponentes lista={lista} />
        </Box>
        
      </Grid>
      <Grid item xs={12} sm={6} md={4}>      
      <Box marginTop={10}>
            <Typography variant='h2'>RESULTADOS</Typography>                    
        </Box>
        <Box >        
            <ListaComponentes lista={lista} />
        </Box>

      </Grid>
      <Grid item xs={12} sm={6} md={4}>
      
        <Box marginTop={10}>
            <Typography variant='h2'>INFORME</Typography>                    
        </Box>
        <Box >        
            <ListaComponentes lista={lista} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AppComponent;
