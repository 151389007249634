import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  Container,
  useMediaQuery,
  Tab,
  Tabs,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import ReactPlayer from 'react-player';
import Typed from 'react-typed';

import CommentsComponent from './VideoComments';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const namecourse=localStorage.getItem('currentcourse')
const completedClasses = [];
//VideoList
const ListItemStyled = styled(ListItem)(({ theme, isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  backgroundColor: isSelected ? '#45ceac' : '#0A0B0F',
  marginBottom: '8px',
}));

const VideoTitle = styled(Typography)(({ theme, isSelected }) => ({
  color: isSelected ? '#141b2b' : '#45ceac',
}));

const VideoSubtitle = styled(Typography)(({ theme, isSelected }) => ({
  color: isSelected ? '#141b2b' : '#45ceac',
}));

const VideoDescription = styled(Typography)(({ theme, isSelected }) => ({
  color: isSelected ? '#141b2b' : 'inherit',
}));

const CompletedIcon = styled(CheckIcon)({
  color: 'yellow',
});

function VideoItem({ video, onVideoClick, isSelected,isCompleted }) {
  return (
    <ListItemStyled button onClick={() => onVideoClick(video)} isSelected={isSelected}>
      <ListItemText
        primary={
          <Box display="flex" alignItems="center">    
          <Box mr={1}>
            {isCompleted && <CompletedIcon />}</Box>       
            <VideoTitle isSelected={isSelected}>
              {video.title.split('').map((letter, index) => (
                <span style={{ color: isSelected ? '#141b2b' : 'inherit' }} key={index}>
                  {letter}
                </span>
              ))}
            </VideoTitle>
          </Box>
        }
        secondary={
          <React.Fragment>
            <VideoSubtitle isSelected={isSelected}>{video.length}</VideoSubtitle>
            <br />
            <VideoDescription isSelected={isSelected}>{video.description}</VideoDescription>
          </React.Fragment>
        }
      />
    </ListItemStyled>
  );
}


function ChapterAccordion({ videos, onVideoClick, selectedVideoId }) {
  const [chapters, setChapters] = useState({});

  useEffect(() => {
    const groupedByChapter = videos.reduce((acc, video) => {
      const chapterName = video.chapter || 'General';
      if (!acc[chapterName]) {
        acc[chapterName] = [];
      }
      acc[chapterName].push(video);
      return acc;
    }, {});
    setChapters(groupedByChapter);
  }, [videos]);

  return (
    <Box>
      {Object.entries(chapters).map(([chapterName, chapterVideos], index) => (
        <Accordion sx={{ backgroundColor: '#141b2b' }} key={index} >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{chapterName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {chapterVideos.map((video, vidIndex) => (
                <VideoItem
                  key={vidIndex}
                  video={video}
                  onVideoClick={() => onVideoClick(video)}
                  isSelected={selectedVideoId === video.id}  // Aquí se pasa correctamente el estado de selección
                  isCompleted={completedClasses.includes(video.id)}
                />
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}



function VimeoPlayer({ videos,curso,idlastvideo1,cursocode }) {
  const cursoActual=curso
  const cursoActualcode=cursocode
  const theme = useTheme();
  const [completedVideos, setCompletedVideos] = useState([]);
 
  const isMobile = useMediaQuery('(max-width: 599.95px)'); // Dispositivos móviles hasta 599.95px
  const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 959.95px)'); // Dispositivos tabletas entre 600px y 959.95px
  const isDesktop = useMediaQuery('(min-width: 960px)'); // Dispositivos de escritorio a partir de 960px

  const [isFullscreen, setIsFullscreen] = useState(false);
  const handlePlayerFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {   
    const completedVideosList = videos.filter(video => completedClasses.includes(video.id));
    const completedVideoIds = completedVideosList.map(video => video.id); // Obtener solo los IDs de los videos completados
    setCompletedVideos(completedVideoIds);
  }, [videos]);
  




  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVideoId, setSelectedVideoId] = useState(null); // ID del video inicial
  const [activeTab, setActiveTab] = useState(0);
  const [autoPlay, setAutoPlay] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const playerRef = useRef(null);
  const [showComments, setShowComments] = useState(false);
  const nameuser1 = localStorage.getItem('username');
  const email1 = localStorage.getItem('useremail');
  //Chapter

  const playNextVideo = () => {
    const nextIndex = (currentVideoIndex + 1) % videos.length;
    const nextVideo = videos[nextIndex];
    setCurrentVideoIndex(nextIndex);
    setSelectedVideo(nextVideo);
    setSelectedVideoId(nextVideo.id); // Agrega esta línea
  };
  

  const playPreviousVideo = () => {
    const previousIndex = (currentVideoIndex - 1 + videos.length) % videos.length;
    const previousVideo = videos[previousIndex];
    setSelectedVideo(previousVideo);
    setCurrentVideoIndex(previousIndex);
    setSelectedVideoId(previousVideo.id); // Agrega esta línea
  };
  
  

  const handleVideoEnded = () => {
    if (currentVideoIndex < videos.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    } else {
      setCurrentVideoIndex(0);
    }
  };

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(0, 'seconds');
    }
  }, [currentVideoIndex]);

  const currentVideo = videos[currentVideoIndex];

  useEffect(() => {
    if (autoPlay && selectedVideo) {
      const currentIndex = videos.findIndex((video) => video === selectedVideo);
      const nextIndex = (currentIndex + 1) % videos.length;
      const nextVideo = videos[nextIndex];
      setSelectedVideo(nextVideo);
    }
  }, [autoPlay, selectedVideo, videos]);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setSelectedVideoId(video.id);
    setCurrentVideoIndex(videos.findIndex((v) => v.id === video.id));
    setAutoPlay(false);
    setShowComments(true);
  };
  

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAutoPlayChange = (event) => {
    setAutoPlay(event.target.checked);
  };


  const registerVideoPlayback = async () => {
    try {
      const response = await fetch('https://8ori4qvsnc.execute-api.us-east-1.amazonaws.com/dev/views', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({  
            "type-operation":"post",
            "email-student": email1,
            "id-class": selectedVideo.id,
            "id-course": cursocode,
            "name-class": selectedVideo.title,
            "name-course": curso,
            "name-student": nameuser1,
            "type-reproduction": "click-inicio"
          
        }),
      });
  
      if (!response.ok) {
        const message = `An error has occurred: ${response.statusText}`;
        throw new Error(message);
      }
  
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedVideoId) {
      registerVideoPlayback();
    }
  }, [selectedVideoId]);
  
  

  const renderVideoPlayer = () => {
    if (selectedVideo) {
      return (
        <Box marginTop={0} sx={{ bgcolor: '#141b2d' }}>
          <Box>
            <ReactPlayer
              ref={playerRef}
              url={`https://vimeo.com/${selectedVideo.id}`}
              width="100%"
              height={isTablet?'460px':isMobile ? '280px' : '565px'}
              playing
              controls
              frameBorder="0"
              onReady={() => console.log(`Video ${currentVideoIndex} started`)}
              onEnded={handleVideoEnded}
            />
            <Box display="flex" justifyContent="space-between" marginTop={1}>
              <Box marginLeft={12}>
                <IconButton onClick={playPreviousVideo} sx={{ color: '#4ed0b0',backgroundColor:"#1f2a40" }}>
                  <ChevronLeftIcon />
                </IconButton>
              </Box>
              <Box marginRight={12} >
                <IconButton onClick={playNextVideo} sx={{ color: '#4ed0b0' , backgroundColor:"#1f2a40"}}>
                  <ChevronRightIcon/>
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    } else {
      return (
            <Box m={0} sx={{ backgroundColor: '#141b2d', borderRadius: '10px', justifyContent: 'center', height: '300px', display: 'flex', alignItems: 'center' }}>
            <Box m={0} sx={{ backgroundColor: '#3e4396', borderRadius: '10px', justifyContent: 'center', display: 'flex', alignItems: 'center', height: '80%' }}>
              <Typography variant="h2" sx={{ textAlign: 'center' }}>
                Hola! Selecciona tu Clase de Hoy
              </Typography>
              <Box sx={{ marginLeft: 'auto' }}>
                <TouchAppIcon fontSize="large" />
              </Box>
            </Box>
          </Box>
      );
    }
  };

  const VideoResources = ({ videoList }) => {
    const theme = useTheme();
    const [videoId, setVideoId] = useState('');

    const handleResourceClick = (event, url) => {
      event.preventDefault();
      window.open(url, '_blank');
    };

    useEffect(() => {
      if (selectedVideo) {
        setVideoId(selectedVideo.id);
      }
    }, [selectedVideo]);

    const video = videoList.find((video) => video.id === videoId);


    if (!video || !video.resources || video.resources.length === 0) {
      return (
        <Box
          sx={{
            backgroundColor: '#1f2a40',
            color: theme.palette.common.white,
            padding: theme.spacing(2),
            maxHeight: 250,
            overflowY: 'auto',
          }}
        >
          <Typography></Typography>
        </Box>
      );
    }

    const { resources } = video;

    
    
   
    return (
      <Box
        sx={{
          backgroundColor: '#1f2a40',
          color: theme.palette.common.white,
          padding: theme.spacing(2),
          maxHeight: 250,
          overflowY: 'auto',
        }}
      >
        {resources.map((resource, index) => (
          <p key={index}>
            <a
              href={resource.url}
              download={!/^https?:\/\//.test(resource.url)}
              onClick={(event) => handleResourceClick(event, resource.url)}
              style={{ color: '#6971c6', textDecoration: 'underline' }}
            >
              {resource.name}
            </a>
          </p>
        ))}
      </Box>
    );
  };

  const nameuser = localStorage.getItem('username');


  return (
    <Box  className={isMobile ? 'container' : ''}>
      <Grid container spacing={1} marginTop={9}>
        {isMobile || isTablet ? (
          <>
          
            <Grid item xs={12}>
              <Paper>{renderVideoPlayer()}</Paper>
            </Grid>
            <Grid item xs={12} marginTop={0}>
              <Box mr={1} ml={1}>
              <Box m={0} mb={1} alignContent={'center'} alignItems={'center'} fontWeight="bold">
                <Typography color="secondary" variant="h5">{cursoActual}</Typography>
              </Box>
              <Paper sx={{ bgcolor: '#45ceac' }}>
                <Tabs value={activeTab} onChange={handleTabChange}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: '#ffffff', // Cambia el color del indicador a rojo
                    },
                  }}
                >
                  <Tab label="Clases" sx={{ color: '#2f2f2f' }} />
                  <Tab label="Recursos" sx={{ color: '#2f2f2f' }} />
                  <Tab label="Comentarios" sx={{ color: '#2f2f2f' }} />
                 
                </Tabs>
               
                {activeTab === 0 && (
                  <Paper sx={{ maxHeight: 400, overflowY: 'auto', backgroundColor: '#03060e' }}>
                    <List component="nav">
                      <Box mr={1} ml={1}>
                        <ChapterAccordion videos={videos} onVideoClick={handleVideoClick} selectedVideoId={selectedVideo ? selectedVideo.id : null} />
                      </Box>
                    </List>
                  </Paper>
                )}
                {activeTab === 1 && (
                  <Paper sx={{ maxHeight: 400, overflowY: 'auto', backgroundColor: '#03060e' }}>
                    <VideoResources videoList={videos}></VideoResources>
                  </Paper>
                )}
                {activeTab === 2 && (
                  <Box>
                  {showComments && (<Paper sx={{ maxHeight: 500, overflowY: 'auto', backgroundColor: '#1f2a40' }}>
                    <CommentsComponent personName={nameuser} idvideo={selectedVideo.id} idlastvideo={idlastvideo1} codecourse={cursocode} nameVideo={selectedVideo.title} nameCourse={curso}></CommentsComponent>
                  </Paper>)}
                  </Box>
                )}

              </Paper>
              </Box>
            </Grid>
      
          </>
        ) : (
          <>
            <Grid item md={9} backgroundColor="#141b2d">
              <Paper>{renderVideoPlayer()}</Paper>
              
              {showComments && (
                <Box marginTop={5} style={{ maxHeight: '600px', overflow: 'auto' }}>
                <CommentsComponent personName={nameuser} idvideo={selectedVideo.id} idlastvideo={idlastvideo1} codecourse={cursocode} nameVideo={selectedVideo.title} nameCourse={curso} ></CommentsComponent>
                </Box>
              )}


              
            </Grid>
            <Grid item md={3} marginTop={2}>
              <Box mr={5}>
              <Box marginBottom={4} alignContent={'center'} alignItems={'center'} fontWeight="bold">
              <Typography color="secondary" variant="h5">{cursoActual}</Typography>
              </Box>
              
              <Typography variant="h4">Lista de Clases</Typography>
              
              <Paper sx={{ maxHeight: 500, overflowY: 'auto', bgcolor: '#03060e' }}>
                <ChapterAccordion videos={videos} onVideoClick={handleVideoClick} selectedVideoId={selectedVideo ? selectedVideo.id : null} />
              </Paper>
              <Box marginTop={4}>
                <Typography variant="h4">Recursos</Typography>
                <Paper sx={{ maxHeight: 400, overflowY: 'auto', backgroundColor: '#03060e' }}>
                    <VideoResources videoList={videos}></VideoResources>
                  </Paper>
              </Box>
              </Box>
            
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default VimeoPlayer;
