import { useState } from "react";

import Topbar from "./scenes/global/Topbar";
import TopbarSells from "./scenes/global/TopbarSells";
import Sidebar from "./scenes/global/Sidebar";
import {BrowserRouter as Router,Route,Routes,Navigate} from 'react-router-dom'

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import IndexPage from './scenes/Index';
import RecoverPage from './scenes/recover';
import RegisterPage from './scenes/register/LRegister';
import NotFund from './scenes/NotFound';
import LoginPage from "./scenes/login";
import AppInside from "./AppPrivateRoutes";
import MainPageSells from "./scenes/sells/main";

import Us from "./scenes/sells/us";
import MainCourses from "./scenes/sells/maincourses";
import Prices from "./scenes/sells/pricing";
import ScrollToTop from "./components/ScrollTop";
import CusosPuentes1Page from "./scenes/sells/cursopuentes1";
import Cursofundamentos from "./scenes/sells/cursofundamentos";
import Cursoavaluos from "./scenes/sells/cursoavaluos";
import Cursoedficiosmuros from "./scenes/sells/cursoedficiosmuros";
import MainPageSellsEnterprise from "./scenes/sells/pricing_enterprice";
import PostSells from "./scenes/sells/postsells";
import Cursodesarrolloapps05 from "./scenes/sells/Cursodesarrolloapps05";
import ThankYouPage from "./scenes/sells/tankyoupage";



{/*import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Calendar from "./scenes/calendar/calendar";
import Geography from "./scenes/geography";*/}






function PublicRoutes({ onLogin }) {
  const [theme,colorMode]=useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">    
          <main className="content">
            <TopbarSells setIsSidebar={setIsSidebar} />
            <ScrollToTop></ScrollToTop>             
            <Routes>                         
              <Route path='/' element={<MainPageSells/>}/> 
              <Route path='/cursos' element={<MainCourses/>}/>   
              <Route path='/cursos-estructuras-puentes-01' element={<CusosPuentes1Page/>}/>   
              <Route path='/cursos-estructuras-edificios-01' element={<Cursoedficiosmuros/>}/>   
              <Route path='/cursos-estructuras-edificios-02' element={<Cursofundamentos/>}/>   
              <Route path='/cursos-estructuras-edificios-03' element={<Cursoavaluos/>}/>   
              <Route path='/cursos-desarrollo-inteligencia-04' element={<Cursodesarrolloapps05/>}/>   
              <Route path='/precios' element={<Prices/>}/>
              <Route path='/empresas' element={<MainPageSellsEnterprise/>}/>
              <Route path='/nosotros' element={<Us/>}/>              
              <Route path='/login' element={<LoginPage onLogin={onLogin}/>}/> 
              <Route path='/register' element={<RegisterPage/>}/>        
              <Route path='/recover' element={<RecoverPage/>}/>                
              <Route path='/thankyoupage' element={<ThankYouPage/>}/>                              
              <Route path='/landingpayment103611474478' element={<PostSells/>}/>  
              <Route path='*' element={<NotFund/>}/>           
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default PublicRoutes;
